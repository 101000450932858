<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <v-flex md12>
          <h2>{{ $t('custom.analitics') }}</h2>
        </v-flex> -->
    <v-container fluid fill-height>
      <!-- <v-layout> -->

      <v-toolbar
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center"
      >
        <v-toolbar-title
          >{{ $t("analitics.common_bookings") }} {{ $t("custom.number")
          }}{{ report.id }} {{ start_date_formatted }} -
          {{ end_date_formatted }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/8/guides/25`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- По машинам -->
      <v-flex v-if="report.state == 'created'" md12 class="d-inline-flex">
        <v-flex md12 class="mt-2">
          <v-card-text>
            <h2>{{ $t("analitics.average_indicators") }}</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      {{ $t("analitics.all_bookings") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.active_bookings") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.convertion") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_price_econom") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_price_middle") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_price_business") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_price_comercial") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ report.bookings }}
                    </td>
                    <td class="text-center">
                      {{ report.active_bookings }}
                    </td>
                    <td class="text-center">
                      {{ parseFloat(report.convertion).toFixed(2) }}%
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(
                          averagePrice(all_prices_econom_data)
                        ).toFixed(2)
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(
                          averagePrice(all_prices_middle_data)
                        ).toFixed(2)
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(
                          averagePrice(all_prices_business_data)
                        ).toFixed(2)
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        parseFloat(
                          averagePrice(all_prices_comercial_data)
                        ).toFixed(2)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-flex md12>
              <h2>{{ $t("analitics.booking_states") }}</h2>
            </v-flex>
            <v-flex md12 sm12 :class="isMobile ? '' : 'd-flex'">
              <v-flex md6 sm12>
                <GChart
                  v-if="pie_chart_all_states_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="PieChart"
                  :options="pie_chart_all_states_options"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="pie_chart_all_states_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
              <v-flex md6 sm12>
                <GChart
                  v-if="pie_chart_active_states_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="PieChart"
                  :options="pie_chart_active_states_options"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="pie_chart_active_states_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
            </v-flex>
            <v-flex md12>
              <h2>{{ $t("car_classes.car_classes") }}</h2>
            </v-flex>
            <v-flex md12 sm12 :class="isMobile ? '' : 'd-flex'">
              <v-flex md6 sm12>
                <GChart
                  v-if="pie_chart_car_classes_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="PieChart"
                  :options="pie_chart_all_classes_options"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="pie_chart_car_classes_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
              <v-flex md6 sm12>
                <GChart
                  v-if="pie_chart_car_classes_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="PieChart"
                  :options="pie_chart_active_classes_options"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="pie_chart_car_classes_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
            </v-flex>
            <v-flex md12>
              <h2>{{ $t("analitics.prices_classes") }}</h2>
            </v-flex>
            <v-flex md12 sm12 :class="isMobile ? '' : 'd-flex'">
              <v-flex md6 sm12>
                <GChart
                  v-if="line_chart_all_prices_econom_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="LineChart"
                  :options="{
                    title: $t('analitics.prices_for_car_econom'),
                    curveType: 'function',
                    lineWidth: 2,
                  }"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="line_chart_all_prices_econom_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
              <v-flex md6 sm12>
                <GChart
                  v-if="line_chart_all_prices_middle_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="LineChart"
                  :options="{
                    title: $t('analitics.prices_for_car_middle'),
                    curveType: 'function',
                    lineWidth: 2,
                  }"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="line_chart_all_prices_middle_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
            </v-flex>
            <v-flex md12 sm12 :class="isMobile ? '' : 'd-flex'">
              <v-flex md6 sm12>
                <GChart
                  v-if="line_chart_all_prices_business_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="LineChart"
                  :options="{
                    title: $t('analitics.prices_for_car_business'),
                    curveType: 'function',
                    lineWidth: 2,
                  }"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="line_chart_all_prices_business_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
              <v-flex md6 sm12>
                <GChart
                  v-if="line_chart_all_prices_comercial_data.length > 1"
                  style="width: 100%; min-height: 300px"
                  type="LineChart"
                  :options="{
                    title: $t('analitics.prices_for_car_comercial'),
                    curveType: 'function',
                    lineWidth: 2,
                  }"
                  :settings="{
                    packages: ['corechart'],
                    language: this.$i18n.locale,
                  }"
                  :data="line_chart_all_prices_comercial_data"
                />
                <v-flex class="mt-16 text-center" v-else>
                  {{ $t("analitics.no_data") }}
                </v-flex>
              </v-flex>
            </v-flex>
          </v-card-text>
          <v-card-text>
            <h2 class="mb-2">{{ $t("analitics.for_bookings") }}</h2>
            <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
            </v-toolbar>
            <v-data-table
              :class="!isMobile ? 'mt-5' : 'mt-5 is-mobile'"
              dense
              :headers="headers"
              :items="cars_data"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :search="search"
              :items-per-page="50"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                itemsPerPageOptions: [50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr
                  @dblclick="replaceToCar(props.item.car_id)"
                  :class="props.item.active ? '' : 'grey--text text--lighten-1'"
                >
                  <td nowrap class="text-center">
                    <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{
                      props.item.code
                    }}</router-link>
                  </td>
                  <td nowrap class="text-center">
                    {{ selectClassLocale(props.item.car_class) }}
                  </td>
                  <td nowrap class="text-center">
                    {{ formattedDate(props.item.buy_date) }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.bookings_size }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.active_bookings_size }}
                  </td>
                  <td nowrap class="text-center">
                    {{
                      parseFloat(props.item.all_bookings_average_price).toFixed(
                        2
                      )
                    }}
                  </td>
                  <td nowrap class="text-center">
                    {{
                      parseFloat(
                        props.item.active_bookings_average_price
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-flex>
      </v-flex>
      <v-flex v-else md12 class="text-center mt-10">
        {{ $t("analitics.report_in_progress") }}
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <br />
        <v-btn @click="$router.go()">{{ $t("custom.update") }}</v-btn>
      </v-flex>
    </v-container>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { GChart } from "vue-google-charts";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      report: {},
      cars_data: [],
      all_states_data: [],
      active_states_data: [],
      car_classes_data: [],
      car_active_classes_data: [],
      all_prices_data: [],
      all_prices_econom_data: [],
      all_prices_middle_data: [],
      all_prices_business_data: [],
      all_prices_comercial_data: [],
      headers: [
        { text: this.$t("custom.car"), value: "code", align: "center" },
        {
          text: this.$t("analitics.car_class"),
          value: "car_class",
          align: "center",
        },
        {
          text: this.$t("cars.purchase_date"),
          value: "buy_date",
          align: "center",
        },
        {
          text: this.$t("analitics.all_bookings_2"),
          value: "bookings_size",
          align: "center",
        },
        {
          text: this.$t("analitics.active_bookings_2"),
          value: "active_bookings_size",
          align: "center",
        },
        {
          text: this.$t("analitics.all_bookings_average_price"),
          value: "all_bookings_average_price",
          align: "center",
        },
        {
          text: this.$t("analitics.active_bookings_average_price"),
          value: "active_bookings_average_price",
          align: "center",
        },
      ],
      pie_chart_all_states_data: [
        [this.$t("analitics.in_all_bookings"), this.$t("custom.sum")],
      ],
      pie_chart_active_states_data: [
        [this.$t("analitics.in_active_bookings"), this.$t("custom.sum")],
      ],
      pie_chart_car_classes_data: [
        [this.$t("analitics.in_all_bookings"), this.$t("custom.sum")],
      ],
      pie_chart_car_active_classes_data: [
        [this.$t("analitics.in_active_bookings"), this.$t("custom.sum")],
      ],
      line_chart_all_prices_econom_data: [
        [
          this.$t("analitics.in_all_bookings"),
          this.$t("analitics.prices_classes"),
        ],
      ],
      line_chart_all_prices_middle_data: [
        [
          this.$t("analitics.in_all_bookings"),
          this.$t("analitics.prices_classes"),
        ],
      ],
      line_chart_all_prices_business_data: [
        [
          this.$t("analitics.in_all_bookings"),
          this.$t("analitics.prices_classes"),
        ],
      ],
      line_chart_all_prices_comercial_data: [
        [
          this.$t("analitics.in_all_bookings"),
          this.$t("analitics.prices_classes"),
        ],
      ],
      pie_chart_all_states_options: {
        title: this.$t("analitics.in_all_bookings"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pie_chart_active_states_options: {
        title: this.$t("analitics.in_active_bookings"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pie_chart_all_classes_options: {
        title: this.$t("analitics.in_all_bookings"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pie_chart_active_classes_options: {
        title: this.$t("analitics.in_active_bookings"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      options_lines: {
        curveType: "function",
        lineWidth: 1,
        intervals: { style: "line" },
        // legend: 'none'
      },
      search: "",
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/bookings_reports/${this.$route.params.query}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.report = response.data;
          this.cars_data = response.data.cars_data;
          this.all_states_data = response.data.all_states_data;
          this.active_states_data = response.data.active_states_data;

          this.car_classes_data = response.data.car_classes_data;
          this.car_active_classes_data = response.data.car_active_classes_data;

          this.all_prices_data = response.data.all_prices;

          if (this.report.state == "created") {
            this.all_states_data.forEach((item) => {
              this.pie_chart_all_states_data.push([
                self.selectStateLocale(item.state),
                item.count,
              ]);
            });
            this.active_states_data.forEach((item) => {
              this.pie_chart_active_states_data.push([
                self.selectStateLocale(item.state),
                item.count,
              ]);
            });

            this.car_classes_data.forEach((item) => {
              this.pie_chart_car_classes_data.push([
                self.selectClassLocale(item.car_class),
                item.count,
              ]);
            });
            this.car_active_classes_data.forEach((item) => {
              this.pie_chart_car_active_classes_data.push([
                self.selectClassLocale(item.car_class),
                item.count,
              ]);
            });

            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Эконом") {
                this.line_chart_all_prices_econom_data.push([
                  moment(item[0], "DD-MM-YYYY H:mm").format("DD.MM.YYYY"),
                  item[1],
                ]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Средний") {
                this.line_chart_all_prices_middle_data.push([
                  moment(item[0], "DD-MM-YYYY H:mm").format("DD.MM.YYYY"),
                  item[1],
                ]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Бизнес") {
                this.line_chart_all_prices_business_data.push([
                  moment(item[0], "DD-MM-YYYY H:mm").format("DD.MM.YYYY"),
                  item[1],
                ]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Коммерческий") {
                this.line_chart_all_prices_comercial_data.push([
                  moment(item[0], "DD-MM-YYYY H:mm").format("DD.MM.YYYY"),
                  item[1],
                ]);
              }
            });
            // Sort by date
            this.line_chart_all_prices_econom_data =
              this.line_chart_all_prices_econom_data.sort(function (a, b) {
                return (
                  moment(b[0], "DD-MM-YYYY H:mm").diff(
                    moment(a[0], "DD-MM-YYYY H:mm")
                  ) * -1
                );
              });
            this.line_chart_all_prices_middle_data =
              this.line_chart_all_prices_middle_data.sort(function (a, b) {
                return (
                  moment(b[0], "DD-MM-YYYY H:mm").diff(
                    moment(a[0], "DD-MM-YYYY H:mm")
                  ) * -1
                );
              });
            this.line_chart_all_prices_business_data =
              this.line_chart_all_prices_business_data.sort(function (a, b) {
                return (
                  moment(b[0], "DD-MM-YYYY H:mm").diff(
                    moment(a[0], "DD-MM-YYYY H:mm")
                  ) * -1
                );
              });
            this.line_chart_all_prices_comercial_data =
              this.line_chart_all_prices_comercial_data.sort(function (a, b) {
                return (
                  moment(b[0], "DD-MM-YYYY H:mm").diff(
                    moment(a[0], "DD-MM-YYYY H:mm")
                  ) * -1
                );
              });

            // Average prices for each class
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Эконом") {
                this.all_prices_econom_data.push(item[1]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Средний") {
                this.all_prices_middle_data.push(item[1]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Бизнес") {
                this.all_prices_business_data.push(item[1]);
              }
            });
            this.all_prices_data.forEach((item) => {
              if (item[2] != null && item[2] == "Коммерческий") {
                this.all_prices_comercial_data.push(item[1]);
              }
            });
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.bookings_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => {});
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  watch: {},
  computed: {
    start_date_formatted() {
      return moment(this.report.start_date).format("DD.MM.YYYY");
    },
    end_date_formatted() {
      return moment(this.report.end_date).format("DD.MM.YYYY");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    selectStateLocale(state) {
      if (state == "Недозвон") {
        return this.$t("bookings_states.abandoned");
      } else if (state == "Подтверждена") {
        return this.$t("bookings_states.accepted");
      } else if (state == "Активная") {
        return this.$t("bookings_states.active");
      } else if (state == "Отмена") {
        return this.$t("bookings_states.cancel");
      } else if (state == "Отказ клиента") {
        return this.$t("bookings_states.decline");
      } else if (state == "В обработке") {
        return this.$t("bookings_states.in_work");
      } else if (state == "Новая") {
        return this.$t("bookings_states.new");
      } else if (state == "Нет машин") {
        return this.$t("bookings_states.no_cars");
      } else if (state == "Не подтверждена") {
        return this.$t("bookings_states.not_accepted");
      } else if (state == "Другое") {
        return this.$t("bookings_states.other");
      } else if (state == "Вернуть залог") {
        return this.$t("bookings_states.return_deposit");
      } else if (state == "Отъездила") {
        return this.$t("bookings_states.rided");
      } else if (state == "Ожидает ответа клиента") {
        return this.$t("bookings_states.wait_answer");
      } else if (state == "Ожидает оплаты") {
        return this.$t("bookings_states.wait_pay");
      } else {
        return "???";
      }
    },
    selectClassLocale(car_class) {
      if (car_class == "Бизнес") {
        return this.$t("car_classes.business");
      } else if (car_class == "Коммерческий") {
        return this.$t("car_classes.commercial");
      } else if (car_class == "Эконом") {
        return this.$t("car_classes.economy");
      } else if (car_class == "Средний") {
        return this.$t("car_classes.middle");
      } else {
        return "???";
      }
    },
    averagePrice(array) {
      if (array.length > 0) {
        return array.reduce((a, b) => a + b, 0) / array.length;
      } else {
        return 0;
      }
    },
    replaceToCar(car) {
      this.$router.push({ path: `/cars/${car.id}` });
    },
    formattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    GChart,
  },
};
</script>
<style>
  .is-mobile .v-data-footer__select {
    margin-right: 0 !important;
  }
  .is-mobile .v-data-footer__icons-before {
    margin: 0 auto !important;
  }
  .is-mobile .v-data-footer__icons-after {
    margin: 0 auto !important;
  }
</style>
