<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1"
          >Краткое описание всех функций</v-card-title
        >
        <v-card-subtitle class="subtitle-1"
          >Небольшие пояснения к функциям системы</v-card-subtitle
        >
        <v-simple-table fixed-header height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Раздел
                </th>
                <th class="text-left">
                  Подраздел
                </th>
                <th class="text-left">
                  Функция
                </th>
                <th class="text-center">
                  Описание
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">Главная</td>
                <td class="font-italic">Главная</td>
                <td>Состояние авто</td>
                <td>Выбор индикации состояния автомобиля в зависимости от доступности для выдачи или технического состояния</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение мойки</td>
                <td>Выбор состояния чистоты автомобиля (мытый/немытый)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение заправки</td>
                <td>Выбор состояния уровня топлива (полный/неполный)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение местонахождения</td>
                <td>Указание текущего местонахождения автомобиля</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке техсостояния</td>
                <td>Отметка о дате предыдущего осмотра автомобиля сотрудником автопроката (проверка технических жидкостей и проч.) Возможно провести осмотр и изменить дату. Так же данный параметр есть в списке автомобилей. В карточке автомобиля можно увидеть историю всех осмотров</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Брони</td>
                <td class="font-italic">Активные</td>
                <td>Создание брони</td>
                <td>Создать новую бронь вручную</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение состояния брони</td>
                <td>Изменение состояния брони в зависимости от этапа и результата ее обработки сотрудником (для неактивной брони) или подтверждения и отмены брони клиентом (для активной брони)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Назначение ответственного</td>
                <td>Выбор сотрудника, ответственного за выдачу или прием автомобиля по данной брони</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отправка письма клиенту</td>
                <td>Отправка шаблона письма клиенту с подтверждением, отказом или в свободной форме. Создание и сохранение шаблонов писем</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата брони</td>
                <td>Прием денежных средств от клиента в оплату брони</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продление брони</td>
                <td>Продление брони - изменение даты окончания без редактирования брони (возможно осуществить как с одновременным приемом оплаты, так и без оплаты)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование брони</td>
                <td>Внесение изменений в бронирование (изменение авто, дат, цен и т.д.)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Печать документов</td>
                <td>Формирование документов для печати (договор и акт приема-передачи)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Прием автомобиля</td>
                <td>Проводка по учету приема автомобиля у клиента сотрудником, с указанием нового пробега, взиманием доплат и возвратом залога</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение расчета вручную</td>
                <td>Ручное редактирование расчета по брони, в случае если автоматический расчет не соответствует необходимому. (Обратите внимание, что если необходимо только снизить цену - это достигается применением большей скидки)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение данных о пробеге и т.п.</td>
                <td>Ручное редактирование параметров выдачи или приема автомобиля (мойки, заправки, пробега) в случае выявления ошибок</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Неактивные</td>
                <td>Активация</td>
                <td>Активизация брони, пришедшей с сайта, после выбора автомобиля доступного для бронирования</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование брони</td>
                <td>Внесение изменений в заказ на этапе подтверждения брони клиентом</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата брони</td>
                <td>Возможность провести оплату долга по уже принятому автомобилю</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Архивация</td>
                <td>Перемещение брони в архив, где будет доступен ее просмотр, в случае необходимости.</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление</td>
                <td>Безвозвратное удаление брони (рекомендуется только для дублей и тестовых броней)</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Клиенты</td>
                <td class="font-italic">Клиенты</td>
                <td>Создание клиента</td>
                <td>Создание нового клиента вручную</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование клиента</td>
                <td>Внесение изменений в карточку клиента</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Загрузка документов</td>
                <td>Загрузка фотографий документов клиента в базу данных</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение категории</td>
                <td>Изменение категории лояльности клиента</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Долги</td>
                <td>Создание долга</td>
                <td>Внесение данных о долге клиента перед компанией</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование долга</td>
                <td>Внесение изменений в карточку долга</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата долга</td>
                <td>Прием денежных средств от клиента в оплату долга</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление долга</td>
                <td>Удаление сведений о долге</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Штрафы</td>
                <td>Создание штрафа</td>
                <td>Внесение данных о поступившем штрафе</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование штрафа</td>
                <td>Изменение данных о штрафе</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата штрафа</td>
                <td>Оплата штрафа компанией в ГИБДД</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Поступление денег в оплату от клиента</td>
                <td>Поступление денег от клиента-штрафника в счет возмещения долга за штраф</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление штрафа</td>
                <td>Безвозвратное удаление данных о штрафе (рекомендуется только для ошибочно введенных)</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Счета</td>
                <td>Создание счета</td>
                <td>Создание счета для приема безналичной оплаты</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование счета</td>
                <td>Внесение изменений в созданный счет</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Печать счета</td>
                <td>Формирование счета из шаблона, для дальнейшей печати</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата счета</td>
                <td>Поступление денежных средств от клиента в оплату счета</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление счета</td>
                <td>Безвозвратное удаление счета(рекомендуется только для ошибочно введенных)</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Календарь</td>
                <td class="font-italic">Календарь</td>
                <td>Режим просмотра</td>
                <td>Просмотр данных о бронированиях на календаре, за любой период времени</td>
              </tr>
              <tr>
                <td></td>
                <td>План на день</td>
                <td>Изменение ответственного</td>
                <td>Изменение сотрудника, ответственного за выдачу или прием автомобиля</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавление задачи</td>
                <td>Внесение задачи сотрудникам в план работ на день</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование задачи</td>
                <td>Внесение изменений в задачу</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление задачи</td>
                <td>Удаление задачи, если отпала необходимость выполнения</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Касса</td>
                <td class="font-italic">Касса</td>
                <td>Создание платежа</td>
                <td>Создание расходного платежа при расходах денежных средств или доходного для поступлений не связанных с основной деятельностью (например продажа авто)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование платежа</td>
                <td>Изменение платежа при допущении ошибки в данных (кроме суммы и вида оплаты, эти данные редактированию не подлежат)</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Автомобили</td>
                <td class="font-italic">Автомобили</td>
                <td>Добавить авто</td>
                <td>Добавление нового автомобиля в базу. Обязательно, после ввода основных данных авто, сохранить изменения, заново войти в карточку, заполнить основные цены и активировать машину!</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактировать авто</td>
                <td>Внесение изменений в карточку автомобиля</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Активировать авто</td>
                <td>Активация автомобиля - включение возможности бронирования</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Деактивировать авто</td>
                <td>Выключение доступности автомобиля для бронирования</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продать авто</td>
                <td>Продажа автомобиля и архивация (перед продажей обязателна деактивация!)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменить состояние авто</td>
                <td>Изменения состояния машины в зависимости от возможности выдачи или технического состояния</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавить ремонт</td>
                <td>Добавление информации о необходимости производства каких-либо работ с автомобилем</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Просмотр аналитики по авто</td>
                <td>Просмотр данных о расходах и доходах на автомобиль (некоторые данные доступны только для управляющего)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Поменять резину</td>
                <td>Изменение сведений о сезонной резине, стоящей на машине</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Обслуживание</td>
                <td class="font-italic">Техобслуживание</td>
                <td>Отметка о проведении</td>
                <td>Отметка о выполнении ремонта или обслуживания и удаление соответствующего напоминания</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Документы</td>
                <td>Продление страховки</td>
                <td>Изменение сведений об окончании страховки при оформлении новой</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продление диагностической карты</td>
                <td>Изменение сведений об окончании диагностической карты при оформлении новой</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Список задач</td>
                <td class="font-italic">Все</td>
                <td>Просмотр</td>
                <td>Просмотр задач всех сотрудников (доступно не всем пользователям)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Просмотр личного чек-листа</td>
                <td>Просмотр личного списка регулярных задач</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавить задачу</td>
                <td>Добавление задачи себе или сотруднику</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удалить задачу</td>
                <td>Удаление задачи из списка дел</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Личные</td>
                <td>Добавить задачу</td>
                <td>Добавление задачи в личный список</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о выполнении</td>
                <td>Отметка о выполнении задачи</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удалить задачу</td>
                <td>Удаление задачи из списка дел</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Аналитика</td>
                <td class="font-italic">Аналитика</td>
                <td>Просмотр</td>
                <td>Просмотр всех аналитических отчетов</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Сотрудники</td>
                <td class="font-italic">Сотрудники</td>
                <td>Просмотр кассы сотрудника</td>
                <td>Просмотр и проверка кассы сотрудника</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование операции</td>
                <td>Изменение некоторых реквизитов при ошибочном вводе операции (сумма и вид оплаты редактируется только по правилу "Красного сторно"!)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение кассы</td>
                <td>Снятие денег с кассы или выдача денег сотруднику</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке</td>
                <td>Отметка о проверке и принятии операции управляющим</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Зарплата</td>
                <td class="font-italic">Зарплата</td>
                <td>Просмотр всех выплат</td>
                <td>Просмотр выплат зарплаты всем сотрудникам</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Создание выплаты</td>
                <td>Выплата зарплаты</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке</td>
                <td>Отметка о проверке и принятии операции управляющим</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Чек-лист</td>
                <td class="font-italic">Чек-лист</td>
                <td>Просмотр</td>
                <td>Просмотр списка типовых (регулярных)  задач сотрудникам</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавление задачи</td>
                <td>Добавление типовой задачи</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование задачи</td>
                <td>Изменение типовой задачи</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление задачи</td>
                <td>Удаление задачи</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о выполнении</td>
                <td>Отметка о выполнении задачи (проставляется исполнителем ежедневно)</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение приоритета</td>
                <td>Изменение порядка выполнения задач</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Профиль</td>
                <td class="font-italic">Личный профиль</td>
                <td>Редактирование</td>
                <td>Изменение личных данных</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Расписание</td>
                <td class="font-italic">Расписание</td>
                <td>Просмотр</td>
                <td>Просмотр графика работы сотрудников</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение занятости</td>
                <td>Постановка отметок о занятости сотрудника</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование занятости задним числом</td>
                <td>Изменение отметки о занятости за прошлый период</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Касса компании</td>
                <td class="font-italic">Касса компании</td>
                <td>Просмотр</td>
                <td>Просмотр остатка денежных средств на счетах и в кассе компании</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Операции с кассой</td>
                <td>Внутреннее перемещение денежных средств компании</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Операции с кассой внешние</td>
                <td>Вывод денежных средств из компании собственником (получение прибыли)</td>
              </tr>
              <tr>
                <td colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Маяки</td>
                <td class="font-italic">Карта</td>
                <td>Просмотр</td>
                <td>Просмотр местоположения автомобилей на карте</td>
              </tr>
              <tr>
                <td  colspan="4"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Настройки компании</td>
                <td class="font-italic">Профиль компании</td>
                <td>Просмотр</td>
                <td>Просмотр данных о компании</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование</td>
                <td>Внесение изменений в основные данные компании</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Загрузка шаблонов</td>
                <td>Загрузка шаблонов документов для печати</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
