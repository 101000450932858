<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1">Как добавить автомобиль</v-card-title>
        <v-card-subtitle class="subtitle-1"
          >Добавление автомобиля в систему</v-card-subtitle
        >
        <v-card-text>
          Заходим в п. меню <router-link to="/cars">«Автомобили»</router-link> и
          в правом верхнем углу нажимаем кнопку
          <v-btn small color="success">Добавить авто</v-btn>. Открывается
          карточка автомобиля. Для создания нового автомобиля достаточно
          заполнить его название, код авто (уникальное обозначение, по которому
          будут отличаться друг от друга автомобили одной модели, например: Рено
          Сандеро – РС1, РС2 и т.д.) и гос. номер. Желательно указать №
          сортировки, тогда автомобили во всех списках будут отсортированы в
          удобном для вас порядке. Номер сортировки рекомендуем делать с шагом -
          10, т.е. 10, 20, 30 … и т.д. Благодаря этому в будущем, при покупке
          новых автомобилей, при необходимости, их можно будет вставить между
          существующими, присвоив им порядковый номер между уже созданными
          (например: 12, 14, 17 … и т.д.). На первоначальном этапе остальные
          данные можно не заполнять, но в будущем они могут понадобиться.
          Например, чтобы в акте приема-передачи авто автоматически указывался
          VIN, этот параметр необходимо заполнить именно здесь. После заполнения
          основных характеристик автомобиля нажимаем кнопку «Сохранить» в нижней
          части карточки авто. Но на этом процесс создания автомобиля не
          заканчивается, т.к. для активации машины нужно обязательно заполнить
          раздел «Цены».
        </v-card-text>
        <v-card-text>
          После завершения вышеуказанных действий вновь созданный автомобиль
          появляется в списке автомобилей, но при этом он не станет доступным
          для бронирования. Заходим в карточку созданного автомобиля нажатием на
          его название или код или двойным кликом по строке указанного
          автомобиля в списке. Теперь в карточке появились дополнительные
          вкладки. Нажимаем на вкладку «Цены». И нажимаем на карандаш рядом с
          названием машины – при этом появляется кнопка добавления сезона цен. В
          первую очередь, обязательно нужно выбрать сезон «Основные цены» и
          добавить соответствующие цены. Остальные сезоны можно заполнить при
          необходимости. (Сезоны задаются в <router-link to="/company_profile">Настройках компании</router-link>). После
          добавления основных цен нажимаем кнопку «Сохранить» под созданными
          ценами. Далее можно заполнить поля стоимости одного часа аренды и
          размер залога, и нажать «Сохранить» под ними. После этого обязательно
          переключите ползунок «Активная» в правом верхнем углу. Теперь
          автомобиль появляется во всех списках и доступен для бронирования.
        </v-card-text>
        <v-card-text>
          Далее, при необходимости, можно снова зайти в карточку машины, внести
          остальные данные, заполнить информацию о периодическом техобслуживании
          и загрузить фотографии автомобиля.
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
