<template>
  <form>
    <div class="image-container mb-3" v-if="previewPath">
      <img :src="previewPath" alt="Uploaded Image Preview" />
    </div>
    <div class="form-group">
      <div ref="dashboardContainer"></div>
    </div>
    <!-- <v-btn :disabled="disabled" @click.prevent="confirmUpload" class="btn btn-primary btn-block mb-2">Confirm upload</v-btn> -->
  </form>
</template>

<script>
import Uppy from "@uppy/core";

import Dashboard from "@uppy/dashboard";
import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    maxFileSizeInBytes: {
      type: Number,
      required: true,
    },
    editedClient: {
      type: Object,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      previewPath: null,
      disabled: true,
    };
  },
  mounted() {
    this.instantiateUppy();
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    instantiateUppy() {
      let self = this;
      this.uppy = Uppy({
        debug: true,
        locale: this.$i18n.locale === "ru" ? Russian : English,
        autoProceed: true,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 10,
          allowedFileTypes: [
            "image/*",
            ".heic",
            ".heif",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
          ],
        },
      })
        .use(Dashboard, {
          hideUploadButton: true,
          theme: this.theme,
          inline: true,
          width: this.width,
          height: this.height,
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          locale: {
            strings: {
              poweredBy2: "",
              // browseFiles: "выберите файлы"
            },
          },
        })
        .use(AwsS3Multipart, {
          limit: 10,
          // companionUrl: "http://localhost:3000/cars/"
          companionUrl: "https://rentprog.net/cars/",
        });

      this.uppy.on("complete", (event) => {
        event.successful.forEach((element) => {
          if (element !== undefined) {
            console.log(element.s3Multipart.key);
            self.axios
              .post(
                `/api/v1/cars_files/${self.$route.params.id}`,
                {
                  cars_files: {
                    name: `${element.s3Multipart.key}`,
                    url: `https://rentprog.storage.yandexcloud.net/${element.s3Multipart.key}`,
                  },
                },
                {
                  headers: {
                    Authorization: self.$store.getters.getAuthToken,
                  },
                }
              )
              .catch((error) => {
                this.setError(error, this.$t("errors.error"));
              });
          }
        });
      });

      const uploadedFileData = (file, response) => {
        return JSON.stringify(response.body);
      };
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;

      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
    confirmUpload() {
      if (this.payload) {
        this.disabled = true;
        axios
          .post("/store", { file: this.payload })
          .then(({ data }) => {
            this.updatePreviewPath(data).resetUploader();
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Файлы загружены!",
              text: "Вы успешно загрузили файлы!",
            });
          })
          .catch((err) => {
            console.error(err);

            this.resetUploader();
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Ошибка при загрузке!",
          text: this.error,
        });
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container>img {
  width: inherit;
  height: inherit;
}
</style>
