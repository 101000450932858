var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.checkMobile),expression:"checkMobile"}]},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-container',{staticClass:"text-center"},[_c('v-toolbar',{staticClass:"align-center",attrs:{"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("analitics.common_workers"))+" "+_vm._s(_vm.$t("custom.number"))+_vm._s(_vm.report.id)+" "+_vm._s(_vm.start_date_formatted)+" - "+_vm._s(_vm.end_date_formatted))]),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('analitics.show_unactive')},model:{value:(_vm.with_not_active),callback:function ($$v) {_vm.with_not_active=$$v},expression:"with_not_active"}}),(!_vm.isIos)?_c('v-btn',{attrs:{"icon":"","href":`https://rentprog.${
          _vm.$root.$i18n.locale == 'ru' ? 'ru' : 'com'
        }/${_vm.$root.$i18n.locale}/categories/8/guides/25`,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1):_vm._e()],1),(_vm.report.state == 'created')?_c('v-flex',{staticClass:"d-inline-flex",attrs:{"md12":""}},[_c('v-data-table',{class:!_vm.isMobile ? 'mt-5' : 'mt-5 is-mobile',attrs:{"dense":"","headers":_vm.headers,"items":_vm.filtered_report,"loading":_vm.isLoading,"loading-text":_vm.$t('custom.loading_table'),"search":_vm.search,"items-per-page":50,"sort-by":['created_at'],"mobile-breakpoint":"100","sort-desc":[true],"footer-props":{
          pageText: `{0} ${_vm.$t('custom.of')} {1}`,
          itemsPerPageText: _vm.$t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:props.item.worker.active ? '' : 'grey--text text--lighten-1'},[_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.worker.name))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.work_days))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.holidays))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.sick_days))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.checkins))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.checkouts))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.salary))])])]}}],null,false,155226208)})],1):_c('v-flex',{staticClass:"text-center mt-10",attrs:{"md12":""}},[_vm._v(" "+_vm._s(_vm.$t("analitics.report_in_progress"))+" "),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}),_c('br'),_c('v-btn',{on:{"click":function($event){return _vm.$router.go()}}},[_vm._v(_vm._s(_vm.$t("custom.update")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }