<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1">Быстрый старт</v-card-title>
        <v-card-subtitle class="subtitle-1"
          >Первые настройки после регистрации</v-card-subtitle
        >
        <v-card-text>
          После регистрации вам необходимо заполнить информацию о себе и
          компании. Добавить сотрудников и автомобили. Многие кнопки и знаки
          имеют подсказки, нужно толыько навести указатель мыши. Если возникнут
          вопросы, пишите нам в чат или на почту,
          <a href="mailto:'admin@rentprog.ru'">admin@rentprog.ru</a>
          Следующие пункты меню находятся в правом верхнем углу, по нажатию на
          аватарку или имя.
        </v-card-text>
        <v-card-title>Профиль пользователя</v-card-title>
        <v-card-text>
          После регистрации поле имя и email должны быть заполнены, если этого
          не произошло или вы хотите что-нибудь поменять, нажмите на карандаш,
          рядом с заголовком и все поля станут доступны для редактирования. В
          поле telegram_id необходимо вставить ваш id в телеграме. Это
          необходимо для возможности пользоваться телеграм-ботом системы. На
          данный момент там можно в краткой форме посмотреть брони на сегодня и
          завтра, разделенные по приёмам и выдачам. Так же, в телеграм будут
          направляться технические уведомления о новых бронях, просроченных
          ремонтах и тд.
        </v-card-text>
        <v-card-title>Настройки компании</v-card-title>
        <v-card-text>
          Данная страница имеет 4 раздела: Цены, Аренда, Компания, Шаблоны.
          Остановимся на каждом подробнее.
        </v-card-text>
        <v-card-subtitle class="subtitle-1"
          >Настройки компании. Периоды цен</v-card-subtitle
        >
        <v-card-text>
          В разделе Цены вы можете установить периоды цен, т.е промежутки
          количества суток, от которых в вашей компании меняется цена
          (предоставляется скидка). После регистрации, по умолчанию, создаются
          периоды 1 - 3 суток, 4 - 7 суток, 8 - 15 суток, 16 - 31 суток. В ценах
          в карточках автомобилей появятся поля для каждого промежутка.
          Соответственно, будет 4 цены. Они могут быть как одинаковыми, так и
          разными. Чтобы изменить периоды цен необходимо нажать на карандаш в
          верхней части экрана у заголовка. Станет доступна возможность
          добавлять периоды. Даже если периоды уже были заданы, и вы хотите их
          поменять, необходимо добавить их заново. Нажимаем на карандаш,
          появляется строка для первого периода и кнопка "Добавить". Прикидываем
          сколько вам необходимо периодов и добавляем их нажатием кнопки
          "Добавить". Сейчас вы видите незаполненные периоды, их необходимо
          заполнить. Путем передвижения ползунков слева вы должны выставить
          необходимые периоды. На первой строке выбираем с первого дня, например
          1-3, далее нажимаем кнопку "Добавить", появится еще одна строка. На
          второй строке период должен начинаться со следующей цифры, в нашем
          примере это 4. Выставляем необходимое значение двумя ползунками,
          например 4-7. И так далее во всех периодах. Далее, вверху, рядом с
          карандашом нажать на зеленую иконку сохранения. Максимальный размер
          периода может быть 1 - 31 день, при таком подходе у вас будет только
          одна цена на автомобиль, без автоматических скидок за количество
          суток.
        </v-card-text>
        <v-card-subtitle class="subtitle-1"
          >Настройки компании. Сезоны цен</v-card-subtitle
        >
        <v-card-text>
          Далее, нужно настроить сезоны цен, если вам это необходимо. Сезон цен
          – это период времени в году, в течение которого действуют определенные
          цены. Можно, например, увеличить цены на время высокого спроса, или
          снизить в несезон. Это необязательный параметр, достаточно задать
          только основные цены, и в ручном режиме проставлять скидки в каждой
          брони. Сезоны цен нужны для того, чтобы при раннем бронировании,
          например, на лето, цены брались именно на этот период, а не текущие.
          Это удобно, если у вас много броней приходят с сайта заранее. Если
          большинство броней создается вручную, то лучше сезоны не создавать.
          Итак, чтобы создать сезоны, необходимо нажать плюс справа от надписи
          «Сезонов цен нет». Далее выбрать даты (внимание, необходимо указывать
          правильное количество дней в месяце, 28, 30 или 31), и при
          необходимости добавить несколько строк дат путём нажатия на плюс и
          нажать на зеленую кнопку сохранения.
        </v-card-text>
        <v-card-title>Аренда</v-card-title>
        <v-card-text>
          В данной вкладке необходимо заполнить лимит минимального возраста
          клиента и минимальное количество суток аренды, они будут учитываться при
          создании брони и сигнализировать о несоответствии. При необходимости
          заполнить ограничение пробега в сутки за бронь и стоимость превышения.
          Если вы не взымаете плату за перепробег, оставьте в строке ноль. В
          пункте «Минимальный зазор между бронями» необходимо указать время в
          часах на подготовку автомобиля к выдаче, чтобы при бронировании и
          сортировке свободных автомобилей брался зазор.
        </v-card-text>
        <v-card-title>Компания</v-card-title>
        <v-card-text>
          В данной вкладке необходимо заполнить данные компании. Вся эта
          информация необходима для проставления в рассылаемых письмах. Так же
          нужно указать какие виды платежей вы принимаете.
        </v-card-text>
        <v-card-title>Шаблоны</v-card-title>
        <v-card-text>
          В данной вкладке настраиваются шаблоны договоров, актов и т.д. Для
          начала нужно зайти на страницу с описанием
          <router-link to="/help/doc_variables"
            >переменных в шаблоне</router-link
          >. Здесь представлены все переменные которые можно вставлять в
          договор. Пример заполненного шаблона можно посмотреть
          <a href="/doc_example.docx">здесь</a>. Сам по себе шаблон представляет
          собой обычный «вордовский» файл из Microsoft Office в формате docx. Вы
          можете взять свои текущие договор и акт, соединить их в один файл и
          проставить переменные в тех местах где это необходимо. Есть два вида
          договоров, для физических и юридических лиц, соответственно нужно подготовить и
          загрузить оба. Так же нужно подготовить шаблоны счетов и актов сверки.
          Если у вас возникнут проблемы с созданием шаблона, обратитесь к нам и
          мы вам поможем. Внесение изменений в шаблоны можно производить простой
          загрузкой новых версий. Далее необходимо добавить автомобили.
        </v-card-text>
        <v-card-title>Автомобили</v-card-title>
        <v-card-text>
          Добавление новых автомобилей производится на
          <router-link to="/cars/new">данной странице</router-link>. Необходимо
          заполнить обязательные данные об автомобиле: марка и модель, код авто,
          № сортировки, гос номер, класс авто, дату и цену покупки. Остальные
          параметры можно заполнить позже. На вкладке «Обслуживание» заполняются
          данные для периодического обслуживания и напоминания об этом, их так
          же можно заполнить позже.
        </v-card-text>
        <v-card-subtitle class="subtitle-1">Автомобили. Цены</v-card-subtitle>
        <v-card-text>
          После добавления автомобиля можно добавить цены. Переходим на страницу
          <router-link to="/cars/new">автомобилей</router-link> и выбираем
          нужный автомобиль. Перейти в автомобиль можно кликнув по его ID в
          системе, коду или двойным кликом мышки по строке автомобиля в таблице.
          Перейдя в автомобиль, мы попадаем на закладку характеристики, тут
          можно заполнить недостающие, но сейчас нам нужно добавить цены,
          поэтому мы переходим в закладку «Цены». Для начала создания цен,
          необходимо нажать на иконку карандаша рядом с названием автомобиля,
          после чего появится кнопка «Добавить», нажимаем ее и появляется строка
          с выбором сезона и колонки с периодами цен добавленными ранее. Для
          начала нужно задать основные цены, поэтому выбираем «Основные цены» и
          проставляем нужные нам цены. После нажимаем на кнопку «Сохранить».
          Теперь, мы готовы создать первую бронь.
        </v-card-text>
        <v-card-title>Брони</v-card-title>
        <v-card-text>
          Переходим на страницу
          <router-link to="/booking/new">новая бронь</router-link> и выбираем
          автомобиль, если клиент новый, то справа нажимаем на иконку «Добавить
          клиента», и создаем нового клиента, заполнив необходимые данные.
          Обязательными полями являются «Фамилия» «Имя» «Отчество», так же лучше
          ввести дату рождения, телефон и email клиента, так как они понадобятся
          для генерации договора и рассылки подтверждения. Чем больше данных о
          клиенте внесете при его создании, тем лучше. Нажимаем «Сохранить».
          Новый клиент автоматически выбирается. Далее необходимо выбрать даты и
          время начала и окончания аренды. После этого появятся цены и расчёт по
          брони. Вносим место выдачи и приёма. При наличии дополнительных услуг, таких как
          доставка или дополнительная страховка и др, необходимо нажать на
          кнопку «Доп услуги» появится выбор услуг и соответствующие им поля,
          куда нужно проставить стоимость на весь срок аренды. Они автоматически
          добавятся в расчёт. Так же можно добавить дополнительную скидку, в
          процентах или в рублях. Лучше придерживаться целых сумм без копеек.
          Это намного удобнее. Можно добавить дополнительную информацию или
          дополнительного водителя. Бронь готова, нажимаем «Создать». Новая
          бронь готова и активирована. Есть две возможности создавать бронь,
          через сайт компании (необходима интеграция по API или можно встроить
          готовую форму заявки, которую можно запросить в поддержке) или
          вручную, как мы описали выше. При ручном создании, бронь активируется
          автоматически и появляется в таблице активных броней. Она учитывается
          при проверке доступности автомобиля при добавлении новых броней и
          видна в календаре. При поступлении брони через сайт она попадает в
          неактивные брони. В ней необходимо заполнить недостающие данные, и
          активировать.
          <br />
          <br />
          Посмотреть текущие брони можно в таблице броней или в календаре. После
          завершения аренды, бронь деактивируется и попадает в неактивные, после
          проверки администратором ее можно заархивировать.
          <br />
          <br />
          <span class="font-italic">
            Это краткая инструкция для первого знакомства с сервисом. В ней
            собран минимум обязательных шагов для создания первой брони. Чтобы
            ознакомиться с полным руководством, перейдите в соответствующий
            раздел.
          </span>
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
