<template>
  <v-app id="inspire">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t('users.enter_new_password') }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model.trim="password"
                  :append-icon="
                    show_password ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                  "
                  :type="show_password ? 'text' : 'password'"
                  name="input-10-1"
                  :hint="$t('users.password_length')"
                  counter
                  @click:append="show_password = !show_password"
                  :label="$t('users.password')"
                  required
                ></v-text-field>
                <v-text-field
                  v-model.trim="confirm_password"
                  :append-icon="
                    show_password ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                  "
                  :type="show_password ? 'text' : 'password'"
                  name="input-10-1"
                  :hint="$t('users.password_length')"
                  counter
                  @click:append="show_password = !show_password"
                  :label="$t('users.password_repeat')"
                  required
                ></v-text-field>
                <v-flex class="text-center">

                <v-btn @click="submit()" color="primary"
                  >{{ $t('custom.save') }}</v-btn
                >
                <div class="mt-2">
                  <router-link to="/signin">{{ $t('users.enter') }}</router-link>
                  <br />
                  <router-link to="/signup">{{ $t('users.registration') }}</router-link>
                </div>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import firebase from "firebase/app";
// import "firebase/auth";
export default {
  data() {
    return {
      email: null,
      password: null,
      confirm_password: null,
      show_password: false,
      error: "",
      notice: "",
      show1: false,
      isLoading: false
    };
  },
  methods: {
    submit() {
      let self = this;
      this.isLoading = true;
      if (this.password && this.confirm_password && this.password == this.confirm_password && this.password.length >= 6) {
        this.axios
          .post(`/api/v1/reset_password?token=${this.$route.params.token}`, {
            password: this.password,
            confirm_password: this.confirm_password
          })
          .then(response => {
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: self.$t('users.password_success')
            });
            self.isLoading = false;
            self.$router.push({ path: `/signin` }).catch(err => {});
          })
          .catch(error => {
            self.isLoading = false;
            self.setError(error, self.$t('errors.pass_recover'));
            self.$swal({
              showConfirmButton: true,
              icon: "error",
              title:
                self.$t('errors.pass_recover_long'),
              text: error
            });
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        self.isLoading = false;
        self.$swal({
          icon: "warning",
          title: self.$t('errors.short_pass')
        });
      }
    },
    setError(error, text) {
      this.$rollbar.error(text, error);
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
