<template>
  <v-card v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-sheet
      v-if="main_company.beacons_on"
      color="white"
      elevation="1"
      :height="windowHeight - 100"
      width="100%"
    >
      <vue-gmap
        :center="markerCenter"
        :zoom="10"
        :style="`width: '100%'; height: '100%';`"
      >
        <gmap-custom-marker
          v-for="marker in positions"
          :key="marker.id"
          alignment="center"
          :marker="{ lat: marker.latitude, lng: marker.longitude }"
          @click.native="getReportData(marker)"
        >
          <v-flex class="black--text d-flex align-middle">
            <v-avatar size="30" class="mr-1" color="white">
              <v-icon :color="returnStateColor(marker)">mdi-car</v-icon>
            </v-avatar>
            <!-- <v-icon small :color="returnStateColor(marker)">mdi-car</v-icon> -->
            <div class="mb-5">{{ findCarNew(marker) }} - {{ marker.speed }} {{ $t('cars.kilometers_hour') }}</div>
          </v-flex>
        </gmap-custom-marker>
      </vue-gmap>
    </v-sheet>
    <vue-gmap
      v-else
      :center="markerCenter"
      :zoom="10"
      style="width: 100%; height: 800px;"
    >
      <!-- <gmap-custom-marker
          alignment="bottomright"
          key="supermarker"
          :marker="markerCenter"
        >
          <div class="card" @click="e => e.stopPropagation()">
            <img :class="animation" class="icon" :src="src" height="30" />
          </div>
        </gmap-custom-marker> -->
      <gmap-custom-marker
        v-for="marker in mayaks"
        :key="marker.id"
        alignment="center"
        :marker="{ lat: marker.lat, lng: marker.lng }"
      >
        <div class="live-html black--text">
          <v-icon small :color="returnStateColor(marker)">mdi-car</v-icon>
          {{ findCar(marker) }} - {{ marker.v }} км/ч
        </div>
      </gmap-custom-marker>
    </vue-gmap>
    <div id="map-container" md12 style="max-height:700px;">
    </div>
    <v-dialog
      v-model="showReportDialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
      :style="isMobile ? 'padding: 5px !important;' : ''"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ reportTitle(report)}}
          <v-spacer></v-spacer>
            <v-icon
              :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
              @click.stop="showReportDialog = false, report = {}"
            >
              mdi-close
            </v-icon>
        </v-card-title>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-layout row wrap>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.averageSpeed"
                  type="text"
                  :label="$t('trackers.average_speed')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.deviceId"
                  type="text"
                  :label="$t('trackers.deviceId')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.distance"
                  type="text"
                  :label="$t('trackers.distance')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.endOdometer"
                  type="text"
                  :label="$t('trackers.endOdometer')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.engineHours"
                  type="text"
                  :label="$t('trackers.engineHours')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.maxSpeed"
                  type="text"
                  :label="$t('trackers.maxSpeed')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.spentFuel"
                  type="text"
                  :label="$t('trackers.spentFuel')"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm12>
                <v-text-field
                  v-model="report.startOdometer"
                  type="text"
                  :label="$t('trackers.startOdometer')"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="showReportDialog = false, report = {}">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { Map } from "gmap-vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  created() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.autofon_token &&
        this.$store.getters.getCompany.company.autofon_pwd
      ) {
          this.connectToTraccar();
          console.log(document.getElementById("map-container"));
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t('errors.beacon_load'),
          text: this.$t('errors.beacon_load_text')
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    if(this.main_company.beacons_on) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/cars_for_map", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then(cars_response => {
          this.cars = [];
          cars_response.data.data.forEach(car => {
            this.cars.push(car.attributes)
          })
          this.pollData();
        })
        .catch(error => {
          self.setError(error, self.$t('errors.beacon_load'));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.autofon_token &&
        this.$store.getters.getCompany.company.autofon_pwd
      ) {
        this.isLoading = true;
        this.axios({
          method: "post",
          url: `https://gprs.autofon.ru:9443/jsonapi/objects/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            this.imeis = response.data;
            this.axios({
              method: "post",
              url: `https://gprs.autofon.ru:9443/jsonapi/laststates/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
              headers: {
                "Content-Type": "application/json"
              }
            })
              .then(response => {
                self.mayaks = response.data;
              })
              .catch(error => {
                self.setError(error, self.$t('errors.beacon_load'));
                console.log(error);
              });
          })
          .catch(error => {
            this.setError(error, this.$t('errors.beacon_load'));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t('errors.beacon_load'),
          text: this.$t('errors.beacon_load_text')
        });
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  data() {
    return {
      mayaks: [],
      imeis: [],
      cars: [],
      positions: [],
      report: {},
      marker: {
        lat: 50.60229509638775,
        lng: 3.0247059387528408
      },
      windowHeight: window.innerHeight,
      showReportDialog: false,
      batchCount: 10,
      html: "<center><h2>This is <u>live</u> html marker 🔥</h2><center>",
      infinite: true,
      selectedAnimation: "",
      zA: 50,
      zB: 51,
      testText: null,
      markerCenter: {
        lat: 54.6272265,
        lng: 20.0571581
      },
      addWeather: false,
      addMode: false,
      markers: [],
      ids: 0,
      alignment: "top",
      batchMarkers: [],
      tools: true,
      polling: null,
      connection: null,
      isLoading: false,
      isMobile: false,
    };
  },
  destroyed() {
    clearInterval(this.polling);
  },
  computed: {
    animation() {
      const infinite = this.infinite ? "infinite" : "";
      return `animated ${infinite} ${this.selectedAnimation}`;
    },
    src() {
      return this.testText
        ? this.testText
        : "https://vuejs.org/images/logo.png";
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
  },
  methods: {
    pollData() {
      let self = this;
      this.polling = setInterval(() => {
        self.getPositions();
      }, 30000);
    },
    updateMayak() {
      let self = this
      this.axios({
        method: "post",
        url: `https://gprs.autofon.ru:9443/jsonapi/objects/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          console.log('objects', response.data)
          this.imeis = response.data;
          this.axios({
            method: "post",
            url: `https://gprs.autofon.ru:9443/jsonapi/laststates/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(response => {
              console.log('laststates', response.data)
              self.mayaks = response.data;
            })
            .catch(error => {
              self.setError(error, self.$t('errors.beacon_load'));
              console.log(error);
            });
        })
        .catch(error => {
          this.setError(error, this.$t('errors.beacon_load'));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    findCar(marker) {
      let result = this.imeis.find(imei => imei.id == marker.id);
      if (result) {
        return result.desc;
      } else {
        return marker.id;
      }
    },
    findCarNew(marker) {
      let car = this.cars.find(car => car.traccar_id == marker.deviceId);
      if (car) {
        return `${car.code}`;
      } else {
        return "?";
      }
    },
    connectToTraccar() {
      let self = this
      let data = new URLSearchParams({
        "email": this.$store.getters.getCurrentUser.email,
        "password": this.$store.getters.getCurrentUser.traccar_password
      })
      // authorization in socket
      let session_url = "https://vos-rentprog.ru/api/session"
      let new_session_resp = this.axios({
        method: "post",
        url: session_url,
        headers: {
          "Authorization": "Basic " + btoa(`${this.$store.getters.getCurrentUser.email}:${this.$store.getters.getCurrentUser.traccar_password}`),
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      })
      .then(response => {
        this.markerCenter = {
          lat: response.data.latitude,
          lng: response.data.longitude,
        }
        this.getPositions();
      })
      .catch(error => {
        console.log(error)
      })
    },
    getPositions() {
      let positions_url = `https://vos-rentprog.ru/api/positions`
      this.axios({
        method: "get",
        url: positions_url,
        headers: {
          "Authorization": "Basic " + btoa(`${this.$store.getters.getCurrentUser.email}:${this.$store.getters.getCurrentUser.traccar_password}`),
          "Content-Type": "application/x-www-form-urlencoded"
        },
      })
      .then(positions_response => {
        this.positions = positions_response.data;
      })
      .catch(error => {
        this.setError(error, this.$t('errors.beacon_load'));
        console.log(error);
      })
    },
    getReportData(marker) {
      this.isLoading = true;
      let from = moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      let to = moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      let report_url = `https://vos-rentprog.ru/api/reports/summary?deviceId=${[marker.deviceId]}&from=${from}&to=${to}`
      this.axios({
        method: "get",
        url: report_url,
        headers: {
          "Authorization": "Basic " + btoa(`${this.$store.getters.getCurrentUser.email}:${this.$store.getters.getCurrentUser.traccar_password}`),
          "Content-Type": "application/x-www-form-urlencoded"
        },
      })
      .then(report_response => {
        this.report = report_response.data[0];
        this.showReportDialog = true;
      })
      .catch(error => {
        this.setError(error, this.$t('errors.beacon_load'));
        console.log(error);
      })
      .finally(() => (this.isLoading = false));
    },
    returnStateColor(marker) {
      if (marker.valid == 1) {
        if(marker.speed > 0) {
          return "success";
        } else {
          return "grey";
        }
      } else if (marker.valid == 0) {
        return "warning";
      } else {
        return "secondery";
      }
    },
    reportTitle(report) {
      if (report.deviceName) {
        return `${report.deviceName}: ${moment(report.startTime).format('lll') } - ${moment(report.endTime).format('lll')}`
      } else {
        return `${moment(report.startTime).format('lll') } - ${moment(report.endTime).format('lll')}`
      }
    },
    setMapHeight() {
      console.log(document.getElementById("map-container").clientHeight);
      // // get ref to map container
      // let mapContainer = this.$refs["map-canvas"];
      // // get height of map container
      // let mapContainerHeight = mapContainer.clientHeight;
      // // set height of map container
      // return `height: ${mapContainerHeight}px`;
    },
    // displayTools(value) {
    //   this.tools = value;
    // },
    // deleteMarker(i) {
    //   this.markers.splice(i, 1);
    // },
    // onMapClick(event) {
    //   if (this.addMode) {
    //     this.markers.push({
    //       _id: this.ids++,
    //       latitude: event.latLng.lat(),
    //       longitude: event.latLng.lng(),
    //       weather: this.addWeather,
    //       alignment: this.alignment
    //     });
    //     this.addMode = false;
    //   }
    // },
    addbatch() {
      this.batchMarkers = [];
      for (let i = 0; i < this.batchCount; i++) {
        this.batchMarkers.push({
          lat: `48.1${String(parseInt(Math.random() * 100000))}`,
          lng: `-1.7${String(parseInt(Math.random() * 100000))}`
        });
      }
      this.markerCenter = {
        lat: 48.1030572,
        lng: -1.7065389
      };
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {
    GmapCustomMarker,
    "vue-gmap": Map
  }
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
.vue-map-container {
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.live-html {
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 3px 3px 3px grey;
  /* min-height: 200px;
  min-width: 200px; */
  background-color: #fafafa;
}
.zindex {
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 3px 3px 3px grey;
  min-height: 100px;
  min-width: 100px;
  font-size: 1.3em;
}
.zindex-a {
  background-color: #eb7bff;
}
.zindex-b {
  background-color: #17e2b8;
}
.alignment {
  right: 10px;
  z-index: 1000;
  padding: 4px;
  top: 15px;
  border-radius: 5px;
}
.weather {
  top: 70px;
}
.icon-sm {
  padding: 3px;
  border-radius: 4px;
}
.animate-panel {
  padding: 7px;
  right: 10px;
  top: 150px;
  height: 100px;
  width: 250px;
  z-index: 100;
}
.live-editor {
  right: 10px;
  top: 270px;
  z-index: 1000;
  height: 100px;
  width: 250px;
  border: 1px solid #ccc;
}
.live-editor textarea {
  width: 100%;
  height: 100%;
}
.add-marker {
  padding: 8px;
  font-size: 1.3em;
}
.batch-cluster {
  right: 0px;
  top: 400px;
  width: 270px;
  height: 40px;
  border-radius: 5px;
  z-index: 100;
}
.card-tools {
  right: 5px;
  top: 5px;
  padding: 8px;
  background-color: #fafafa;
  border: 1px solid #bbb;
  width: 270px;
  z-index: 100;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.batch-cluster > input,
.batch-cluster > button {
  margin-left: 10px;
  margin-right: 10px;
}
.hide-tool {
  background-color: #fafafa;
  border: 1px solid #bbb;
  font-size: 1.3em;
}
.show-tool {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fafafa;
  border: 1px solid #bbb;
  font-size: 1.3em;
  z-index: 100;
}
</style>
