<template>
  <v-row>
    <v-col cols="12" class="mx-1">
      <v-card>
          <h2 class="text-center my-2">{{ $t("bookings.checkin") }}</h2>
          <v-row justify="center" class="mt-1 mr-3 ml-3 align-center">
            <v-col
              v-for="(item, index) in sortedPhotos"
              :key="item.id"
              cols="4"
            >
              <v-card flat tile class="d-flex flex-column my-2">
                <v-img
                  :src="source(item)"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="showGallery(index)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-row justify="center" class="mt-1 mr-3 ml-3 align-center">
                  <v-icon class="mx-1" color="info" @click="downloadItem(item)">
                    mdi-content-save-outline
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="sortedPhotos.length == 0" class="d-flex text-center justify-center my-3">
            <v-card flat tile class="d-flex text-center justify-center">
              {{ $t("other.no_photo") }}
            </v-card>
          </v-row>
      </v-card>
    </v-col>
    <LightBox ref="lightbox" :media="media" :show-light-box="showLightBox"></LightBox>
    <v-dialog v-model="dialog" :retain-focus="false" max-width="1200">
      <v-card class="mt-2">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(dialog = false), (link_full_image = '')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-img :src="link_full_image" class="grey lighten-2"> </v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="(dialog = false), (link_full_image = '')"
          >
            {{ $t("custom.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { saveAs } from "file-saver";
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
export default {
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      media: [],
      showLightBox: false,
      link_full_image: "",
      dialog: false,
    };
  },
  created() {
    // { // For an image
    //   type: 'image', // Can be omitted for image
    //   thumb: 'http://example.com/thumb.jpg',
    //   src: 'http://example.com/image.jpg',
    //   caption: 'Caption to display. HTML <b>enabled</b>', // Optional
    //   srcset: '...' // Optional for displaying responsive images
    // },
    // { // For a YouTube video
    //   type: 'youtube',
    //   thumb: 'https://img.youtube.com/vi/WsptdUFthWI/hqdefault.jpg',
    //   id: 'WsptdUFthWI',
    //   caption: 'HTML <b>enabled</b> caption to display' // Optional
    // },
    // { // For a video that can be played in the <video> tag
    //   type: 'video',
    //   thumb: 'https://s3-us-west-1.amazonaws.com/powr/defaults/image-slider2.jpg',
    //   sources: [
    //     {
    //       src: 'https://www.w3schools.com/html/mov_bbb.mp4',
    //       type: 'video/mp4'
    //     }
    //   ],
    //   caption: '<h4>Monsters Inc.</h4>',
    //   width: 800, // Required
    //   height: 600, // Required
    //   autoplay: true // Optional: Autoplay video when the lightbox opens
    // }
    this.media = this.photos.map((item) => {
      let type = "image";
      let thumb = item.url;
      if (item.content_type && item.content_type.split("/")[0] == "video") {
        type = "video";
        thumb = "/img/play-button96.png";
      }
      if (type == "image") {
        return {
          type: type,
          thumb: thumb,
          src: item.url,
        };
      } else {
        return {
          type: type,
          thumb: thumb,
          sources: [
            {
              src: item.url,
              type: item.content_type,
            },
          ],
          width: 800,
          height: 600,
        };
      }
    });
  },
  computed: {
    sortedPhotos() {
      return this.photos;
      // if (this.photos && this.photos.length > 0) {
      //   return this.photos.sort((a, b) => {
      //     return b.position - a.position;
      //   });
      // } else {
      //   return [];
      // }
    },
  },
  methods: {
    showGallery(index) {
      console.log('showGallery')
      this.$refs.lightbox.showImage(index)
    },
    async downloadItem(item) {
      this.isLoading = true;
      this.axios
        .get(`api/v1/booking_photos_full/${item.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.isLoading = false;
          saveAs(`${response.data.url}`);
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    source(item) {
      if (item.content_type && item.content_type.split("/")[0] == "video") {
        return "/img/play-button96.png";
      } else {
        return item.url;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    LightBox,
  },
};
</script>
