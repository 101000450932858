<template>
  <span v-resize="checkMobile" :class="$vuetify.theme.dark ? 'dark' : ''">
    <fullscreen ref="fullscreen" @change="fullscreenChange">
      <loading
        :active.sync="isLoading"
        :is-full-page="true"
        color="#1976d2"
        :background-color="$vuetify.theme.dark ? '#000' : ''"
      ></loading>
      <v-toolbar dense v-if="!isMobile" class="start-tour-calendar-1">
        <v-toolbar-title>{{ $t("custom.calendar") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.econom')"
          v-model="showEconom"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.middle')"
          v-model="showMiddle"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.business')"
          v-model="showBusiness"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.commercial')"
          v-model="showCommercial"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-btn
          icon
          @click="hourly_view = !hourly_view"
          :color="hourly_view ? 'warning' : ''"
        >
          <v-icon>mdi-timetable</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="showOnlyFreeCars = !showOnlyFreeCars"
          :color="showOnlyFreeCars ? 'red' : ''"
        >
          <v-icon>mdi-home-circle-outline</v-icon>
        </v-btn>
        <v-btn v-if="!fullscreen" icon @click="toggleFullscreen()">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn v-else icon @click="toggleFullscreen()">
          <v-icon>mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon to="/calendars/bookings">
          <v-icon
            v-tooltip="{
              content: $t('other.old_calendar'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
            >mdi-calendar-month-outline</v-icon
          >
        </v-btn>
        <div
          :class="
            'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
          "
          v-tooltip="{
            content: connected
              ? $t('custom.connected')
              : $t('custom.disconnected'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }"
        ></div>
        <v-btn
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/5/guides/19`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        v-else
        :height="showFilters ? '100px' : '50px'"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="mb-1 start-tour-calendar-1"
      >
        <v-flex md12 class="text-center" justify-center>
          <v-flex md12 class="mt-0 d-flex align-center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-flex>
              <v-icon
                v-if="!showFilters"
                @click="showFilters = !showFilters"
              >
                mdi-chevron-down
              </v-icon>
              <v-icon
                v-else
                @click="showFilters = !showFilters"
              >
                mdi-chevron-up
              </v-icon>
            </v-flex>
            <v-icon
              @click="hourly_view = !hourly_view"
              :color="hourly_view ? 'warning' : ''"
            >
              mdi-timetable
            </v-icon>
          </v-flex>
          <v-flex md12 class="mt-2" v-if="showFilters">
            <v-flex md6 class="d-flex">
              <v-checkbox
                class=""
                :label="$t('cars.econom')"
                v-model="showEconom"
                hide-details
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-checkbox
                class="mr-12"
                :label="$t('cars.middle')"
                v-model="showMiddle"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex md6 class="d-flex">
              <v-checkbox
                class=""
                :label="$t('cars.business')"
                v-model="showBusiness"
                hide-details
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-checkbox
                class=""
                :label="$t('cars.commercial')"
                v-model="showCommercial"
                hide-details
              ></v-checkbox>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-toolbar>

      <timeline
        v-if="!isMobile"
        ref="timeline"
        :items="items"
        :groups="filtered_groups"
        :options="hourly_view ? optionsHourly : options"
        :events="['doubleClick', 'changed', 'click']"
        @double-click="showBooking($event)"
      >
      </timeline>
      <timeline
        v-else
        ref="timeline"
        :items="items"
        :groups="filtered_groups"
        :options="hourly_view ? optionsHourlyMobile : options"
        :events="['click']"
        @click="showBooking($event)"
      >
      </timeline>
      <v-dialog
        v-model="dialog"
        :retain-focus="false"
        max-width="800px"
        persistent
        @click:outside="(booking = null), (dialog = false)"
      >
        <v-card v-if="booking">
          <v-card-text>
            <v-container grid-list-md>
              <v-form>
                <v-container>
                  <v-flex md12 class="text-right">
                    <v-btn icon @click="(booking = null), (dialog = false)">
                      <v-icon class="black--text"> mdi-close </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <h2 class="m-0 p-0 text-center align-center">
                        {{ $t("bookings.booking") }} {{ $t("custom.number")
                        }}{{ booking.id }}
                      </h2>
                      <div v-if="!booking.entity">
                        {{ $t("custom.client") }}:
                        <router-link
                          :to="{ path: `/clients/${booking.client_id}` }"
                          >{{ booking.client.lastname }}
                          {{ booking.client.name }}
                          {{ booking.client.middlename }}</router-link
                        >
                      </div>
                      <div v-else>
                        {{ $t("custom.client") }}:
                        <router-link
                          :to="{ path: `/clients/${booking.client_id}` }"
                          >{{ booking.entity_name }}</router-link
                        >
                      </div>
                      <div>
                        {{ $t("custom.phone") }}:
                        <a :href="'tel:' + booking.client.phone">{{
                          booking.client.phone
                        }}</a>
                      </div>
                      <div>
                        {{ $t("custom.car_long") }}:
                        <router-link :to="{ path: `/cars/${booking.car_id}` }"
                          >{{ booking.car_name }} -
                          {{ booking.car_code }}</router-link
                        >
                      </div>
                      <div>
                        {{ $t("bookings.start_date") }}:
                        {{ booking.start_date }}
                      </div>
                      <div>
                        {{ $t("bookings.end_date") }}: {{ booking.end_date }}
                      </div>
                      <div>{{ $t("custom.days") }}: {{ booking.days }}</div>
                      <div>
                        {{ $t("bookings.checkin") }}:
                        {{ booking.location_start }}
                      </div>
                      <div>
                        {{ $t("bookings.checkout") }}:
                        {{ booking.location_end }}
                      </div>
                      <div>
                        {{ $t("bookings.day_price") }}: {{ booking.price
                        }}{{ currency }}
                      </div>
                      <div>
                        {{ $t("bookings.deposit") }}: {{ booking.deposit
                        }}{{ currency }}
                      </div>
                      <div>
                        {{ $t("bookings.rental_cost") }}:
                        {{ booking.rental_cost }}{{ currency }}
                      </div>
                      <div>
                        {{ $t("bookings.total") }}:
                        {{ booking.total + booking.deposit }}{{ currency }}
                      </div>
                      <div v-if="booking.additional_hours > 0">
                        {{ $t("bookings.add_hours") }}:
                        {{ booking.additional_hours }}
                      </div>
                      <div v-if="booking.additional_hours > 0">
                        {{ $t("bookings.hours_cost") }}: {{ booking.hours_cost
                        }}{{ currency }}
                      </div>
                      <div v-if="booking.delivery > 0">
                        {{ $t("bookings.delivery") }}: {{ booking.delivery
                        }}{{ currency }}
                      </div>
                      <div v-if="booking.delivery_end > 0">
                        {{ $t("bookings.checkout") }}: {{ booking.delivery_end
                        }}{{ currency }}
                      </div>
                      <div v-if="booking.equipment > 0">
                        {{ $t("bookings.equipment") }}: {{ booking.equipment
                        }}{{ currency }}
                      </div>
                      <div v-if="booking.other > 0">
                        {{ $t("bookings.for_other") }}: {{ booking.other
                        }}{{ currency }}
                      </div>
                      <div v-if="booking.description">
                        <h3>{{ $t("bookings.description") }}</h3>
                        <p>{{ booking.description }}</p>
                      </div>
                      <v-flex md12 class="text-center">
                        <v-btn class="mt-2" @click="replaceToBooking(booking)"
                          >{{ $t("bookings.description_2") }}
                        </v-btn>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </fullscreen>
  </span>
</template>

<script>
import { Timeline, DataSet } from "vue-visjs";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
const mdi_spray_bottle = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#795548" viewBox="0 0 24 24"><title>spray-bottle</title><path d="M16.72 10.43C14.68 8.39 14.5 4.66 14.5 4H13V6H9V4H7C7 2.9 7.9 2 9 2H16V3C16 3.08 16.04 7.63 17.78 9.37L16.72 10.43M17 2V4H18V2H17M15 12C13 10 13 7 13 7H9V9C9 10 9 10 8 11S7 13 7 13V20C7 21.1 7.9 22 9 22H13C14.1 22 15 21.1 15 20V12Z" /></svg>`)
const mdi_flare = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#2196f3" viewBox="0 0 24 24"><title>flare</title><path d="M7,11H1V13H7V11M9.17,7.76L7.05,5.64L5.64,7.05L7.76,9.17L9.17,7.76M13,1H11V7H13V1M18.36,7.05L16.95,5.64L14.83,7.76L16.24,9.17L18.36,7.05M17,11V13H23V11H17M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M14.83,16.24L16.95,18.36L18.36,16.95L16.24,14.83L14.83,16.24M5.64,16.95L7.05,18.36L9.17,16.24L7.76,14.83L5.64,16.95M11,23H13V17H11V23Z" /></svg>`)
const mdi_battery = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#8bc34a" viewBox="0 0 24 24"><title>battery</title><path d="M16.67,4H15V2H9V4H7.33A1.33,1.33 0 0,0 6,5.33V20.67C6,21.4 6.6,22 7.33,22H16.67A1.33,1.33 0 0,0 18,20.67V5.33C18,4.6 17.4,4 16.67,4Z" /></svg>`)
const mdi_battery_10 = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#F44336" viewBox="0 0 24 24"><title>battery-10</title><path d="M16,18H8V6H16M16.67,4H15V2H9V4H7.33A1.33,1.33 0 0,0 6,5.33V20.67C6,21.4 6.6,22 7.33,22H16.67A1.33,1.33 0 0,0 18,20.67V5.33C18,4.6 17.4,4 16.67,4Z" /></svg>`)
const mdi_manual = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#ff9800" viewBox="0 0 24 24"><title>car-shift-pattern</title><path d="M8 5H4V2H8V5M4 22H8V19H4V22M14 2H10V5H14V2M10 22H14V19H10V22M16 2V5H20V2H16M17 11H13V7H11V11H7V7H5V17H7V13H11V17H13V13H19V7H17V11Z" /></svg>`)
const mdi_automat = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#ff9800" viewBox="0 0 24 24"><title>alpha-a-circle</title><path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,7A2,2 0 0,0 9,9V17H11V13H13V17H15V9A2,2 0 0,0 13,7H11M11,9H13V11H11V9Z" /></svg>`)
const mdi_plus = 'data:image/svg+xml, ' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="#8bc34a" viewBox="0 0 24 24"><title>plus-circle</title><path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>`)
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      cars: [],
      all_cars: [],
      items: [],
      all_items: [],
      groups: [
        {
          id: 0,
          content: "",
        },
      ],
      all_groups: [],
      finded_booking: null,
      showFilters: false,
      booking: null,
      dialog: false,
      hourly_view: false,
      options: {
        // editable: {
        //   add: true, // add new items by double tapping
        //   updateTime: true, // drag items horizontally
        //   updateGroup: true, // drag items from one group to another
        //   remove: false, // delete an item by tapping the delete button top right
        //   overrideItems: false, // allow these options to override item.editable
        // },
        // cluster: true,
        maxHeight: "1000px",
        margin: {
          axis: 20,
          item: {
            horizontal: 0,
            vertical: 4,
          },
        },
        orientation: "both",
        // itemsAlwaysDraggable: true,
        // zoomMax: 2629800000, // 1 month
        // zoomMin: 604800000, // 1 month
        timeAxis: { scale: "day", step: 1 },
        zoomMax: isMobile ? 1604800000 / 1.5 : 1604800000 * 6, // 1 month
        zoomMin: isMobile ? 1604800000 / 8 : 1604800000, // 1 month
        // minHeight: "500px",
        start: moment().subtract(7, "day"),
        // end: new Date(100000 * 60 * 60 * 24 + new Date().valueOf()),
        locale: "ru",
        // locales: {
        //   // create a new locale (text strings should be replaced with localized strings)
        //   mylocale: {
        //     current: 'current',
        //     time: 'time',
        //     deleteSelected: 'Delete selected',
        //     months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
        //     monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
        //     weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
        //     weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
        //     weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
        //   }
        // },
        // // use the new locale
        // locale: 'mylocale',
        // horizontalScroll: false,
        rollingMode: {
          follow: true,
          offset: 0.1,
        },
        groupOrder: function (a, b) {
          return a.sort - b.sort;
        },
        rtl: false,
        type: "range",
        verticalScroll: true,
        stack: true,
        // stack: false,
        align: "auto",
        groupHeightMode: "auto",
        zoomable: true,
        zoomKey: "ctrlKey",
        moveable: true,
        selectable: false,
        // selectable: true,
        autoResize: true,
        format: {
          minorLabels: {
            day: "D",
          },
        },
        margin: {
          item: {
            horizontal: 0,
          },
        },
      },
      optionsHourly: {
        maxHeight: "1000px",
        margin: {
          axis: 20,
          item: {
            horizontal: 0,
            vertical: 4,
          },
        },
        orientation: "both",
        timeAxis: { scale: "hour", step: 1 },
        zoomMax: 46048000, // 1 day
        zoomMin: 14800, // 1 day
        start: moment().subtract(36, "hour"),
        // end: new Date(100000 * 60 * 60 * 24 + new Date().valueOf()),
        locale: this.$i18n.locale,
        rollingMode: {
          follow: true,
          offset: 0.1,
        },
        groupOrder: function (a, b) {
          return a.sort - b.sort;
        },
        rtl: false,
        type: "range",
        verticalScroll: true,
        stack: true,
        // stack: false,
        align: "auto",
        groupHeightMode: "auto",
        zoomable: true,
        zoomKey: "ctrlKey",
        moveable: true,
        selectable: false,
        // selectable: true,
        autoResize: true,
        format: {
          minorLabels: {
            day: "D",
          },
        },
        margin: {
          item: {
            horizontal: 0,
          },
        },
      },
      optionsHourlyMobile: {
        maxHeight: "1000px",
        margin: {
          axis: 20,
          item: {
            horizontal: 0,
            vertical: 4,
          },
        },
        orientation: "both",
        timeAxis: { scale: "hour", step: 1 },
        zoomMax: 26048000, // 1 day
        zoomMin: 14800, // 1 day
        start: moment().subtract(36, "hour"),
        // end: new Date(100000 * 60 * 60 * 24 + new Date().valueOf()),
        locale: this.$i18n.locale,
        rollingMode: {
          follow: true,
          offset: 0.1,
        },
        groupOrder: function (a, b) {
          return a.sort - b.sort;
        },
        rtl: false,
        type: "range",
        verticalScroll: true,
        stack: true,
        // stack: false,
        align: "auto",
        groupHeightMode: "auto",
        zoomable: true,
        zoomKey: "ctrlKey",
        moveable: true,
        selectable: false,
        // selectable: true,
        autoResize: true,
        format: {
          minorLabels: {
            day: "D",
          },
        },
        margin: {
          item: {
            horizontal: 0,
          },
        },
      },
      mobileOptions: {
        maxHeight: "500px",
        orientation: "top",
        // itemsAlwaysDraggable: true,
        timeAxis: { scale: "day", step: 1 },
        // zoomMax: 2629800000, // 1 month
        // zoomMin: 604800000, // 1 month
        zoomMax: 604800000, // 1 month
        zoomMin: 604800000, // 1 month
        // minHeight: "500px",
        start: new Date(),
        locale: "ru",
        // horizontalScroll: false,
        rollingMode: {
          follow: true,
          offset: 0,
        },
        type: "range",
        verticalScroll: true,
        stack: false,
        align: "center",
        zoomable: false,
        // moveable: true,
        selectable: false,
        autoResize: true,
        // editable: {
        //   add: true, // add new items by double tapping
        //   updateTime: true, // drag items horizontally
        //   updateGroup: true, // drag items from one group to another
        //   remove: true, // delete an item by tapping the delete button top right
        //   overrideItems: false // allow these options to override item.editable
        // },
      },
      windowHeight: "550px",
      fullscreen: false,
      search: null,
      showOnlyFreeCars: false,
      showEconom: false,
      showMiddle: false,
      showBusiness: false,
      showCommercial: false,
      connected: false,
      isLoading: false,
      isMobile: false,
      error: "",
    };
  },
  channels: {
    CalendarChannel: {
      connected() {
        this.connected = "success";
      },
      disconnected() {
        this.connected = "error";
      },
      received(data) {
        if (data.action == "car") {
          let finded_car = this.groups.findIndex(
            (item) => item.id == data.car.id
          );
          if (finded_car > -1) {
            let car_data = {
              id: data.car.id,
              content: data.car.code,
              className: this.returnCarStateColor(data.car.state),
              sort: data.car.sort,
            };
            this.groups.splice(finded_car, 1, car_data);
          }
        }
        let self = this;
        let finded_item = this.items.findIndex(
          (item) => item.id == data.booking.id
        );
        if (data.booking.active) {
          let updated_booking = {
            id: data.booking.id,
            title: `<p class='timeline-tooltip'>
                              <b>${data.booking.id} ${data.booking.location_start} ${data.booking.start_date} - ${data.booking.location_end} ${data.booking.end_date}</b>
                              <br />
                              <b>${data.booking.last_name} ${data.booking.first_name} ${data.booking.middle_name}</b>
                              </p>`,

            content: `${data.booking.location_start} ${moment(
              data.booking.start_date,
              "DD-MM-YYYY H:mm"
            ).format("H:mm")} - ${data.booking.location_end} ${moment(
              data.booking.end_date,
              "DD-MM-YYYY H:mm"
            ).format("H:mm")}`,
            start: moment(data.booking.start_date, [
              "DD-MM-YYYY H:mm",
              "DD-MM-YYYY HH:mm",
            ]),
            end: moment(data.booking.end_date, [
              "DD-MM-YYYY H:mm",
              "DD-MM-YYYY HH:mm",
            ]),
            group: data.booking.car_id,
            className: self.bookingColor(data.booking),
          };
          if (finded_item == -1) {
            this.items.push(updated_booking);
          } else {
            this.items.splice(finded_item, 1, updated_booking);
          }
        } else {
          if (finded_item != -1) {
            this.items.splice(finded_item, 1);
          }
        }
      },
    },
  },
  mounted() {
    PullToRefresh.destroyAll();
    this.$cable.subscribe({
      channel: "CalendarChannel",
      room: this.$store.getters.getCompany.company.id,
    });
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
    if(this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.rent_type == 1) {
      this.hourly_view = true;
    }
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      window.addEventListener("resize", () => {
        this.windowHeight = window.innerHeight;
      });
      this.windowHeight = window.innerHeight;
      if(this.isMobile || this.isNative) {
        this.options.maxHeight = this.windowHeight - 145 + "px";
      } else {
        this.options.maxHeight = this.windowHeight - 195 + "px";
      }
      this.isLoading = true;
      this.axios
        .get("/api/v1/index_for_calendar", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          self.groups = [];
          let show_icons_on_calendar = self.getUserSettings.show_icons_on_calendar;
          response.data.cars.data.forEach((car) => {
            self.cars.push(car.attributes);
            let transmission_auto = car.attributes.transmission == "Автомат" || car.attributes.transmission == "Вариатор" || car.attributes.transmission == "Робот";
            if(show_icons_on_calendar) {
              self.groups.push({
                id: car.attributes.id,
                content: `
                <div>
                  <div>
                  <a href="/cars/${
                    car.attributes.id
                  }">${
                    car.attributes.code
                  }</a>
                  </div>
                  <div>
                    <img src="${transmission_auto ? mdi_automat : mdi_manual}">
                    <img src="${car.attributes.clean_state ? mdi_flare : mdi_spray_bottle}">
                    <img src="${car.attributes.tank_state ? mdi_battery : mdi_battery_10}">
                  </div>
                  <div>
                    <a href="/booking/new?car_id=${car.attributes.id}">
                      <img src="${mdi_plus}">
                    </a>
                  </div>
                </div>
                `,
                className: self.returnCarStateColor(car.attributes.state),
                sort: car.attributes.sort,
              });
            } else {
              self.groups.push({
                id: car.attributes.id,
                content: `
                <div>
                  <div>
                  <a href="/cars/${
                    car.attributes.id
                  }">${
                    car.attributes.code
                  }</a>
                  </div>
                </div>
                `,
                className: self.returnCarStateColor(car.attributes.state),
                sort: car.attributes.sort,
              });
            }
            self.all_groups.push({
              id: car.attributes.id,
              content: car.attributes.code,
              className: self.returnCarStateColor(car.attributes.state),
            });
          });
          response.data.bookings.data.forEach((element) => {
            let booking = element.attributes;
            self.items.push({
              id: booking.id,
              title: `<p class='timeline-tooltip'>
                              <b>${booking.id} ${booking.location_start} ${booking.start_date} - ${booking.location_end} ${booking.end_date}</b>
                              <br />
                              <b>${booking.last_name} ${booking.first_name} ${booking.middle_name}</b>
                              </p>`,

              content: `${booking.location_start} ${moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm")} - ${booking.location_end} ${moment(
                booking.end_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm")}`,
              start: moment(booking.start_date, [
                "DD-MM-YYYY H:mm",
                "DD-MM-YYYY HH:mm",
              ]),
              end: moment(booking.end_date, [
                "DD-MM-YYYY H:mm",
                "DD-MM-YYYY HH:mm",
              ]),
              group: booking.car_id,
              className: self.bookingColor(booking),
            });
          });
          self.items.forEach((item) => self.all_items.push(item));
          // console.log(self.items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  beforeDestroy() {
    // Timeline.destroy();
  },
  components: {
    timeline: Timeline,
  },
  computed: {
    filtered_groups() {
      // Search
      if (
        this.showOnlyFreeCars ||
        (this.search && this.search.length > 0 && this.search != " ") ||
        this.showEconom ||
        this.showMiddle ||
        this.showBusiness ||
        this.showCommercial
      ) {
        if (this.search && this.search.length > 0 && this.search != " ") {
          return this.groups.filter((group) => {
            return group.content
              .toLowerCase()
              .includes(this.search.toLowerCase());
          });
        }
        if (
          this.showEconom ||
          this.showMiddle ||
          this.showBusiness ||
          this.showCommercial
        ) {
          let filtered_cars_ids = [];
          if (this.showEconom) {
            this.cars.forEach((car) => {
              if (car.car_class == "Эконом") {
                filtered_cars_ids.push(car.id);
              }
            });
          }
          if (this.showMiddle) {
            this.cars.forEach((car) => {
              if (car.car_class == "Средний") {
                filtered_cars_ids.push(car.id);
              }
            });
          }
          if (this.showBusiness) {
            this.cars.forEach((car) => {
              if (car.car_class == "Бизнес") {
                filtered_cars_ids.push(car.id);
              }
            });
          }
          if (this.showCommercial) {
            this.cars.forEach((car) => {
              if (car.car_class == "Коммерческий") {
                filtered_cars_ids.push(car.id);
              }
            });
          }
          return this.groups.filter((car) => {
            return filtered_cars_ids.includes(car.id);
          });
        }
        if (this.showOnlyFreeCars) {
          let now = moment();
          let free_cars_ids = [];
          this.items.forEach((item) => {
            if (now.isBetween(moment(item.start), moment(item.end), "hour")) {
              free_cars_ids.push(item.group);
            }
          });
          return this.groups.filter((car) => {
            return !free_cars_ids.includes(car.id);
          });
        }
      } else {
        return this.groups;
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    getUserSettings() {
      if (
        this.$store.getters.getUserSettings
      ) {
        return this.$store.getters.getUserSettings;
      } else {
        return null;
      }
    },
  },
  watch: {
    showFilters() {
      if (this.showFilters) {
        this.options.maxHeight = this.windowHeight - 195 + "px";
      } else {
        this.options.maxHeight = this.windowHeight - 145 + "px";
      }
    },
  },
  methods: {
    showBooking(event) {
      let id = event.item;
      if (event.item) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/bookings/${id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.booking = response.data;
            this.booking.selected_price = this.booking.price;
            this.dialog = true;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.booking_load"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.booking_load"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      }
    },
    bookingColor(booking) {
      if (booking.in_rent && !booking.technical) {
        return "booking_green";
      } else if (booking.in_rent && booking.technical) {
        return "booking_grey_green";
      } else if (!booking.in_rent && booking.technical) {
        return "booking_grey";
      } else {
        return "booking_blue";
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    returnCarStateColor(state) {
      let result;
      if (state == 1) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "success_state_dark";
        } else {
          result = "success_state";
        }
      } else if (state == 2) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "secondary_state_dark";
        } else {
          result = "secondary_state";
        }
      } else if (state == 3) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "error_state_dark";
        } else {
          result = "error_state";
        }
      } else if (state == 4) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "pink_state_dark";
        } else {
          result = "pink_state";
        }
      } else if (state == 5) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "info_state_dark";
        } else {
          result = "info_state";
        }
      } else if (state == 6) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "warning_state_dark";
        } else {
          result = "warning_state";
        }
      } else if (state == 7) {
        if (this.$vuetify.theme && this.$vuetify.theme.dark) {
          result = "error_state_dark";
        } else {
          result = "error_state";
        }
      } else {
        result = "";
      }
      return result;
    },
    toggleFullscreen() {
      this.$refs["fullscreen"].toggle(); // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange(fullscreen) {
      this.options.maxHeight = this.windowHeight + 50 + "px";
      this.fullscreen = fullscreen;
    },
    checkMobile() {
      this.windowHeight = window.innerHeight;
      console.log(this.windowHeight);
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>

<style lang="css">
.pulse {
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
}
.pulse.pulse_success {
  background: rgba(0, 179, 0, 1);
  box-shadow: 0 0 0 0 rgba(0, 179, 0, 1);
  animation: pulse-green 2s infinite;
}
.pulse.pulse_error {
  background: rgb(239, 24, 24);
  box-shadow: 0 0 0 0 rgb(239, 24, 24);
  animation: pulse-error 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 51, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0);
  }
}
/* alternating column backgrounds */
/* .vis-item {
    border-color: orange;
    background-color: yellow;
  } */
.hidden {
  display: none;
}
:hover.hidden {
  display: block;
}
.vis-time-axis .vis-text {
  font-size: small;
}
.vis-time-axis .vis-grid.vis-minor {
  border-width: 2px;
  border-color: black !important;
}
.vis-time-axis .grid.vis-odd {
  background: #f5f5f5;
}
.vis-today {
  background: #afe3fc !important;
}
.dark .vis-today {
  background: #272727 !important;
}

/* gray background in weekends, white text color */
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: rgba(231, 230, 230, 0.76);
}
.dark .vis-time-axis .vis-grid.vis-saturday,
.dark .vis-time-axis .vis-grid.vis-sunday {
  background: rgba(0, 0, 0, 0.02);
}
.vis-time-axis .vis-grid.vis-monday,
.vis-time-axis .vis-grid.vis-tuesday,
.vis-time-axis .vis-grid.vis-wednesday,
.vis-time-axis .vis-grid.vis-thursday,
.vis-time-axis .vis-grid.vis-friday {
  background: rgb(255, 255, 255);
}
.dark .vis-time-axis .vis-grid.vis-monday,
.dark .vis-time-axis .vis-grid.vis-tuesday,
.dark .vis-time-axis .vis-grid.vis-wednesday,
.dark .vis-time-axis .vis-grid.vis-thursday,
.dark .vis-time-axis .vis-grid.vis-friday {
  background: #272727;
}
.vis-major {
  background: rgba(231, 230, 230, 0.76);
}
.vis-top {
  background: rgba(255, 253, 253, 0.76);
}
.dark .vis-major {
  background: #272727;
}
.dark .vis-top {
  background: #272727;
}
.dark .vis-time-axis .vis-text {
  color: white;
}
/* Исправляет косяк с невидимостью!!! */
.vis-timeline {
  visibility: visible !important;
}
/* .vis-time-axis .vis-text.vis-saturday,
.vis-time-axis .vis-text.vis-sunday {
  color: white;
} */
.vis-item .vis-item-content {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px;
  padding-left: 15px;
  font-size: medium;
}
.vis-item {
  border-radius: 17px !important;
}
/* .vis-item-overflow-group {
  margin-bottom: 3px !important;
} */
.vis-foreground .vis-group {
  position: relative;
  box-sizing: border-box;
  border-bottom: 2px solid black !important;
}
.vis-item.red {
  color: white;
  background-color: red;
  border-color: darkred;
}
.vis-item.booking_green {
  color: white;
  background-color: rgba(21, 180, 21, 0.8);
  border-color: #424242 !important;
}
:hover.vis-item.booking_green {
  color: white;
  background-color: rgba(0, 179, 0, 1);
  border: black 1px solid !important;
}
.vis-item.booking_grey {
  color: rgb(0, 0, 0);
  background-color: rgba(128, 128, 128, 0.5);
  border-color: #424242 !important;
}
:hover.vis-item.booking_grey {
  color: rgb(0, 0, 0);
  background-color: rgba(128, 128, 128, 1);
  border-color: #424242 !important;
}
.vis-item.booking_grey_green {
  color: rgb(0, 0, 0);
  background-color: rgba(124, 188, 127, 0.5);
  border-color: #424242 !important;
}
:hover.vis-item.booking_grey_green {
  color: rgb(0, 0, 0);
  background-color: rgba(124, 188, 127, 1);
  border-color: #424242 !important;
}
:hover.vis-item.booking_blue {
  color: white;
  background-color: rgba(59, 135, 229, 1);
}
.vis-item.booking_blue {
  color: white;
  background-color: rgba(59, 135, 229, 0.9);
}
.vis-label.success_state a {
  color: #4caf50 !important;
  text-align: center !important;
  vertical-align: middle;
  text-decoration: none;
}
.vis-label.success_state_dark a {
  color: #4caf50 !important;
  text-align: center !important;
  vertical-align: middle;
  text-decoration: none;
}

.vis-label img {
  width: 18px;
  height: 18px;
}
.vis-label a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: gray !important; */
  border-bottom: 1px solid black !important;
  margin-bottom: 3px;
}
.vis-label div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vis-label div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* plus */
.vis-label div:nth-child(3) {
  display: none;
}
.vis-label div:nth-child(3) a {
  border: none !important;
}
:hover.vis-label div:nth-child(3) {
  display: block;
  position: absolute;
  top: 50%;
  left: 90%;
  border: none;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 100;
}
.vis-label {
  padding: 0 7px;
}
:hover.vis-label {
  background-color: rgba(59, 135, 229, 0.2);
}
/* .vis-group.success_state {
  background: rgba(76, 175, 80, 0.2);
} */
:hover.vis-group.success_state a {
  background: rgba(76, 175, 80, 0.5);
}
:hover.vis-group.success_state_dark a {
  background: rgba(76, 175, 80, 0.5);
}
.vis-label.secondary_state a {
  color: #424242 !important;
  text-align: center;
  vertical-align: middle;
  background: white;
  text-decoration: none;
}
.vis-label.secondary_state_dark a {
  color: #70706e !important;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
/* .vis-group.secondary_state {
  background: rgba(128, 128, 128, 0.2);
} */
:hover.vis-group.secondary_state {
  background: rgba(128, 128, 128, 0.5);
}
:hover.vis-group.secondary_state_dark {
  background: rgba(128, 128, 128, 0.5);
}
.vis-label.error_state a {
  color: red !important;
  text-align: center;
  vertical-align: middle;
  background: white;
  text-decoration: none;
}
.vis-label.error_state_dark a {
  color: red !important;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
/* .vis-group.error_state {
  background: rgba(255, 0, 0, 0.3);
} */
:hover.vis-group.error_state {
  background: rgba(255, 0, 0, 0.5);
}
:hover.vis-group.error_state_dark {
  background: rgba(255, 0, 0, 0.5);
}
.vis-label.pink_state a {
  color: #e91e63 !important;
  text-align: center;
  vertical-align: middle;
  background: white;
  text-decoration: none;
}
.vis-label.pink_state_dark a {
  color: #e91e63 !important;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
/* .vis-group.pink_state {
  background: rgba(233, 30, 99, 0.2);
} */
:hover.vis-group.pink_state {
  background: rgba(233, 30, 99, 0.5);
}
:hover.vis-group.pink_state_dark {
  background: rgba(233, 30, 99, 0.5);
}
.vis-label.info_state a {
  color: blue !important;
  text-align: center;
  vertical-align: middle;
  background: white;
  text-decoration: none;
}
.vis-label.info_state_dark a {
  color: blue !important;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
/* .vis-group.info_state {
  background: rgba(1, 1, 244, 0.2);
} */
:hover.vis-group.info_state {
  background: rgba(20, 20, 165, 0.5);
}
:hover.vis-group.info_state_dark {
  background: rgba(20, 20, 165, 0.5);
}
.vis-label.warning_state a {
  color: #ff9800 !important;
  text-align: center;
  vertical-align: middle;
  background: white;
  text-decoration: none;
}
.vis-label.warning_state_dark a {
  color: #ff9800 !important;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
/* .vis-group.warning_state {
  background: rgba(255, 152, 0, 0.2);
} */
:hover.vis-group.warning_state {
  background: rgba(255, 152, 0, 0.5);
}
:hover.vis-group.warning_state_dark {
  background: rgba(255, 152, 0, 0.5);
}
.vis-rolling-mode-btn:before {
  font-family: "Material Symbols Outlined";
  content: "\e15e";
}
</style>
