export const countries = [
  {
    "name": "Russia",
    "native": "Россия",
    "phone": [
      7
    ],
    "continent": "EU",
    "continents": [
      "AS",
      "EU"
    ],
    "capital": "Moscow",
    "currency": [
      "RUB"
    ],
    "languages": [
      "ru"
    ],
    "both": "Russia (Россия)"
  },
  {
    "name": "Portugal",
    "native": "Portugal",
    "phone": [
      351
    ],
    "continent": "EU",
    "capital": "Lisbon",
    "currency": [
      "EUR"
    ],
    "languages": [
      "pt"
    ],
    "both": "Portugal (Portugal)"
  },
  {
    "name": "Belarus",
    "native": "Белару́сь",
    "phone": [
      375
    ],
    "continent": "EU",
    "capital": "Minsk",
    "currency": [
      "BYN"
    ],
    "languages": [
      "be",
      "ru"
    ],
    "both": "Belarus (Белару́сь)"
  },
  {
    "name": "Kazakhstan",
    "native": "Қазақстан",
    "phone": [
      76,
      77
    ],
    "continent": "AS",
    "continents": [
      "AS",
      "EU"
    ],
    "capital": "Astana",
    "currency": [
      "KZT"
    ],
    "languages": [
      "kk",
      "ru"
    ],
    "both": "Kazakhstan (Қазақстан)"
  },
  {
    "name": "Germany",
    "native": "Deutschland",
    "phone": [
      49
    ],
    "continent": "EU",
    "capital": "Berlin",
    "currency": [
      "EUR"
    ],
    "languages": [
      "de"
    ],
    "both": "Germany (Deutschland)"
  },
  {
    "name": "Andorra",
    "native": "Andorra",
    "phone": [
      376
    ],
    "continent": "EU",
    "capital": "Andorra la Vella",
    "currency": [
      "EUR"
    ],
    "languages": [
      "ca"
    ],
    "both": "Andorra (Andorra)"
  },
  {
    "name": "United Arab Emirates",
    "native": "دولة الإمارات العربية المتحدة",
    "phone": [
      971
    ],
    "continent": "AS",
    "capital": "Abu Dhabi",
    "currency": [
      "AED"
    ],
    "languages": [
      "ar"
    ],
    "both": "United Arab Emirates (دولة الإمارات العربية المتحدة)"
  },
  {
    "name": "Afghanistan",
    "native": "افغانستان",
    "phone": [
      93
    ],
    "continent": "AS",
    "capital": "Kabul",
    "currency": [
      "AFN"
    ],
    "languages": [
      "ps",
      "uz",
      "tk"
    ],
    "both": "Afghanistan (افغانستان)"
  },
  {
    "name": "Antigua and Barbuda",
    "native": "Antigua and Barbuda",
    "phone": [
      1268
    ],
    "continent": "NA",
    "capital": "Saint John's",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Antigua and Barbuda (Antigua and Barbuda)"
  },
  {
    "name": "Abkhazia",
    "native": "Республика Абхазия",
    "phone": [
      7
    ],
    "continent": "NA",
    "capital": "Saint John's",
    "currency": [
      "RUB"
    ],
    "languages": [
      "ru"
    ],
    "both": "Abkhazia (Республика Абхазия)"
  },
  {
    "name": "Anguilla",
    "native": "Anguilla",
    "phone": [
      1264
    ],
    "continent": "NA",
    "capital": "The Valley",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Anguilla (Anguilla)"
  },
  {
    "name": "Albania",
    "native": "Shqipëria",
    "phone": [
      355
    ],
    "continent": "EU",
    "capital": "Tirana",
    "currency": [
      "ALL"
    ],
    "languages": [
      "sq"
    ],
    "both": "Albania (Shqipëria)"
  },
  {
    "name": "Armenia",
    "native": "Հայաստան",
    "phone": [
      374
    ],
    "continent": "AS",
    "capital": "Yerevan",
    "currency": [
      "AMD"
    ],
    "languages": [
      "hy",
      "ru"
    ],
    "both": "Armenia (Հայաստան)"
  },
  {
    "name": "Angola",
    "native": "Angola",
    "phone": [
      244
    ],
    "continent": "AF",
    "capital": "Luanda",
    "currency": [
      "AOA"
    ],
    "languages": [
      "pt"
    ],
    "both": "Angola (Angola)"
  },
  {
    "name": "Antarctica",
    "native": "Antarctica",
    "phone": [
      672
    ],
    "continent": "AN",
    "capital": "",
    "currency": [

    ],
    "languages": [

    ],
    "both": "Antarctica (Antarctica)"
  },
  {
    "name": "Argentina",
    "native": "Argentina",
    "phone": [
      54
    ],
    "continent": "SA",
    "capital": "Buenos Aires",
    "currency": [
      "ARS"
    ],
    "languages": [
      "es",
      "gn"
    ],
    "both": "Argentina (Argentina)"
  },
  {
    "name": "American Samoa",
    "native": "American Samoa",
    "phone": [
      1684
    ],
    "continent": "OC",
    "capital": "Pago Pago",
    "currency": [
      "USD"
    ],
    "languages": [
      "en",
      "sm"
    ],
    "both": "American Samoa (American Samoa)"
  },
  {
    "name": "Austria",
    "native": "Österreich",
    "phone": [
      43
    ],
    "continent": "EU",
    "capital": "Vienna",
    "currency": [
      "EUR"
    ],
    "languages": [
      "de"
    ],
    "both": "Austria (Österreich)"
  },
  {
    "name": "Australia",
    "native": "Australia",
    "phone": [
      61
    ],
    "continent": "OC",
    "capital": "Canberra",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Australia (Australia)"
  },
  {
    "name": "Aruba",
    "native": "Aruba",
    "phone": [
      297
    ],
    "continent": "NA",
    "capital": "Oranjestad",
    "currency": [
      "AWG"
    ],
    "languages": [
      "nl",
      "pa"
    ],
    "both": "Aruba (Aruba)"
  },
  {
    "name": "Åland",
    "native": "Åland",
    "phone": [
      358
    ],
    "continent": "EU",
    "capital": "Mariehamn",
    "currency": [
      "EUR"
    ],
    "languages": [
      "sv"
    ],
    "both": "Åland (Åland)"
  },
  {
    "name": "Azerbaijan",
    "native": "Azərbaycan",
    "phone": [
      994
    ],
    "continent": "AS",
    "continents": [
      "AS",
      "EU"
    ],
    "capital": "Baku",
    "currency": [
      "AZN"
    ],
    "languages": [
      "az"
    ],
    "both": "Azerbaijan (Azərbaycan)"
  },
  {
    "name": "Bosnia and Herzegovina",
    "native": "Bosna i Hercegovina",
    "phone": [
      387
    ],
    "continent": "EU",
    "capital": "Sarajevo",
    "currency": [
      "BAM"
    ],
    "languages": [
      "bs",
      "hr",
      "sr"
    ],
    "both": "Bosnia and Herzegovina (Bosna i Hercegovina)"
  },
  {
    "name": "Barbados",
    "native": "Barbados",
    "phone": [
      1246
    ],
    "continent": "NA",
    "capital": "Bridgetown",
    "currency": [
      "BBD"
    ],
    "languages": [
      "en"
    ],
    "both": "Barbados (Barbados)"
  },
  {
    "name": "Bangladesh",
    "native": "Bangladesh",
    "phone": [
      880
    ],
    "continent": "AS",
    "capital": "Dhaka",
    "currency": [
      "BDT"
    ],
    "languages": [
      "bn"
    ],
    "both": "Bangladesh (Bangladesh)"
  },
  {
    "name": "Belgium",
    "native": "België",
    "phone": [
      32
    ],
    "continent": "EU",
    "capital": "Brussels",
    "currency": [
      "EUR"
    ],
    "languages": [
      "nl",
      "fr",
      "de"
    ],
    "both": "Belgium (België)"
  },
  {
    "name": "Burkina Faso",
    "native": "Burkina Faso",
    "phone": [
      226
    ],
    "continent": "AF",
    "capital": "Ouagadougou",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr",
      "ff"
    ],
    "both": "Burkina Faso (Burkina Faso)"
  },
  {
    "name": "Bulgaria",
    "native": "България",
    "phone": [
      359
    ],
    "continent": "EU",
    "capital": "Sofia",
    "currency": [
      "BGN"
    ],
    "languages": [
      "bg"
    ],
    "both": "Bulgaria (България)"
  },
  {
    "name": "Bahrain",
    "native": "‏البحرين",
    "phone": [
      973
    ],
    "continent": "AS",
    "capital": "Manama",
    "currency": [
      "BHD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Bahrain (‏البحرين)"
  },
  {
    "name": "Burundi",
    "native": "Burundi",
    "phone": [
      257
    ],
    "continent": "AF",
    "capital": "Bujumbura",
    "currency": [
      "BIF"
    ],
    "languages": [
      "fr",
      "rn"
    ],
    "both": "Burundi (Burundi)"
  },
  {
    "name": "Benin",
    "native": "Bénin",
    "phone": [
      229
    ],
    "continent": "AF",
    "capital": "Porto-Novo",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Benin (Bénin)"
  },
  {
    "name": "Saint Barthélemy",
    "native": "Saint-Barthélemy",
    "phone": [
      590
    ],
    "continent": "NA",
    "capital": "Gustavia",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Saint Barthélemy (Saint-Barthélemy)"
  },
  {
    "name": "Bermuda",
    "native": "Bermuda",
    "phone": [
      1441
    ],
    "continent": "NA",
    "capital": "Hamilton",
    "currency": [
      "BMD"
    ],
    "languages": [
      "en"
    ],
    "both": "Bermuda (Bermuda)"
  },
  {
    "name": "Brunei",
    "native": "Negara Brunei Darussalam",
    "phone": [
      673
    ],
    "continent": "AS",
    "capital": "Bandar Seri Begawan",
    "currency": [
      "BND"
    ],
    "languages": [
      "ms"
    ],
    "both": "Brunei (Negara Brunei Darussalam)"
  },
  {
    "name": "Bolivia",
    "native": "Bolivia",
    "phone": [
      591
    ],
    "continent": "SA",
    "capital": "Sucre",
    "currency": [
      "BOB",
      "BOV"
    ],
    "languages": [
      "es",
      "ay",
      "qu"
    ],
    "both": "Bolivia (Bolivia)"
  },
  {
    "name": "Bonaire",
    "native": "Bonaire",
    "phone": [
      5997
    ],
    "continent": "NA",
    "capital": "Kralendijk",
    "currency": [
      "USD"
    ],
    "languages": [
      "nl"
    ],
    "both": "Bonaire (Bonaire)"
  },
  {
    "name": "Brazil",
    "native": "Brasil",
    "phone": [
      55
    ],
    "continent": "SA",
    "capital": "Brasília",
    "currency": [
      "BRL"
    ],
    "languages": [
      "pt"
    ],
    "both": "Brazil (Brasil)"
  },
  {
    "name": "Bahamas",
    "native": "Bahamas",
    "phone": [
      1242
    ],
    "continent": "NA",
    "capital": "Nassau",
    "currency": [
      "BSD"
    ],
    "languages": [
      "en"
    ],
    "both": "Bahamas (Bahamas)"
  },
  {
    "name": "Bhutan",
    "native": "ʼbrug-yul",
    "phone": [
      975
    ],
    "continent": "AS",
    "capital": "Thimphu",
    "currency": [
      "BTN",
      "INR"
    ],
    "languages": [
      "dz"
    ],
    "both": "Bhutan (ʼbrug-yul)"
  },
  {
    "name": "Bouvet Island",
    "native": "Bouvetøya",
    "phone": [
      47
    ],
    "continent": "AN",
    "capital": "",
    "currency": [
      "NOK"
    ],
    "languages": [
      "no",
      "nb",
      "nn"
    ],
    "both": "Bouvet Island (Bouvetøya)"
  },
  {
    "name": "Botswana",
    "native": "Botswana",
    "phone": [
      267
    ],
    "continent": "AF",
    "capital": "Gaborone",
    "currency": [
      "BWP"
    ],
    "languages": [
      "en",
      "tn"
    ],
    "both": "Botswana (Botswana)"
  },
  {
    "name": "Belize",
    "native": "Belize",
    "phone": [
      501
    ],
    "continent": "NA",
    "capital": "Belmopan",
    "currency": [
      "BZD"
    ],
    "languages": [
      "en",
      "es"
    ],
    "both": "Belize (Belize)"
  },
  {
    "name": "Canada",
    "native": "Canada",
    "phone": [
      1
    ],
    "continent": "NA",
    "capital": "Ottawa",
    "currency": [
      "CAD"
    ],
    "languages": [
      "en",
      "fr"
    ],
    "both": "Canada (Canada)"
  },
  {
    "name": "Cocos [Keeling] Islands",
    "native": "Cocos (Keeling) Islands",
    "phone": [
      61
    ],
    "continent": "AS",
    "capital": "West Island",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Cocos [Keeling] Islands (Cocos (Keeling) Islands)"
  },
  {
    "name": "Democratic Republic of the Congo",
    "native": "République démocratique du Congo",
    "phone": [
      243
    ],
    "continent": "AF",
    "capital": "Kinshasa",
    "currency": [
      "CDF"
    ],
    "languages": [
      "fr",
      "ln",
      "kg",
      "sw",
      "lu"
    ],
    "both": "Democratic Republic of the Congo (République démocratique du Congo)"
  },
  {
    "name": "Central African Republic",
    "native": "Ködörösêse tî Bêafrîka",
    "phone": [
      236
    ],
    "continent": "AF",
    "capital": "Bangui",
    "currency": [
      "XAF"
    ],
    "languages": [
      "fr",
      "sg"
    ],
    "both": "Central African Republic (Ködörösêse tî Bêafrîka)"
  },
  {
    "name": "Republic of the Congo",
    "native": "République du Congo",
    "phone": [
      242
    ],
    "continent": "AF",
    "capital": "Brazzaville",
    "currency": [
      "XAF"
    ],
    "languages": [
      "fr",
      "ln"
    ],
    "both": "Republic of the Congo (République du Congo)"
  },
  {
    "name": "Switzerland",
    "native": "Schweiz",
    "phone": [
      41
    ],
    "continent": "EU",
    "capital": "Bern",
    "currency": [
      "CHE",
      "CHF",
      "CHW"
    ],
    "languages": [
      "de",
      "fr",
      "it"
    ],
    "both": "Switzerland (Schweiz)"
  },
  {
    "name": "Ivory Coast",
    "native": "Côte d'Ivoire",
    "phone": [
      225
    ],
    "continent": "AF",
    "capital": "Yamoussoukro",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Ivory Coast (Côte d'Ivoire)"
  },
  {
    "name": "Cook Islands",
    "native": "Cook Islands",
    "phone": [
      682
    ],
    "continent": "OC",
    "capital": "Avarua",
    "currency": [
      "NZD"
    ],
    "languages": [
      "en"
    ],
    "both": "Cook Islands (Cook Islands)"
  },
  {
    "name": "Chile",
    "native": "Chile",
    "phone": [
      56
    ],
    "continent": "SA",
    "capital": "Santiago",
    "currency": [
      "CLF",
      "CLP"
    ],
    "languages": [
      "es"
    ],
    "both": "Chile (Chile)"
  },
  {
    "name": "Cameroon",
    "native": "Cameroon",
    "phone": [
      237
    ],
    "continent": "AF",
    "capital": "Yaoundé",
    "currency": [
      "XAF"
    ],
    "languages": [
      "en",
      "fr"
    ],
    "both": "Cameroon (Cameroon)"
  },
  {
    "name": "China",
    "native": "中国",
    "phone": [
      86
    ],
    "continent": "AS",
    "capital": "Beijing",
    "currency": [
      "CNY"
    ],
    "languages": [
      "zh"
    ],
    "both": "China (中国)"
  },
  {
    "name": "Colombia",
    "native": "Colombia",
    "phone": [
      57
    ],
    "continent": "SA",
    "capital": "Bogotá",
    "currency": [
      "COP"
    ],
    "languages": [
      "es"
    ],
    "both": "Colombia (Colombia)"
  },
  {
    "name": "Costa Rica",
    "native": "Costa Rica",
    "phone": [
      506
    ],
    "continent": "NA",
    "capital": "San José",
    "currency": [
      "CRC"
    ],
    "languages": [
      "es"
    ],
    "both": "Costa Rica (Costa Rica)"
  },
  {
    "name": "Cuba",
    "native": "Cuba",
    "phone": [
      53
    ],
    "continent": "NA",
    "capital": "Havana",
    "currency": [
      "CUC",
      "CUP"
    ],
    "languages": [
      "es"
    ],
    "both": "Cuba (Cuba)"
  },
  {
    "name": "Cape Verde",
    "native": "Cabo Verde",
    "phone": [
      238
    ],
    "continent": "AF",
    "capital": "Praia",
    "currency": [
      "CVE"
    ],
    "languages": [
      "pt"
    ],
    "both": "Cape Verde (Cabo Verde)"
  },
  {
    "name": "Curacao",
    "native": "Curaçao",
    "phone": [
      5999
    ],
    "continent": "NA",
    "capital": "Willemstad",
    "currency": [
      "ANG"
    ],
    "languages": [
      "nl",
      "pa",
      "en"
    ],
    "both": "Curacao (Curaçao)"
  },
  {
    "name": "Christmas Island",
    "native": "Christmas Island",
    "phone": [
      61
    ],
    "continent": "AS",
    "capital": "Flying Fish Cove",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Christmas Island (Christmas Island)"
  },
  {
    "name": "Cyprus",
    "native": "Κύπρος",
    "phone": [
      357
    ],
    "continent": "EU",
    "capital": "Nicosia",
    "currency": [
      "EUR"
    ],
    "languages": [
      "el",
      "tr",
      "hy"
    ],
    "both": "Cyprus (Κύπρος)"
  },
  {
    "name": "Czech Republic",
    "native": "Česká republika",
    "phone": [
      420
    ],
    "continent": "EU",
    "capital": "Prague",
    "currency": [
      "CZK"
    ],
    "languages": [
      "cs",
      "sk"
    ],
    "both": "Czech Republic (Česká republika)"
  },
  {
    "name": "Djibouti",
    "native": "Djibouti",
    "phone": [
      253
    ],
    "continent": "AF",
    "capital": "Djibouti",
    "currency": [
      "DJF"
    ],
    "languages": [
      "fr",
      "ar"
    ],
    "both": "Djibouti (Djibouti)"
  },
  {
    "name": "Denmark",
    "native": "Danmark",
    "phone": [
      45
    ],
    "continent": "EU",
    "continents": [
      "EU",
      "NA"
    ],
    "capital": "Copenhagen",
    "currency": [
      "DKK"
    ],
    "languages": [
      "da"
    ],
    "both": "Denmark (Danmark)"
  },
  {
    "name": "Dominica",
    "native": "Dominica",
    "phone": [
      1767
    ],
    "continent": "NA",
    "capital": "Roseau",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Dominica (Dominica)"
  },
  {
    "name": "Dominican Republic",
    "native": "República Dominicana",
    "phone": [
      1809,
      1829,
      1849
    ],
    "continent": "NA",
    "capital": "Santo Domingo",
    "currency": [
      "DOP"
    ],
    "languages": [
      "es"
    ],
    "both": "Dominican Republic (República Dominicana)"
  },
  {
    "name": "Algeria",
    "native": "الجزائر",
    "phone": [
      213
    ],
    "continent": "AF",
    "capital": "Algiers",
    "currency": [
      "DZD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Algeria (الجزائر)"
  },
  {
    "name": "Ecuador",
    "native": "Ecuador",
    "phone": [
      593
    ],
    "continent": "SA",
    "capital": "Quito",
    "currency": [
      "USD"
    ],
    "languages": [
      "es"
    ],
    "both": "Ecuador (Ecuador)"
  },
  {
    "name": "Estonia",
    "native": "Eesti",
    "phone": [
      372
    ],
    "continent": "EU",
    "capital": "Tallinn",
    "currency": [
      "EUR"
    ],
    "languages": [
      "et"
    ],
    "both": "Estonia (Eesti)"
  },
  {
    "name": "Egypt",
    "native": "مصر‎",
    "phone": [
      20
    ],
    "continent": "AF",
    "continents": [
      "AF",
      "AS"
    ],
    "capital": "Cairo",
    "currency": [
      "EGP"
    ],
    "languages": [
      "ar"
    ],
    "both": "Egypt (مصر‎)"
  },
  {
    "name": "Western Sahara",
    "native": "الصحراء الغربية",
    "phone": [
      212
    ],
    "continent": "AF",
    "capital": "El Aaiún",
    "currency": [
      "MAD",
      "DZD",
      "MRU"
    ],
    "languages": [
      "es"
    ],
    "both": "Western Sahara (الصحراء الغربية)"
  },
  {
    "name": "Eritrea",
    "native": "ኤርትራ",
    "phone": [
      291
    ],
    "continent": "AF",
    "capital": "Asmara",
    "currency": [
      "ERN"
    ],
    "languages": [
      "ti",
      "ar",
      "en"
    ],
    "both": "Eritrea (ኤርትራ)"
  },
  {
    "name": "Spain",
    "native": "España",
    "phone": [
      34
    ],
    "continent": "EU",
    "capital": "Madrid",
    "currency": [
      "EUR"
    ],
    "languages": [
      "es",
      "eu",
      "ca",
      "gl",
      "oc"
    ],
    "both": "Spain (España)"
  },
  {
    "name": "Ethiopia",
    "native": "ኢትዮጵያ",
    "phone": [
      251
    ],
    "continent": "AF",
    "capital": "Addis Ababa",
    "currency": [
      "ETB"
    ],
    "languages": [
      "am"
    ],
    "both": "Ethiopia (ኢትዮጵያ)"
  },
  {
    "name": "Finland",
    "native": "Suomi",
    "phone": [
      358
    ],
    "continent": "EU",
    "capital": "Helsinki",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fi",
      "sv"
    ],
    "both": "Finland (Suomi)"
  },
  {
    "name": "Fiji",
    "native": "Fiji",
    "phone": [
      679
    ],
    "continent": "OC",
    "capital": "Suva",
    "currency": [
      "FJD"
    ],
    "languages": [
      "en",
      "fj",
      "hi",
      "ur"
    ],
    "both": "Fiji (Fiji)"
  },
  {
    "name": "Falkland Islands",
    "native": "Falkland Islands",
    "phone": [
      500
    ],
    "continent": "SA",
    "capital": "Stanley",
    "currency": [
      "FKP"
    ],
    "languages": [
      "en"
    ],
    "both": "Falkland Islands (Falkland Islands)"
  },
  {
    "name": "Micronesia",
    "native": "Micronesia",
    "phone": [
      691
    ],
    "continent": "OC",
    "capital": "Palikir",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "Micronesia (Micronesia)"
  },
  {
    "name": "Faroe Islands",
    "native": "Føroyar",
    "phone": [
      298
    ],
    "continent": "EU",
    "capital": "Tórshavn",
    "currency": [
      "DKK"
    ],
    "languages": [
      "fo"
    ],
    "both": "Faroe Islands (Føroyar)"
  },
  {
    "name": "France",
    "native": "France",
    "phone": [
      33
    ],
    "continent": "EU",
    "capital": "Paris",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "France (France)"
  },
  {
    "name": "Gabon",
    "native": "Gabon",
    "phone": [
      241
    ],
    "continent": "AF",
    "capital": "Libreville",
    "currency": [
      "XAF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Gabon (Gabon)"
  },
  {
    "name": "United Kingdom",
    "native": "United Kingdom",
    "phone": [
      44
    ],
    "continent": "EU",
    "capital": "London",
    "currency": [
      "GBP"
    ],
    "languages": [
      "en"
    ],
    "both": "United Kingdom (United Kingdom)"
  },
  {
    "name": "Grenada",
    "native": "Grenada",
    "phone": [
      1473
    ],
    "continent": "NA",
    "capital": "St. George's",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Grenada (Grenada)"
  },
  {
    "name": "Georgia",
    "native": "საქართველო",
    "phone": [
      995
    ],
    "continent": "AS",
    "capital": "Tbilisi",
    "currency": [
      "GEL"
    ],
    "languages": [
      "ka"
    ],
    "both": "Georgia (საქართველო)"
  },
  {
    "name": "French Guiana",
    "native": "Guyane française",
    "phone": [
      594
    ],
    "continent": "SA",
    "capital": "Cayenne",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "French Guiana (Guyane française)"
  },
  {
    "name": "Guernsey",
    "native": "Guernsey",
    "phone": [
      44
    ],
    "continent": "EU",
    "capital": "St. Peter Port",
    "currency": [
      "GBP"
    ],
    "languages": [
      "en",
      "fr"
    ],
    "both": "Guernsey (Guernsey)"
  },
  {
    "name": "Ghana",
    "native": "Ghana",
    "phone": [
      233
    ],
    "continent": "AF",
    "capital": "Accra",
    "currency": [
      "GHS"
    ],
    "languages": [
      "en"
    ],
    "both": "Ghana (Ghana)"
  },
  {
    "name": "Gibraltar",
    "native": "Gibraltar",
    "phone": [
      350
    ],
    "continent": "EU",
    "capital": "Gibraltar",
    "currency": [
      "GIP"
    ],
    "languages": [
      "en"
    ],
    "both": "Gibraltar (Gibraltar)"
  },
  {
    "name": "Greenland",
    "native": "Kalaallit Nunaat",
    "phone": [
      299
    ],
    "continent": "NA",
    "capital": "Nuuk",
    "currency": [
      "DKK"
    ],
    "languages": [
      "kl"
    ],
    "both": "Greenland (Kalaallit Nunaat)"
  },
  {
    "name": "Gambia",
    "native": "Gambia",
    "phone": [
      220
    ],
    "continent": "AF",
    "capital": "Banjul",
    "currency": [
      "GMD"
    ],
    "languages": [
      "en"
    ],
    "both": "Gambia (Gambia)"
  },
  {
    "name": "Guinea",
    "native": "Guinée",
    "phone": [
      224
    ],
    "continent": "AF",
    "capital": "Conakry",
    "currency": [
      "GNF"
    ],
    "languages": [
      "fr",
      "ff"
    ],
    "both": "Guinea (Guinée)"
  },
  {
    "name": "Guadeloupe",
    "native": "Guadeloupe",
    "phone": [
      590
    ],
    "continent": "NA",
    "capital": "Basse-Terre",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Guadeloupe (Guadeloupe)"
  },
  {
    "name": "Equatorial Guinea",
    "native": "Guinea Ecuatorial",
    "phone": [
      240
    ],
    "continent": "AF",
    "capital": "Malabo",
    "currency": [
      "XAF"
    ],
    "languages": [
      "es",
      "fr"
    ],
    "both": "Equatorial Guinea (Guinea Ecuatorial)"
  },
  {
    "name": "Greece",
    "native": "Ελλάδα",
    "phone": [
      30
    ],
    "continent": "EU",
    "capital": "Athens",
    "currency": [
      "EUR"
    ],
    "languages": [
      "el"
    ],
    "both": "Greece (Ελλάδα)"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "native": "South Georgia",
    "phone": [
      500
    ],
    "continent": "AN",
    "capital": "King Edward Point",
    "currency": [
      "GBP"
    ],
    "languages": [
      "en"
    ],
    "both": "South Georgia and the South Sandwich Islands (South Georgia)"
  },
  {
    "name": "Guatemala",
    "native": "Guatemala",
    "phone": [
      502
    ],
    "continent": "NA",
    "capital": "Guatemala City",
    "currency": [
      "GTQ"
    ],
    "languages": [
      "es"
    ],
    "both": "Guatemala (Guatemala)"
  },
  {
    "name": "Guam",
    "native": "Guam",
    "phone": [
      1671
    ],
    "continent": "OC",
    "capital": "Hagåtña",
    "currency": [
      "USD"
    ],
    "languages": [
      "en",
      "ch",
      "es"
    ],
    "both": "Guam (Guam)"
  },
  {
    "name": "Guinea-Bissau",
    "native": "Guiné-Bissau",
    "phone": [
      245
    ],
    "continent": "AF",
    "capital": "Bissau",
    "currency": [
      "XOF"
    ],
    "languages": [
      "pt"
    ],
    "both": "Guinea-Bissau (Guiné-Bissau)"
  },
  {
    "name": "Guyana",
    "native": "Guyana",
    "phone": [
      592
    ],
    "continent": "SA",
    "capital": "Georgetown",
    "currency": [
      "GYD"
    ],
    "languages": [
      "en"
    ],
    "both": "Guyana (Guyana)"
  },
  {
    "name": "Hong Kong",
    "native": "香港",
    "phone": [
      852
    ],
    "continent": "AS",
    "capital": "City of Victoria",
    "currency": [
      "HKD"
    ],
    "languages": [
      "zh",
      "en"
    ],
    "both": "Hong Kong (香港)"
  },
  {
    "name": "Heard Island and McDonald Islands",
    "native": "Heard Island and McDonald Islands",
    "phone": [
      61
    ],
    "continent": "AN",
    "capital": "",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Heard Island and McDonald Islands (Heard Island and McDonald Islands)"
  },
  {
    "name": "Honduras",
    "native": "Honduras",
    "phone": [
      504
    ],
    "continent": "NA",
    "capital": "Tegucigalpa",
    "currency": [
      "HNL"
    ],
    "languages": [
      "es"
    ],
    "both": "Honduras (Honduras)"
  },
  {
    "name": "Croatia",
    "native": "Hrvatska",
    "phone": [
      385
    ],
    "continent": "EU",
    "capital": "Zagreb",
    "currency": [
      "HRK"
    ],
    "languages": [
      "hr"
    ],
    "both": "Croatia (Hrvatska)"
  },
  {
    "name": "Haiti",
    "native": "Haïti",
    "phone": [
      509
    ],
    "continent": "NA",
    "capital": "Port-au-Prince",
    "currency": [
      "HTG",
      "USD"
    ],
    "languages": [
      "fr",
      "ht"
    ],
    "both": "Haiti (Haïti)"
  },
  {
    "name": "Hungary",
    "native": "Magyarország",
    "phone": [
      36
    ],
    "continent": "EU",
    "capital": "Budapest",
    "currency": [
      "HUF"
    ],
    "languages": [
      "hu"
    ],
    "both": "Hungary (Magyarország)"
  },
  {
    "name": "Indonesia",
    "native": "Indonesia",
    "phone": [
      62
    ],
    "continent": "AS",
    "capital": "Jakarta",
    "currency": [
      "IDR"
    ],
    "languages": [
      "id"
    ],
    "both": "Indonesia (Indonesia)"
  },
  {
    "name": "Ireland",
    "native": "Éire",
    "phone": [
      353
    ],
    "continent": "EU",
    "capital": "Dublin",
    "currency": [
      "EUR"
    ],
    "languages": [
      "ga",
      "en"
    ],
    "both": "Ireland (Éire)"
  },
  {
    "name": "Israel",
    "native": "יִשְׂרָאֵל",
    "phone": [
      972
    ],
    "continent": "AS",
    "capital": "Jerusalem",
    "currency": [
      "ILS"
    ],
    "languages": [
      "he",
      "ar"
    ],
    "both": "Israel (יִשְׂרָאֵל)"
  },
  {
    "name": "Isle of Man",
    "native": "Isle of Man",
    "phone": [
      44
    ],
    "continent": "EU",
    "capital": "Douglas",
    "currency": [
      "GBP"
    ],
    "languages": [
      "en",
      "gv"
    ],
    "both": "Isle of Man (Isle of Man)"
  },
  {
    "name": "India",
    "native": "भारत",
    "phone": [
      91
    ],
    "continent": "AS",
    "capital": "New Delhi",
    "currency": [
      "INR"
    ],
    "languages": [
      "hi",
      "en"
    ],
    "both": "India (भारत)"
  },
  {
    "name": "British Indian Ocean Territory",
    "native": "British Indian Ocean Territory",
    "phone": [
      246
    ],
    "continent": "AS",
    "capital": "Diego Garcia",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "British Indian Ocean Territory (British Indian Ocean Territory)"
  },
  {
    "name": "Iraq",
    "native": "العراق",
    "phone": [
      964
    ],
    "continent": "AS",
    "capital": "Baghdad",
    "currency": [
      "IQD"
    ],
    "languages": [
      "ar",
      "ku"
    ],
    "both": "Iraq (العراق)"
  },
  {
    "name": "Iran",
    "native": "ایران",
    "phone": [
      98
    ],
    "continent": "AS",
    "capital": "Tehran",
    "currency": [
      "IRR"
    ],
    "languages": [
      "fa"
    ],
    "both": "Iran (ایران)"
  },
  {
    "name": "Iceland",
    "native": "Ísland",
    "phone": [
      354
    ],
    "continent": "EU",
    "capital": "Reykjavik",
    "currency": [
      "ISK"
    ],
    "languages": [
      "is"
    ],
    "both": "Iceland (Ísland)"
  },
  {
    "name": "Italy",
    "native": "Italia",
    "phone": [
      39
    ],
    "continent": "EU",
    "capital": "Rome",
    "currency": [
      "EUR"
    ],
    "languages": [
      "it"
    ],
    "both": "Italy (Italia)"
  },
  {
    "name": "Jersey",
    "native": "Jersey",
    "phone": [
      44
    ],
    "continent": "EU",
    "capital": "Saint Helier",
    "currency": [
      "GBP"
    ],
    "languages": [
      "en",
      "fr"
    ],
    "both": "Jersey (Jersey)"
  },
  {
    "name": "Jamaica",
    "native": "Jamaica",
    "phone": [
      1876
    ],
    "continent": "NA",
    "capital": "Kingston",
    "currency": [
      "JMD"
    ],
    "languages": [
      "en"
    ],
    "both": "Jamaica (Jamaica)"
  },
  {
    "name": "Jordan",
    "native": "الأردن",
    "phone": [
      962
    ],
    "continent": "AS",
    "capital": "Amman",
    "currency": [
      "JOD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Jordan (الأردن)"
  },
  {
    "name": "Japan",
    "native": "日本",
    "phone": [
      81
    ],
    "continent": "AS",
    "capital": "Tokyo",
    "currency": [
      "JPY"
    ],
    "languages": [
      "ja"
    ],
    "both": "Japan (日本)"
  },
  {
    "name": "Kenya",
    "native": "Kenya",
    "phone": [
      254
    ],
    "continent": "AF",
    "capital": "Nairobi",
    "currency": [
      "KES"
    ],
    "languages": [
      "en",
      "sw"
    ],
    "both": "Kenya (Kenya)"
  },
  {
    "name": "Kyrgyzstan",
    "native": "Кыргызстан",
    "phone": [
      996
    ],
    "continent": "AS",
    "capital": "Bishkek",
    "currency": [
      "KGS"
    ],
    "languages": [
      "ky",
      "ru"
    ],
    "both": "Kyrgyzstan (Кыргызстан)"
  },
  {
    "name": "Cambodia",
    "native": "Kâmpŭchéa",
    "phone": [
      855
    ],
    "continent": "AS",
    "capital": "Phnom Penh",
    "currency": [
      "KHR"
    ],
    "languages": [
      "km"
    ],
    "both": "Cambodia (Kâmpŭchéa)"
  },
  {
    "name": "Kiribati",
    "native": "Kiribati",
    "phone": [
      686
    ],
    "continent": "OC",
    "capital": "South Tarawa",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Kiribati (Kiribati)"
  },
  {
    "name": "Comoros",
    "native": "Komori",
    "phone": [
      269
    ],
    "continent": "AF",
    "capital": "Moroni",
    "currency": [
      "KMF"
    ],
    "languages": [
      "ar",
      "fr"
    ],
    "both": "Comoros (Komori)"
  },
  {
    "name": "Saint Kitts and Nevis",
    "native": "Saint Kitts and Nevis",
    "phone": [
      1869
    ],
    "continent": "NA",
    "capital": "Basseterre",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Saint Kitts and Nevis (Saint Kitts and Nevis)"
  },
  {
    "name": "North Korea",
    "native": "북한",
    "phone": [
      850
    ],
    "continent": "AS",
    "capital": "Pyongyang",
    "currency": [
      "KPW"
    ],
    "languages": [
      "ko"
    ],
    "both": "North Korea (북한)"
  },
  {
    "name": "South Korea",
    "native": "대한민국",
    "phone": [
      82
    ],
    "continent": "AS",
    "capital": "Seoul",
    "currency": [
      "KRW"
    ],
    "languages": [
      "ko"
    ],
    "both": "South Korea (대한민국)"
  },
  {
    "name": "Kuwait",
    "native": "الكويت",
    "phone": [
      965
    ],
    "continent": "AS",
    "capital": "Kuwait City",
    "currency": [
      "KWD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Kuwait (الكويت)"
  },
  {
    "name": "Cayman Islands",
    "native": "Cayman Islands",
    "phone": [
      1345
    ],
    "continent": "NA",
    "capital": "George Town",
    "currency": [
      "KYD"
    ],
    "languages": [
      "en"
    ],
    "both": "Cayman Islands (Cayman Islands)"
  },
  {
    "name": "Laos",
    "native": "ສປປລາວ",
    "phone": [
      856
    ],
    "continent": "AS",
    "capital": "Vientiane",
    "currency": [
      "LAK"
    ],
    "languages": [
      "lo"
    ],
    "both": "Laos (ສປປລາວ)"
  },
  {
    "name": "Lebanon",
    "native": "لبنان",
    "phone": [
      961
    ],
    "continent": "AS",
    "capital": "Beirut",
    "currency": [
      "LBP"
    ],
    "languages": [
      "ar",
      "fr"
    ],
    "both": "Lebanon (لبنان)"
  },
  {
    "name": "Saint Lucia",
    "native": "Saint Lucia",
    "phone": [
      1758
    ],
    "continent": "NA",
    "capital": "Castries",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Saint Lucia (Saint Lucia)"
  },
  {
    "name": "Liechtenstein",
    "native": "Liechtenstein",
    "phone": [
      423
    ],
    "continent": "EU",
    "capital": "Vaduz",
    "currency": [
      "CHF"
    ],
    "languages": [
      "de"
    ],
    "both": "Liechtenstein (Liechtenstein)"
  },
  {
    "name": "Sri Lanka",
    "native": "śrī laṃkāva",
    "phone": [
      94
    ],
    "continent": "AS",
    "capital": "Colombo",
    "currency": [
      "LKR"
    ],
    "languages": [
      "si",
      "ta"
    ],
    "both": "Sri Lanka (śrī laṃkāva)"
  },
  {
    "name": "Liberia",
    "native": "Liberia",
    "phone": [
      231
    ],
    "continent": "AF",
    "capital": "Monrovia",
    "currency": [
      "LRD"
    ],
    "languages": [
      "en"
    ],
    "both": "Liberia (Liberia)"
  },
  {
    "name": "Lesotho",
    "native": "Lesotho",
    "phone": [
      266
    ],
    "continent": "AF",
    "capital": "Maseru",
    "currency": [
      "LSL",
      "ZAR"
    ],
    "languages": [
      "en",
      "st"
    ],
    "both": "Lesotho (Lesotho)"
  },
  {
    "name": "Lithuania",
    "native": "Lietuva",
    "phone": [
      370
    ],
    "continent": "EU",
    "capital": "Vilnius",
    "currency": [
      "EUR"
    ],
    "languages": [
      "lt"
    ],
    "both": "Lithuania (Lietuva)"
  },
  {
    "name": "Luxembourg",
    "native": "Luxembourg",
    "phone": [
      352
    ],
    "continent": "EU",
    "capital": "Luxembourg",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr",
      "de",
      "lb"
    ],
    "both": "Luxembourg (Luxembourg)"
  },
  {
    "name": "Latvia",
    "native": "Latvija",
    "phone": [
      371
    ],
    "continent": "EU",
    "capital": "Riga",
    "currency": [
      "EUR"
    ],
    "languages": [
      "lv"
    ],
    "both": "Latvia (Latvija)"
  },
  {
    "name": "Libya",
    "native": "‏ليبيا",
    "phone": [
      218
    ],
    "continent": "AF",
    "capital": "Tripoli",
    "currency": [
      "LYD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Libya (‏ليبيا)"
  },
  {
    "name": "Morocco",
    "native": "المغرب",
    "phone": [
      212
    ],
    "continent": "AF",
    "capital": "Rabat",
    "currency": [
      "MAD"
    ],
    "languages": [
      "ar"
    ],
    "both": "Morocco (المغرب)"
  },
  {
    "name": "Monaco",
    "native": "Monaco",
    "phone": [
      377
    ],
    "continent": "EU",
    "capital": "Monaco",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Monaco (Monaco)"
  },
  {
    "name": "Moldova",
    "native": "Moldova",
    "phone": [
      373
    ],
    "continent": "EU",
    "capital": "Chișinău",
    "currency": [
      "MDL"
    ],
    "languages": [
      "ro"
    ],
    "both": "Moldova (Moldova)"
  },
  {
    "name": "Montenegro",
    "native": "Црна Гора",
    "phone": [
      382
    ],
    "continent": "EU",
    "capital": "Podgorica",
    "currency": [
      "EUR"
    ],
    "languages": [
      "sr",
      "bs",
      "sq",
      "hr"
    ],
    "both": "Montenegro (Црна Гора)"
  },
  {
    "name": "Saint Martin",
    "native": "Saint-Martin",
    "phone": [
      590
    ],
    "continent": "NA",
    "capital": "Marigot",
    "currency": [
      "EUR"
    ],
    "languages": [
      "en",
      "fr",
      "nl"
    ],
    "both": "Saint Martin (Saint-Martin)"
  },
  {
    "name": "Madagascar",
    "native": "Madagasikara",
    "phone": [
      261
    ],
    "continent": "AF",
    "capital": "Antananarivo",
    "currency": [
      "MGA"
    ],
    "languages": [
      "fr",
      "mg"
    ],
    "both": "Madagascar (Madagasikara)"
  },
  {
    "name": "Marshall Islands",
    "native": "M̧ajeļ",
    "phone": [
      692
    ],
    "continent": "OC",
    "capital": "Majuro",
    "currency": [
      "USD"
    ],
    "languages": [
      "en",
      "mh"
    ],
    "both": "Marshall Islands (M̧ajeļ)"
  },
  {
    "name": "North Macedonia",
    "native": "Северна Македонија",
    "phone": [
      389
    ],
    "continent": "EU",
    "capital": "Skopje",
    "currency": [
      "MKD"
    ],
    "languages": [
      "mk"
    ],
    "both": "North Macedonia (Северна Македонија)"
  },
  {
    "name": "Mali",
    "native": "Mali",
    "phone": [
      223
    ],
    "continent": "AF",
    "capital": "Bamako",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Mali (Mali)"
  },
  {
    "name": "Myanmar [Burma]",
    "native": "မြန်မာ",
    "phone": [
      95
    ],
    "continent": "AS",
    "capital": "Naypyidaw",
    "currency": [
      "MMK"
    ],
    "languages": [
      "my"
    ],
    "both": "Myanmar [Burma] (မြန်မာ)"
  },
  {
    "name": "Mongolia",
    "native": "Монгол улс",
    "phone": [
      976
    ],
    "continent": "AS",
    "capital": "Ulan Bator",
    "currency": [
      "MNT"
    ],
    "languages": [
      "mn"
    ],
    "both": "Mongolia (Монгол улс)"
  },
  {
    "name": "Macao",
    "native": "澳門",
    "phone": [
      853
    ],
    "continent": "AS",
    "capital": "",
    "currency": [
      "MOP"
    ],
    "languages": [
      "zh",
      "pt"
    ],
    "both": "Macao (澳門)"
  },
  {
    "name": "Northern Mariana Islands",
    "native": "Northern Mariana Islands",
    "phone": [
      1670
    ],
    "continent": "OC",
    "capital": "Saipan",
    "currency": [
      "USD"
    ],
    "languages": [
      "en",
      "ch"
    ],
    "both": "Northern Mariana Islands (Northern Mariana Islands)"
  },
  {
    "name": "Martinique",
    "native": "Martinique",
    "phone": [
      596
    ],
    "continent": "NA",
    "capital": "Fort-de-France",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Martinique (Martinique)"
  },
  {
    "name": "Mauritania",
    "native": "موريتانيا",
    "phone": [
      222
    ],
    "continent": "AF",
    "capital": "Nouakchott",
    "currency": [
      "MRU"
    ],
    "languages": [
      "ar"
    ],
    "both": "Mauritania (موريتانيا)"
  },
  {
    "name": "Montserrat",
    "native": "Montserrat",
    "phone": [
      1664
    ],
    "continent": "NA",
    "capital": "Plymouth",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Montserrat (Montserrat)"
  },
  {
    "name": "Malta",
    "native": "Malta",
    "phone": [
      356
    ],
    "continent": "EU",
    "capital": "Valletta",
    "currency": [
      "EUR"
    ],
    "languages": [
      "mt",
      "en"
    ],
    "both": "Malta (Malta)"
  },
  {
    "name": "Mauritius",
    "native": "Maurice",
    "phone": [
      230
    ],
    "continent": "AF",
    "capital": "Port Louis",
    "currency": [
      "MUR"
    ],
    "languages": [
      "en"
    ],
    "both": "Mauritius (Maurice)"
  },
  {
    "name": "Maldives",
    "native": "Maldives",
    "phone": [
      960
    ],
    "continent": "AS",
    "capital": "Malé",
    "currency": [
      "MVR"
    ],
    "languages": [
      "dv"
    ],
    "both": "Maldives (Maldives)"
  },
  {
    "name": "Malawi",
    "native": "Malawi",
    "phone": [
      265
    ],
    "continent": "AF",
    "capital": "Lilongwe",
    "currency": [
      "MWK"
    ],
    "languages": [
      "en",
      "ny"
    ],
    "both": "Malawi (Malawi)"
  },
  {
    "name": "Mexico",
    "native": "México",
    "phone": [
      52
    ],
    "continent": "NA",
    "capital": "Mexico City",
    "currency": [
      "MXN"
    ],
    "languages": [
      "es"
    ],
    "both": "Mexico (México)"
  },
  {
    "name": "Malaysia",
    "native": "Malaysia",
    "phone": [
      60
    ],
    "continent": "AS",
    "capital": "Kuala Lumpur",
    "currency": [
      "MYR"
    ],
    "languages": [
      "ms"
    ],
    "both": "Malaysia (Malaysia)"
  },
  {
    "name": "Mozambique",
    "native": "Moçambique",
    "phone": [
      258
    ],
    "continent": "AF",
    "capital": "Maputo",
    "currency": [
      "MZN"
    ],
    "languages": [
      "pt"
    ],
    "both": "Mozambique (Moçambique)"
  },
  {
    "name": "Namibia",
    "native": "Namibia",
    "phone": [
      264
    ],
    "continent": "AF",
    "capital": "Windhoek",
    "currency": [
      "NAD",
      "ZAR"
    ],
    "languages": [
      "en",
      "af"
    ],
    "both": "Namibia (Namibia)"
  },
  {
    "name": "New Caledonia",
    "native": "Nouvelle-Calédonie",
    "phone": [
      687
    ],
    "continent": "OC",
    "capital": "Nouméa",
    "currency": [
      "XPF"
    ],
    "languages": [
      "fr"
    ],
    "both": "New Caledonia (Nouvelle-Calédonie)"
  },
  {
    "name": "Niger",
    "native": "Niger",
    "phone": [
      227
    ],
    "continent": "AF",
    "capital": "Niamey",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Niger (Niger)"
  },
  {
    "name": "Norfolk Island",
    "native": "Norfolk Island",
    "phone": [
      672
    ],
    "continent": "OC",
    "capital": "Kingston",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Norfolk Island (Norfolk Island)"
  },
  {
    "name": "Nigeria",
    "native": "Nigeria",
    "phone": [
      234
    ],
    "continent": "AF",
    "capital": "Abuja",
    "currency": [
      "NGN"
    ],
    "languages": [
      "en"
    ],
    "both": "Nigeria (Nigeria)"
  },
  {
    "name": "Nicaragua",
    "native": "Nicaragua",
    "phone": [
      505
    ],
    "continent": "NA",
    "capital": "Managua",
    "currency": [
      "NIO"
    ],
    "languages": [
      "es"
    ],
    "both": "Nicaragua (Nicaragua)"
  },
  {
    "name": "Netherlands",
    "native": "Nederland",
    "phone": [
      31
    ],
    "continent": "EU",
    "capital": "Amsterdam",
    "currency": [
      "EUR"
    ],
    "languages": [
      "nl"
    ],
    "both": "Netherlands (Nederland)"
  },
  {
    "name": "Norway",
    "native": "Norge",
    "phone": [
      47
    ],
    "continent": "EU",
    "capital": "Oslo",
    "currency": [
      "NOK"
    ],
    "languages": [
      "no",
      "nb",
      "nn"
    ],
    "both": "Norway (Norge)"
  },
  {
    "name": "Nepal",
    "native": "नपल",
    "phone": [
      977
    ],
    "continent": "AS",
    "capital": "Kathmandu",
    "currency": [
      "NPR"
    ],
    "languages": [
      "ne"
    ],
    "both": "Nepal (नपल)"
  },
  {
    "name": "Nauru",
    "native": "Nauru",
    "phone": [
      674
    ],
    "continent": "OC",
    "capital": "Yaren",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en",
      "na"
    ],
    "both": "Nauru (Nauru)"
  },
  {
    "name": "Niue",
    "native": "Niuē",
    "phone": [
      683
    ],
    "continent": "OC",
    "capital": "Alofi",
    "currency": [
      "NZD"
    ],
    "languages": [
      "en"
    ],
    "both": "Niue (Niuē)"
  },
  {
    "name": "New Zealand",
    "native": "New Zealand",
    "phone": [
      64
    ],
    "continent": "OC",
    "capital": "Wellington",
    "currency": [
      "NZD"
    ],
    "languages": [
      "en",
      "mi"
    ],
    "both": "New Zealand (New Zealand)"
  },
  {
    "name": "Oman",
    "native": "عمان",
    "phone": [
      968
    ],
    "continent": "AS",
    "capital": "Muscat",
    "currency": [
      "OMR"
    ],
    "languages": [
      "ar"
    ],
    "both": "Oman (عمان)"
  },
  {
    "name": "Panama",
    "native": "Panamá",
    "phone": [
      507
    ],
    "continent": "NA",
    "capital": "Panama City",
    "currency": [
      "PAB",
      "USD"
    ],
    "languages": [
      "es"
    ],
    "both": "Panama (Panamá)"
  },
  {
    "name": "Peru",
    "native": "Perú",
    "phone": [
      51
    ],
    "continent": "SA",
    "capital": "Lima",
    "currency": [
      "PEN"
    ],
    "languages": [
      "es"
    ],
    "both": "Peru (Perú)"
  },
  {
    "name": "French Polynesia",
    "native": "Polynésie française",
    "phone": [
      689
    ],
    "continent": "OC",
    "capital": "Papeetē",
    "currency": [
      "XPF"
    ],
    "languages": [
      "fr"
    ],
    "both": "French Polynesia (Polynésie française)"
  },
  {
    "name": "Papua New Guinea",
    "native": "Papua Niugini",
    "phone": [
      675
    ],
    "continent": "OC",
    "capital": "Port Moresby",
    "currency": [
      "PGK"
    ],
    "languages": [
      "en"
    ],
    "both": "Papua New Guinea (Papua Niugini)"
  },
  {
    "name": "Philippines",
    "native": "Pilipinas",
    "phone": [
      63
    ],
    "continent": "AS",
    "capital": "Manila",
    "currency": [
      "PHP"
    ],
    "languages": [
      "en"
    ],
    "both": "Philippines (Pilipinas)"
  },
  {
    "name": "Pakistan",
    "native": "Pakistan",
    "phone": [
      92
    ],
    "continent": "AS",
    "capital": "Islamabad",
    "currency": [
      "PKR"
    ],
    "languages": [
      "en",
      "ur"
    ],
    "both": "Pakistan (Pakistan)"
  },
  {
    "name": "Poland",
    "native": "Polska",
    "phone": [
      48
    ],
    "continent": "EU",
    "capital": "Warsaw",
    "currency": [
      "PLN"
    ],
    "languages": [
      "pl"
    ],
    "both": "Poland (Polska)"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "native": "Saint-Pierre-et-Miquelon",
    "phone": [
      508
    ],
    "continent": "NA",
    "capital": "Saint-Pierre",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)"
  },
  {
    "name": "Pitcairn Islands",
    "native": "Pitcairn Islands",
    "phone": [
      64
    ],
    "continent": "OC",
    "capital": "Adamstown",
    "currency": [
      "NZD"
    ],
    "languages": [
      "en"
    ],
    "both": "Pitcairn Islands (Pitcairn Islands)"
  },
  {
    "name": "Puerto Rico",
    "native": "Puerto Rico",
    "phone": [
      1787,
      1939
    ],
    "continent": "NA",
    "capital": "San Juan",
    "currency": [
      "USD"
    ],
    "languages": [
      "es",
      "en"
    ],
    "both": "Puerto Rico (Puerto Rico)"
  },
  {
    "name": "Palestine",
    "native": "فلسطين",
    "phone": [
      970
    ],
    "continent": "AS",
    "capital": "Ramallah",
    "currency": [
      "ILS"
    ],
    "languages": [
      "ar"
    ],
    "both": "Palestine (فلسطين)"
  },
  {
    "name": "Palau",
    "native": "Palau",
    "phone": [
      680
    ],
    "continent": "OC",
    "capital": "Ngerulmud",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "Palau (Palau)"
  },
  {
    "name": "Paraguay",
    "native": "Paraguay",
    "phone": [
      595
    ],
    "continent": "SA",
    "capital": "Asunción",
    "currency": [
      "PYG"
    ],
    "languages": [
      "es",
      "gn"
    ],
    "both": "Paraguay (Paraguay)"
  },
  {
    "name": "Qatar",
    "native": "قطر",
    "phone": [
      974
    ],
    "continent": "AS",
    "capital": "Doha",
    "currency": [
      "QAR"
    ],
    "languages": [
      "ar"
    ],
    "both": "Qatar (قطر)"
  },
  {
    "name": "Réunion",
    "native": "La Réunion",
    "phone": [
      262
    ],
    "continent": "AF",
    "capital": "Saint-Denis",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Réunion (La Réunion)"
  },
  {
    "name": "Romania",
    "native": "România",
    "phone": [
      40
    ],
    "continent": "EU",
    "capital": "Bucharest",
    "currency": [
      "RON"
    ],
    "languages": [
      "ro"
    ],
    "both": "Romania (România)"
  },
  {
    "name": "Serbia",
    "native": "Србија",
    "phone": [
      381
    ],
    "continent": "EU",
    "capital": "Belgrade",
    "currency": [
      "RSD"
    ],
    "languages": [
      "sr"
    ],
    "both": "Serbia (Србија)"
  },
  {
    "name": "Rwanda",
    "native": "Rwanda",
    "phone": [
      250
    ],
    "continent": "AF",
    "capital": "Kigali",
    "currency": [
      "RWF"
    ],
    "languages": [
      "rw",
      "en",
      "fr"
    ],
    "both": "Rwanda (Rwanda)"
  },
  {
    "name": "Saudi Arabia",
    "native": "العربية السعودية",
    "phone": [
      966
    ],
    "continent": "AS",
    "capital": "Riyadh",
    "currency": [
      "SAR"
    ],
    "languages": [
      "ar"
    ],
    "both": "Saudi Arabia (العربية السعودية)"
  },
  {
    "name": "Solomon Islands",
    "native": "Solomon Islands",
    "phone": [
      677
    ],
    "continent": "OC",
    "capital": "Honiara",
    "currency": [
      "SBD"
    ],
    "languages": [
      "en"
    ],
    "both": "Solomon Islands (Solomon Islands)"
  },
  {
    "name": "Seychelles",
    "native": "Seychelles",
    "phone": [
      248
    ],
    "continent": "AF",
    "capital": "Victoria",
    "currency": [
      "SCR"
    ],
    "languages": [
      "fr",
      "en"
    ],
    "both": "Seychelles (Seychelles)"
  },
  {
    "name": "Sudan",
    "native": "السودان",
    "phone": [
      249
    ],
    "continent": "AF",
    "capital": "Khartoum",
    "currency": [
      "SDG"
    ],
    "languages": [
      "ar",
      "en"
    ],
    "both": "Sudan (السودان)"
  },
  {
    "name": "Sweden",
    "native": "Sverige",
    "phone": [
      46
    ],
    "continent": "EU",
    "capital": "Stockholm",
    "currency": [
      "SEK"
    ],
    "languages": [
      "sv"
    ],
    "both": "Sweden (Sverige)"
  },
  {
    "name": "Singapore",
    "native": "Singapore",
    "phone": [
      65
    ],
    "continent": "AS",
    "capital": "Singapore",
    "currency": [
      "SGD"
    ],
    "languages": [
      "en",
      "ms",
      "ta",
      "zh"
    ],
    "both": "Singapore (Singapore)"
  },
  {
    "name": "Saint Helena",
    "native": "Saint Helena",
    "phone": [
      290
    ],
    "continent": "AF",
    "capital": "Jamestown",
    "currency": [
      "SHP"
    ],
    "languages": [
      "en"
    ],
    "both": "Saint Helena (Saint Helena)"
  },
  {
    "name": "Slovenia",
    "native": "Slovenija",
    "phone": [
      386
    ],
    "continent": "EU",
    "capital": "Ljubljana",
    "currency": [
      "EUR"
    ],
    "languages": [
      "sl"
    ],
    "both": "Slovenia (Slovenija)"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "native": "Svalbard og Jan Mayen",
    "phone": [
      4779
    ],
    "continent": "EU",
    "capital": "Longyearbyen",
    "currency": [
      "NOK"
    ],
    "languages": [
      "no"
    ],
    "both": "Svalbard and Jan Mayen (Svalbard og Jan Mayen)"
  },
  {
    "name": "Slovakia",
    "native": "Slovensko",
    "phone": [
      421
    ],
    "continent": "EU",
    "capital": "Bratislava",
    "currency": [
      "EUR"
    ],
    "languages": [
      "sk"
    ],
    "both": "Slovakia (Slovensko)"
  },
  {
    "name": "Sierra Leone",
    "native": "Sierra Leone",
    "phone": [
      232
    ],
    "continent": "AF",
    "capital": "Freetown",
    "currency": [
      "SLL"
    ],
    "languages": [
      "en"
    ],
    "both": "Sierra Leone (Sierra Leone)"
  },
  {
    "name": "San Marino",
    "native": "San Marino",
    "phone": [
      378
    ],
    "continent": "EU",
    "capital": "City of San Marino",
    "currency": [
      "EUR"
    ],
    "languages": [
      "it"
    ],
    "both": "San Marino (San Marino)"
  },
  {
    "name": "Senegal",
    "native": "Sénégal",
    "phone": [
      221
    ],
    "continent": "AF",
    "capital": "Dakar",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Senegal (Sénégal)"
  },
  {
    "name": "Somalia",
    "native": "Soomaaliya",
    "phone": [
      252
    ],
    "continent": "AF",
    "capital": "Mogadishu",
    "currency": [
      "SOS"
    ],
    "languages": [
      "so",
      "ar"
    ],
    "both": "Somalia (Soomaaliya)"
  },
  {
    "name": "Suriname",
    "native": "Suriname",
    "phone": [
      597
    ],
    "continent": "SA",
    "capital": "Paramaribo",
    "currency": [
      "SRD"
    ],
    "languages": [
      "nl"
    ],
    "both": "Suriname (Suriname)"
  },
  {
    "name": "South Sudan",
    "native": "South Sudan",
    "phone": [
      211
    ],
    "continent": "AF",
    "capital": "Juba",
    "currency": [
      "SSP"
    ],
    "languages": [
      "en"
    ],
    "both": "South Sudan (South Sudan)"
  },
  {
    "name": "São Tomé and Príncipe",
    "native": "São Tomé e Príncipe",
    "phone": [
      239
    ],
    "continent": "AF",
    "capital": "São Tomé",
    "currency": [
      "STN"
    ],
    "languages": [
      "pt"
    ],
    "both": "São Tomé and Príncipe (São Tomé e Príncipe)"
  },
  {
    "name": "El Salvador",
    "native": "El Salvador",
    "phone": [
      503
    ],
    "continent": "NA",
    "capital": "San Salvador",
    "currency": [
      "SVC",
      "USD"
    ],
    "languages": [
      "es"
    ],
    "both": "El Salvador (El Salvador)"
  },
  {
    "name": "Sint Maarten",
    "native": "Sint Maarten",
    "phone": [
      1721
    ],
    "continent": "NA",
    "capital": "Philipsburg",
    "currency": [
      "ANG"
    ],
    "languages": [
      "nl",
      "en"
    ],
    "both": "Sint Maarten (Sint Maarten)"
  },
  {
    "name": "Syria",
    "native": "سوريا",
    "phone": [
      963
    ],
    "continent": "AS",
    "capital": "Damascus",
    "currency": [
      "SYP"
    ],
    "languages": [
      "ar"
    ],
    "both": "Syria (سوريا)"
  },
  {
    "name": "Swaziland",
    "native": "Swaziland",
    "phone": [
      268
    ],
    "continent": "AF",
    "capital": "Lobamba",
    "currency": [
      "SZL"
    ],
    "languages": [
      "en",
      "ss"
    ],
    "both": "Swaziland (Swaziland)"
  },
  {
    "name": "Turks and Caicos Islands",
    "native": "Turks and Caicos Islands",
    "phone": [
      1649
    ],
    "continent": "NA",
    "capital": "Cockburn Town",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "Turks and Caicos Islands (Turks and Caicos Islands)"
  },
  {
    "name": "Chad",
    "native": "Tchad",
    "phone": [
      235
    ],
    "continent": "AF",
    "capital": "N'Djamena",
    "currency": [
      "XAF"
    ],
    "languages": [
      "fr",
      "ar"
    ],
    "both": "Chad (Tchad)"
  },
  {
    "name": "French Southern Territories",
    "native": "Territoire des Terres australes et antarctiques fr",
    "phone": [
      262
    ],
    "continent": "AN",
    "capital": "Port-aux-Français",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "French Southern Territories (Territoire des Terres australes et antarctiques fr)"
  },
  {
    "name": "Togo",
    "native": "Togo",
    "phone": [
      228
    ],
    "continent": "AF",
    "capital": "Lomé",
    "currency": [
      "XOF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Togo (Togo)"
  },
  {
    "name": "Thailand",
    "native": "ประเทศไทย",
    "phone": [
      66
    ],
    "continent": "AS",
    "capital": "Bangkok",
    "currency": [
      "THB"
    ],
    "languages": [
      "th"
    ],
    "both": "Thailand (ประเทศไทย)"
  },
  {
    "name": "Tajikistan",
    "native": "Тоҷикистон",
    "phone": [
      992
    ],
    "continent": "AS",
    "capital": "Dushanbe",
    "currency": [
      "TJS"
    ],
    "languages": [
      "tg",
      "ru"
    ],
    "both": "Tajikistan (Тоҷикистон)"
  },
  {
    "name": "Tokelau",
    "native": "Tokelau",
    "phone": [
      690
    ],
    "continent": "OC",
    "capital": "Fakaofo",
    "currency": [
      "NZD"
    ],
    "languages": [
      "en"
    ],
    "both": "Tokelau (Tokelau)"
  },
  {
    "name": "East Timor",
    "native": "Timor-Leste",
    "phone": [
      670
    ],
    "continent": "OC",
    "capital": "Dili",
    "currency": [
      "USD"
    ],
    "languages": [
      "pt"
    ],
    "both": "East Timor (Timor-Leste)"
  },
  {
    "name": "Turkmenistan",
    "native": "Türkmenistan",
    "phone": [
      993
    ],
    "continent": "AS",
    "capital": "Ashgabat",
    "currency": [
      "TMT"
    ],
    "languages": [
      "tk",
      "ru"
    ],
    "both": "Turkmenistan (Türkmenistan)"
  },
  {
    "name": "Tunisia",
    "native": "تونس",
    "phone": [
      216
    ],
    "continent": "AF",
    "capital": "Tunis",
    "currency": [
      "TND"
    ],
    "languages": [
      "ar"
    ],
    "both": "Tunisia (تونس)"
  },
  {
    "name": "Tonga",
    "native": "Tonga",
    "phone": [
      676
    ],
    "continent": "OC",
    "capital": "Nuku'alofa",
    "currency": [
      "TOP"
    ],
    "languages": [
      "en",
      "to"
    ],
    "both": "Tonga (Tonga)"
  },
  {
    "name": "Turkey",
    "native": "Türkiye",
    "phone": [
      90
    ],
    "continent": "AS",
    "continents": [
      "AS",
      "EU"
    ],
    "capital": "Ankara",
    "currency": [
      "TRY"
    ],
    "languages": [
      "tr"
    ],
    "both": "Turkey (Türkiye)"
  },
  {
    "name": "Trinidad and Tobago",
    "native": "Trinidad and Tobago",
    "phone": [
      1868
    ],
    "continent": "NA",
    "capital": "Port of Spain",
    "currency": [
      "TTD"
    ],
    "languages": [
      "en"
    ],
    "both": "Trinidad and Tobago (Trinidad and Tobago)"
  },
  {
    "name": "Tuvalu",
    "native": "Tuvalu",
    "phone": [
      688
    ],
    "continent": "OC",
    "capital": "Funafuti",
    "currency": [
      "AUD"
    ],
    "languages": [
      "en"
    ],
    "both": "Tuvalu (Tuvalu)"
  },
  {
    "name": "Taiwan",
    "native": "臺灣",
    "phone": [
      886
    ],
    "continent": "AS",
    "capital": "Taipei",
    "currency": [
      "TWD"
    ],
    "languages": [
      "zh"
    ],
    "both": "Taiwan (臺灣)"
  },
  {
    "name": "Tanzania",
    "native": "Tanzania",
    "phone": [
      255
    ],
    "continent": "AF",
    "capital": "Dodoma",
    "currency": [
      "TZS"
    ],
    "languages": [
      "sw",
      "en"
    ],
    "both": "Tanzania (Tanzania)"
  },
  {
    "name": "Ukraine",
    "native": "Україна",
    "phone": [
      380
    ],
    "continent": "EU",
    "capital": "Kyiv",
    "currency": [
      "UAH"
    ],
    "languages": [
      "uk"
    ],
    "both": "Ukraine (Україна)"
  },
  {
    "name": "Uganda",
    "native": "Uganda",
    "phone": [
      256
    ],
    "continent": "AF",
    "capital": "Kampala",
    "currency": [
      "UGX"
    ],
    "languages": [
      "en",
      "sw"
    ],
    "both": "Uganda (Uganda)"
  },
  {
    "name": "U.S. Minor Outlying Islands",
    "native": "United States Minor Outlying Islands",
    "phone": [
      1
    ],
    "continent": "OC",
    "capital": "",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "U.S. Minor Outlying Islands (United States Minor Outlying Islands)"
  },
  {
    "name": "United States",
    "native": "United States",
    "phone": [
      1
    ],
    "continent": "NA",
    "capital": "Washington D.C.",
    "currency": [
      "USD",
      "USN",
      "USS"
    ],
    "languages": [
      "en"
    ],
    "both": "United States (United States)"
  },
  {
    "name": "Uruguay",
    "native": "Uruguay",
    "phone": [
      598
    ],
    "continent": "SA",
    "capital": "Montevideo",
    "currency": [
      "UYI",
      "UYU"
    ],
    "languages": [
      "es"
    ],
    "both": "Uruguay (Uruguay)"
  },
  {
    "name": "Uzbekistan",
    "native": "O‘zbekiston",
    "phone": [
      998
    ],
    "continent": "AS",
    "capital": "Tashkent",
    "currency": [
      "UZS"
    ],
    "languages": [
      "uz",
      "ru"
    ],
    "both": "Uzbekistan (O‘zbekiston)"
  },
  {
    "name": "Vatican City",
    "native": "Vaticano",
    "phone": [
      379
    ],
    "continent": "EU",
    "capital": "Vatican City",
    "currency": [
      "EUR"
    ],
    "languages": [
      "it",
      "la"
    ],
    "both": "Vatican City (Vaticano)"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "native": "Saint Vincent and the Grenadines",
    "phone": [
      1784
    ],
    "continent": "NA",
    "capital": "Kingstown",
    "currency": [
      "XCD"
    ],
    "languages": [
      "en"
    ],
    "both": "Saint Vincent and the Grenadines (Saint Vincent and the Grenadines)"
  },
  {
    "name": "Venezuela",
    "native": "Venezuela",
    "phone": [
      58
    ],
    "continent": "SA",
    "capital": "Caracas",
    "currency": [
      "VES"
    ],
    "languages": [
      "es"
    ],
    "both": "Venezuela (Venezuela)"
  },
  {
    "name": "British Virgin Islands",
    "native": "British Virgin Islands",
    "phone": [
      1284
    ],
    "continent": "NA",
    "capital": "Road Town",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "British Virgin Islands (British Virgin Islands)"
  },
  {
    "name": "U.S. Virgin Islands",
    "native": "United States Virgin Islands",
    "phone": [
      1340
    ],
    "continent": "NA",
    "capital": "Charlotte Amalie",
    "currency": [
      "USD"
    ],
    "languages": [
      "en"
    ],
    "both": "U.S. Virgin Islands (United States Virgin Islands)"
  },
  {
    "name": "Vietnam",
    "native": "Việt Nam",
    "phone": [
      84
    ],
    "continent": "AS",
    "capital": "Hanoi",
    "currency": [
      "VND"
    ],
    "languages": [
      "vi"
    ],
    "both": "Vietnam (Việt Nam)"
  },
  {
    "name": "Vanuatu",
    "native": "Vanuatu",
    "phone": [
      678
    ],
    "continent": "OC",
    "capital": "Port Vila",
    "currency": [
      "VUV"
    ],
    "languages": [
      "bi",
      "en",
      "fr"
    ],
    "both": "Vanuatu (Vanuatu)"
  },
  {
    "name": "Wallis and Futuna",
    "native": "Wallis et Futuna",
    "phone": [
      681
    ],
    "continent": "OC",
    "capital": "Mata-Utu",
    "currency": [
      "XPF"
    ],
    "languages": [
      "fr"
    ],
    "both": "Wallis and Futuna (Wallis et Futuna)"
  },
  {
    "name": "Samoa",
    "native": "Samoa",
    "phone": [
      685
    ],
    "continent": "OC",
    "capital": "Apia",
    "currency": [
      "WST"
    ],
    "languages": [
      "sm",
      "en"
    ],
    "both": "Samoa (Samoa)"
  },
  {
    "name": "Kosovo",
    "native": "Republika e Kosovës",
    "phone": [
      377,
      381,
      383,
      386
    ],
    "continent": "EU",
    "capital": "Pristina",
    "currency": [
      "EUR"
    ],
    "languages": [
      "sq",
      "sr"
    ],
    "both": "Kosovo (Republika e Kosovës)"
  },
  {
    "name": "Yemen",
    "native": "اليَمَن",
    "phone": [
      967
    ],
    "continent": "AS",
    "capital": "Sana'a",
    "currency": [
      "YER"
    ],
    "languages": [
      "ar"
    ],
    "both": "Yemen (اليَمَن)"
  },
  {
    "name": "Mayotte",
    "native": "Mayotte",
    "phone": [
      262
    ],
    "continent": "AF",
    "capital": "Mamoudzou",
    "currency": [
      "EUR"
    ],
    "languages": [
      "fr"
    ],
    "both": "Mayotte (Mayotte)"
  },
  {
    "name": "South Africa",
    "native": "South Africa",
    "phone": [
      27
    ],
    "continent": "AF",
    "capital": "Pretoria",
    "currency": [
      "ZAR"
    ],
    "languages": [
      "af",
      "en",
      "nr",
      "st",
      "ss",
      "tn",
      "ts",
      "ve",
      "xh",
      "zu"
    ],
    "both": "South Africa (South Africa)"
  },
  {
    "name": "Zambia",
    "native": "Zambia",
    "phone": [
      260
    ],
    "continent": "AF",
    "capital": "Lusaka",
    "currency": [
      "ZMW"
    ],
    "languages": [
      "en"
    ],
    "both": "Zambia (Zambia)"
  },
  {
    "name": "Zimbabwe",
    "native": "Zimbabwe",
    "phone": [
      263
    ],
    "continent": "AF",
    "capital": "Harare",
    "currency": [
      "USD",
      "ZAR",
      "BWP",
      "GBP",
      "AUD",
      "CNY",
      "INR",
      "JPY"
    ],
    "languages": [
      "en",
      "sn",
      "nd"
    ],
    "both": "Zimbabwe (Zimbabwe)"
  }
]