<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar :color="$vuetify.theme.dark ? '' : 'white'">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/9`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="employees"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :search="search"
        :server-items-length.sync="total"
        :options.sync="tableOptions"
        :page.sync="page"
        item-key="id"
        :class="!isMobile ? 'elevation-1 mt-2' : 'elevation-1 mt-2 is-mobile'"
        mobile-breakpoint="100"
        :items-per-page="10"
        dense
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} ${total}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 30, 50, 150, 300, -1],
        }"
      >
        <template v-slot:item="props">
          <tr v-if="props.item.active || showNotActive" @dblclick="replaceToAgent(props.item)">
            <td class="text-center">
              <v-chip
                class="ma-2"
                :color="
                  checkLogin(props.item.id) || props.item.login
                    ? 'green'
                    : 'secondery'
                "
                text-color="white"
              >
                {{ props.item.id }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ formatCreatedAt(props.item.last_login) }}
            </td>
            <td class="text-center">
              <router-link :to="`/agent_card/${props.item.id}`">
                {{ props.item.name }}
              </router-link>
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              <router-link :to="`/agent_card/${props.item.id}`">
                {{ props.item.email }}
              </router-link>
            </td>
            <td class="text-center" v-if="props.item.agent_setting.commission_percent">
              {{ props.item.agent_setting.commission_percent }}%
            </td>
            <td v-else class="text-center">
              {{ props.item.agent_setting.commission_fix }}{{ currency }}
            </td>
            <td class="text-center">
              {{ calculateAgentCommission(props.item) }}{{ currency }}
            </td>
            <td class="text-center">
              {{ getUserAccount(props.item.id) }}{{ currency }}
            </td>
            <td class="text-center">
              <v-btn
                small
                @click="showCreateCountDialog = true, agent_id = props.item.id"
                class="text-center mr-2"
                color="success"
              >
                {{ $t("agents.payout") }}
              </v-btn>
              <v-btn
                small
                @click="setUser(props.item.id), (kassa_dialog = true)"
                class="text-center mr-2"
                color="info"
              >
                {{ $t("custom.counts") }}
              </v-btn>
              <v-btn
                small
                @click="getBookings(props.item.id)"
                class="text-center"
                color="secondary"
              >
                {{ $t("custom.bookings") }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn
          v-if="checkAdminManager()"
          small
          color="success"
          :to="{ name: 'CreateAgent' }"
          class="mt-2 mr-2 mb-2"
          >{{ $t("agents.add_agent") }}</v-btn
        >
        <v-btn
          v-if="!showNotActive"
          small
          @click="showNotActive = true"
          class="mt-2 mb-2"
          >{{ $t("agents.show_dismiss") }}</v-btn
        >
        <v-btn
          v-if="showNotActive"
          small
          @click="showNotActive = false"
          class="mt-2 mb-2"
          >{{ $t("agents.remove_dismiss") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="kassa_dialog"
      :retain-focus="false"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("custom.counts") }} - {{ user.name }}
          <v-icon class="ml-2 green--text" @click="updateUserKassa()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (kassa_dialog = false),
                (money_track = false),
                (user = { account: { cash: 0 } }),
                (user_cash = 0),
                (get_cash = 0),
                (give_cash = 0)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex md1 class="mr-2">
                <v-text-field
                  readonly
                  v-model.number="user.account.cash"
                  :label="$t('counts.in_count')"
                ></v-text-field>
              </v-flex>
              <v-flex md2 class="mr-2">
                <v-text-field
                  :disabled="give_cash > 0"
                  v-model.number="get_cash"
                  :label="$t('counts.get_cash')"
                ></v-text-field>
              </v-flex>
              <v-flex md2 class="mr-2">
                <v-text-field
                  :disabled="get_cash > 0"
                  v-model.number="give_cash"
                  :label="$t('counts.give_cash')"
                ></v-text-field>
              </v-flex>
              <v-flex md4 class="mr-2" v-if="checkAdmin()">
                <v-radio-group v-model="money_track" row>
                  <v-radio
                    :label="$t('counts.company_counts')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :disabled="currentUserId == user.id"
                    :label="$t('counts.employee_count')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex md2 class="mr-2 mb-2 align-center">
                <v-btn color="success" @click="updateUserKassa()">{{
                  $t("custom.save")
                }}</v-btn>
              </v-flex>
              <v-flex md12>
                <v-card>
                  <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('custom.search')"
                      single-line
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-toolbar>
                  <v-data-table
                    :headers="headers_cashier"
                    :items="counts"
                    :search="search"
                    :loading="table_counts_loading"
                    :loading-text="$t('custom.loading_table')"
                    :class="!isMobile ? '' : 'is-mobile'"
                    :items-per-page="20"
                    mobile-breakpoint="100"
                    :sort-by="['created_at']"
                    :sort-desc="[true]"
                    :footer-props="{
                      pageText: `{0} ${$t('custom.of')} {1}`,
                      itemsPerPageText: $t('custom.elements_table'),
                      showFirstLastPage: true,
                      itemsPerPageOptions: [20, 50, 100, -1],
                    }"
                  >
                    <template slot="item" slot-scope="props">
                      <tr
                        :class="
                          props.item.operation && props.item.sum < 0
                            ? 'red--text'
                            : ''
                        "
                      >
                        <td class="text-center">{{ props.item.id }}</td>
                        <td class="text-center">
                          {{ returnDate(props.item.created_at) }}
                        </td>
                        <td class="text-center">
                          <v-icon @click="editCount(props.item)">
                            mdi-pencil-outline
                          </v-icon>
                        </td>
                        <td class="text-center">
                          {{ translateCategory(props.item.group) }}
                        </td>
                        <td class="text-center">
                          {{ props.item.description }}
                        </td>
                        <td class="text-center">
                          {{ props.item.sum }}{{ currency }}
                        </td>
                        <td class="text-center" v-if="props.item.operation">
                          <v-icon class="text-center" style="color: green">
                            mdi-plus-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="!props.item.operation">
                          <v-icon class="text-center" style="color: tomato">
                            mdi-minus-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cash">
                          <v-icon
                            class="text-center"
                            color="success"
                            v-tooltip="{
                              content: $t('money.cash'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-cash
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cashless">
                          <v-icon
                            class="text-center"
                            color="warning"
                            v-tooltip="{
                              content: $t('money.terminal'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-contactless-payment-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.entity">
                          <v-icon
                            class="text-center"
                            color="secondery"
                            v-tooltip="{
                              content: $t('money.from_entity'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-bank
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cash_card">
                          <v-icon
                            class="text-center"
                            color="success"
                            v-tooltip="{
                              content: $t('money.card_to_card'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-credit-card-check-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.client_balance">
                          <v-icon
                            class="text-center"
                            color="error"
                            v-tooltip="{
                              content: $t('money.client_balance'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-account-arrow-right
                          </v-icon>
                        </td>
                        <td
                          class="text-left"
                          nowrap
                          v-if="
                            props.item.booking_id &&
                            props.item.car_id &&
                            props.item.car_code
                          "
                        >
                          <p>
                            {{ $t("bookings.booking") }}:
                            <router-link
                              :to="{
                                path: `/bookings/${props.item.booking_id}`,
                              }"
                            >
                              {{ props.item.booking_id }}
                            </router-link>
                          </p>
                          <p>
                            {{ $t("custom.car") }}:
                            <router-link
                              :to="{ path: `/cars/${props.item.car_id}` }"
                            >
                              {{ props.item.car_code }}
                            </router-link>
                          </p>
                        </td>
                        <td
                          class="text-left"
                          v-else-if="props.item.car_id && props.item.car_code"
                        >
                          {{ $t("custom.car") }}:
                          <router-link
                            :to="{ path: `/cars/${props.item.car_id}` }"
                            >{{ props.item.car_code }}</router-link
                          >
                        </td>
                        <td class="text-left" v-else-if="props.item.source">
                          {{ props.item.source }}
                        </td>
                        <td class="text-center" v-else-if="props.item.debt_id">
                          {{ $t("custom.deb") }} №{{ props.item.debt_id }}
                        </td>
                        <td class="text-center" v-else-if="props.item.agent_id">
                          {{ $t("agents.agent") }} {{ $t("custom.number") }}
                          <router-link
                            :to="{ path: `/agent_card/${props.item.agent_id}` }"
                          >
                            {{ props.item.agent_id }}
                          </router-link>
                        </td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-center">
                          <v-menu offset-y v-if="props.item.check == null">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.some_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="secondary"
                              >
                                mdi-map-marker-question-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-menu offset-y v-if="props.item.check == true">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.trust_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                              >
                                mdi-checkbox-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-menu offset-y v-if="props.item.check == false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.bad_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="error"
                              >
                                mdi-bookmark-remove-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data">
                      {{ $t("custom.no_data_in_table") }}
                    </template>
                    <v-alert
                      slot="no-results"
                      :value="true"
                      color="error"
                      icon="mdi-alert"
                    >
                      {{ $t("tables.no_search_result", { msg: search }) }}
                    </v-alert>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm1 md1>
                    <v-text-field
                      v-model.number="editedCount.sum"
                      :label="$t('custom.amount')"
                      readonly
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-combobox
                      v-model="selected_count.group"
                      :items="counts_groups"
                      item-text="text"
                      item-value="value"
                      :label="$t('custom.select_group')"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="selected_car"
                      item-text="code"
                      item-value="id"
                      text
                      hide-no-data
                      hide-details
                      :label="$t('custom.select_car')"
                      :items="cars"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-radio-group v-model="editedCount.operation" row>
                      <v-radio
                        :label="$t('custom.income')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('custom.expences')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <td
                    class="text-center"
                    v-if="!editedCount.cashless && !editedCount.cash_card"
                  >
                    <v-icon class="text-center" color="success">
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="editedCount.cashless">
                    <v-icon class="text-center" color="warning">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="editedCount.cash_card">
                    <v-icon class="text-center" color="success">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="editedCount.description"
                      :label="$t('counts.about')"
                      :placeholder="$t('counts.about_placeholder')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCount()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showBookingsDialog"
      :retain-focus="false"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.bookings") }}</span>
          <v-spacer></v-spacer>
            <v-icon
              :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
              @click.stop="showBookingsDialog = false"
            >
              mdi-close
            </v-icon>
        </v-card-title>
        <hr class="mb-2 mt-3" />
        <v-card-text>
          <v-toolbar
            text
            :color="$vuetify.theme.dark ? '' : 'white'"
            class="start-tour-car-1"
          >
            <v-spacer></v-spacer>
            <v-row align="center" class="ml-4">
              <v-text-field
                :dense="isMobile"
                v-model="search_bookings"
                prepend-icon="mdi-magnify"
                :label="$t('custom.search')"
                single-line
                clearable
                hide-details
              ></v-text-field>
            </v-row>
          </v-toolbar>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <vc-date-picker
                :value="new Date()"
                :attributes="calendar_attributes"
                title-position="left"
                :columns="$screens({ default: 1, md: 2, lg: 4 })"
                :is-expanded="true"
                :locale="$i18n.locale"
                :is-dark="$vuetify.theme.dark"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3"></v-divider>

          <v-data-table
            :headers="bookings_headers"
            :items="bookings"
            :search="search_bookings"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            sort-by="sort"
            :sort-desc="true"
            item-key="id"
            :items-per-page="10"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr
                @dblclick="replaceToBooking(props.item)"
                style="cursor: pointer"
                :class="
                  !props.item.technical ? '' : 'blue-grey lighten-4'
                "
              >
                <td class="text-center" :key="props.item.id">
                  <router-link
                    :to="{
                      name: 'BookingCard',
                      params: { id: props.item.id },
                    }"
                    >{{ props.item.id }}</router-link
                  >
                </td>
                <td nowrap class="text-center">
                  {{ returnDate(props.item.created_at) }}
                </td>
                <td class="text-center">
                  <v-flex class="d-flex text-center justify-center">
                    <v-icon
                      class="mr-2"
                      v-if="props.item.in_rent"
                      color="primary"
                      v-tooltip="{
                        content: $t('bookings.in_rent'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-car
                    </v-icon>
                    <v-icon
                      v-if="props.item.active && !props.item.in_rent"
                      class="mr-2"
                      style="z-index: 1"
                      color="secondary"
                      v-tooltip="{
                        content: $t('bookings.booked'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-clock-outline
                    </v-icon>
                    <v-icon
                      v-if="props.item.failure"
                      v-tooltip="{
                        content: $t('bookings.decline'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                  </v-flex>
                </td>
                <td class="text-center">
                  <v-flex class="d-flex text-center justify-center">
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Новая'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="primary"
                            v-tooltip="{
                              content: $t('bookings.new'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-new-box
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Подтверждена'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="success"
                            v-tooltip="{
                              content: $t('bookings.approved'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-check-all
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Не подтверждена'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="warning"
                            v-tooltip="{
                              content: $t('bookings.not_approved'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-cellphone-basic
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'В обработке'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="primary"
                            v-tooltip="{
                              content: $t('bookings.in_work'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-autorenew
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Отказ клиента'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="danger"
                            v-tooltip="{
                              content: $t('bookings.declined'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-account-remove-outline
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Нет машин'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="secondary"
                            v-tooltip="{
                              content: $t(
                                'bookings.declined_free_cars'
                              ),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-car-off
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Недозвон'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="warning--text"
                        >
                          <v-icon
                            color="warning"
                            v-tooltip="{
                              content: $t('bookings.not_called'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-phone-off-outline
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="
                        props.item.state == 'Ожидает ответа клиента'
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="pink"
                            v-tooltip="{
                              content: $t(
                                'bookings.waiting_for_answer'
                              ),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-account-question-outline
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Ожидает оплаты'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="lime"
                            v-tooltip="{
                              content: $t('bookings.waiting_for_pay'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-currency-usd-off
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Активная'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="light-blue"
                            v-tooltip="{
                              content: $t('bookings.active_state'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-alpha-a-circle-outline
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Отмена'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="brown"
                            v-tooltip="{
                              content: $t('bookings.canceled_state'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-cancel
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="props.item.state == 'Отъездила'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="light-green"
                            v-tooltip="{
                              content: $t('bookings.ride_true'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-garage-variant
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      offset-y
                      v-if="
                        props.item.state == 'Другое' ||
                        props.item.state == null
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon
                            color="secondary"
                            v-tooltip="{
                              content: $t('bookings.other_state'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-head-question-outline
                          </v-icon>
                        </span>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in bookings_states"
                          :key="index"
                          @click="
                            saveBookingState(
                              state.value,
                              props.item.id
                            )
                          "
                        >
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-flex>
                </td>
                <td
                  class="text-center"
                  nowrap
                  v-if="!props.item.entity"
                  :style="
                    user_role == 'guest' ? 'display: none;' : ''
                  "
                >
                  <router-link
                    :to="{
                      name: 'ClientCard',
                      params: { id: props.item.client_id },
                    }"
                  >
                    {{
                      props.item.last_name ? props.item.last_name : ""
                    }}
                    {{
                      props.item.first_name
                        ? props.item.first_name[0]
                        : ""
                    }}.
                    {{
                      props.item.middle_name
                        ? props.item.middle_name[0]
                        : ""
                    }}.
                  </router-link>
                </td>
                <td
                  class="text-center"
                  v-else
                  :style="
                    user_role == 'guest' ? 'display: none;' : ''
                  "
                >
                  <router-link
                    :to="{
                      name: 'ClientCard',
                      params: { id: props.item.client_id },
                    }"
                    >{{ props.item.entity_name }}</router-link
                  >
                </td>
                <td v-if="!isMobile" nowrap class="text-center">
                  {{ formatDateBookingDates(props.item.start_date) }}
                  <br />
                  {{ formatTimeBookingDates(props.item.start_date) }}
                </td>
                <td v-else nowrap class="text-center">
                  {{ shortFormatBookingDates(props.item.start_date) }}
                </td>
                <td
                  class="text-center"
                  nowrap
                  v-if="!isMobile && !props.item.active"
                >
                  {{ formatDateBookingDates(props.item.end_date) }}
                  <br />
                  {{ formatTimeBookingDates(props.item.end_date) }}
                </td>
                <td
                  class="text-center"
                  nowrap
                  v-if="isMobile && !props.item.active"
                >
                  {{ shortFormatBookingDates(props.item.end_date) }}
                </td>
                <td
                  class="text-center"
                  nowrap
                  v-if="!isMobile && props.item.active"
                  :style="checkLateDate(props.item.end_date)"
                >
                  {{ formatDateBookingDates(props.item.end_date) }}
                  <br />
                  {{ formatTimeBookingDates(props.item.end_date) }}
                </td>
                <td
                  class="text-center"
                  nowrap
                  v-if="isMobile && props.item.active"
                  :style="checkLateDate(props.item.end_date)"
                >
                  {{ shortFormatBookingDates(props.item.end_date) }}
                </td>
                <td class="text-center">{{ props.item.days }}</td>
                <td class="text-center">
                  {{ props.item.location_start }}
                </td>
                <td class="text-center">
                  {{ props.item.location_end }}
                </td>
                <td
                  class="text-truncate"
                  style="max-width: 200px"
                  @click="
                    (showDescriptionEditId = props.item.id),
                      (descriptionEdit = props.item.description),
                      (showDescriptionEditDialog = true)
                  "
                  v-tooltip="{
                    content: `${props.item.description}`,
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  {{ props.item.description }}
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{
                $t("tables.no_search_result", {
                  msg: search_bookings,
                })
              }}
            </v-alert>
          </v-data-table>
          <!-- </v-layout> -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showBookingsDialog = false">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCreateCountDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("agents.payout_for_agent") }}</span>
          <v-spacer></v-spacer>
            <v-icon
              :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
              @click.stop="showCreateCountDialog = false, agent_id = null"
            >
              mdi-close
            </v-icon>
        </v-card-title>
        <hr class="mb-2 mt-3" />
        <v-card-text>
          <v-flex md12 :class="isMobile ? 'pb-3' : 'd-flex pb-3'">
            <v-layout row wrap>
              <v-flex md12 class="my-3">
                <v-text-field
                  v-model.number="count.sum"
                  :label="$t('custom.amount')"
                  hide-no-data
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex
                v-if="!isMobile"
                xs12
                sm12
                md12
                class="d-inline-flex align-center"
              >
                <v-checkbox
                  v-if="company && company.cash_payment"
                  v-model="count.cash"
                  :disabled="count.cashless || count.cash_card || count.entity"
                  class="mt-0 mr-2"
                  dense
                  hide-details
                  :style="
                    count.cashless || count.cash_card || count.entity
                      ? 'opacity: 0.4;'
                      : ''
                  "
                >
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="success" size="30">
                      mdi-cash
                    </v-icon>
                    <span>{{ $t("money.cash") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-if="company && company.cashless_payment"
                  v-model="count.cashless"
                  :label="$t('money.terminal')"
                  :disabled="
                    count.cash ||
                    count.cash_card ||
                    count.entity
                  "
                  class="mt-0 mr-2"
                  dense
                  hide-details
                  :style="
                    count.cash ||
                    count.cash_card ||
                    count.entity
                  "
                  v-tooltip="{
                    content: $t('tooltips.terminal_count'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="warning">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                    <span>{{ $t("money.terminal") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-if="company && company.card_payment"
                  v-model="count.cash_card"
                  :disabled="
                    count.cash ||
                    count.cashless ||
                    count.entity
                  "
                  class="mt-0 mr-2"
                  dense
                  hide-details
                  :style="
                    count.cash ||
                    count.cashless ||
                    count.entity
                  "
                  v-tooltip="{
                    content: $t('money.card_to_card_2'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="info">
                      mdi-credit-card-check-outline
                    </v-icon>
                    <span>{{ $t("money.to_card") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-if="company && company.entity_payment"
                  v-model="count.entity"
                  :label="$t('money.to_entity_short')"
                  class="mt-0 mr-0"
                  dense
                  hide-details
                  :disabled="
                    count.cashless ||
                    count.cash_card ||
                    count.cash
                  "
                  :style="
                    count.cashless ||
                    count.cash_card ||
                    count.cash
                  "
                  v-tooltip="{
                    content: $t('money.from_entity_2'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="secondery">
                      mdi-bank
                    </v-icon>
                    <span>{{ $t("money.to_entity") }}</span>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex
                v-else
                xs12
                sm12
                md12
                class="d-flex align-center justify-space-between"
              >
                <v-flex v-if="company && company.cash_payment">
                  <v-checkbox
                    v-model="count.cash"
                    color="success"
                    :disabled="
                      count.cashless || count.cash_card || count.entity
                    "
                    class="mt-0 mr-0"
                    dense
                    hide-details
                    :style="
                      count.cashless || count.cash_card || count.entity
                        ? 'opacity: 0.4;'
                        : ''
                    "
                  >
                    <template v-slot:label>
                      <v-icon
                        class="text-center mr-2"
                        color="success"
                        size="30"
                        v-tooltip="{
                          content: $t('tooltips.cash_count'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      >
                        mdi-cash
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.cashless_payment">
                  <v-checkbox
                    v-model="count.cashless"
                    color="warning"
                    :disabled="
                      count.cash ||
                      count.cash_card ||
                      count.entity
                    "
                    class="mt-0 mr-0"
                    dense
                    hide-details
                    :style="
                      count.cash ||
                      count.cash_card ||
                      count.entity
                    "
                  >
                    <template v-slot:label>
                      <v-icon
                        class="text-center mr-2"
                        color="warning"
                        v-tooltip="{
                          content: $t('tooltips.terminal_count'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      >
                        mdi-contactless-payment-circle-outline
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.card_payment">
                  <v-checkbox
                    v-model="count.cash_card"
                    color="info"
                    :disabled="
                      count.cash ||
                      count.cashless ||
                      count.entity
                    "
                    class="mt-0 mr-0"
                    dense
                    hide-details
                    :style="
                      count.cash ||
                      count.cashless ||
                      count.entity
                    "
                  >
                    <template v-slot:label>
                      <v-icon
                        class="text-center mr-2"
                        color="info"
                        v-tooltip="{
                          content: $t('money.card_to_card'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      >
                        mdi-credit-card-check-outline
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.entity_payment">
                  <v-checkbox
                    v-model="count.entity"
                    color="secondery"
                    class="mt-0 mr-0"
                    dense
                    hide-details
                    :disabled="
                      count.cashless ||
                      count.cash_card ||
                      count.cash
                    "
                    :style="
                      count.cashless ||
                      count.cash_card ||
                      count.cash
                    "
                    v-tooltip="{
                      content: $t('tooltips.entity_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                  >
                    <template v-slot:label>
                      <v-icon class="text-center" color="secondery">
                        mdi-bank
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-flex>
              <v-flex
                v-if="
                  count.cashless ||
                  count.cash_card ||
                  count.cash ||
                  count.entity
                "
                xs12
                sm12
                md12
                class="text-center"
              >
                {{ $t("money.payment_type") }}:
                <span v-if="count.cash">{{ $t("money.cash") }}</span>
                <span v-if="count.cashless">{{ $t("money.terminal") }}</span>
                <span v-if="count.cash_card">{{
                  $t("money.card_to_card")
                }}</span>
                <span v-if="count.entity">{{ $t("money.to_entity") }}</span>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-textarea
                  v-model="count.description"
                  auto-grow
                  rows="2"
                  :label="$t('counts.count_description')"
                  :placeholder="$t('counts.count_description_plc')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 mr-2" text @click="showCreateCountDialog = false, agent_id = null">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="createCount()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  name: "AgentsList",
  data() {
    return {
      error: "",
      users: [],
      selected: [],
      accounts: [],
      counts: [],
      count: {
        group: "Выплаты агентам",
        operation: false,
        description: null,
        sum: 0,
        cash: false,
        cashless: false,
        cash_card: false,
        entity: false,
        completed: true,
      },
      agent_id: null,
      bookings_counts: [],
      bookings: [],
      editedCount: [],
      cars: [],
      selected_car: [],
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.partners_payments"),
          value: "Выплаты партнёрам",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
      ],
      selected_count: [],
      cashboxes: [],
      user_cashbox: [],
      money_track: false,
      user: {
        account: {
          cash: 0,
        },
      },
      user_cash: 0,
      get_cash: 0,
      give_cash: 0,
      headers: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        {
          text: this.$t("other.last_active"),
          align: "center",
          value: "last_login",
        },
        { text: this.$t("agents.agent"), value: "name", align: "center" },
        { text: this.$t("custom.email"), value: "email", align: "center" },
        { text: this.$t('agents.agents_commission'), sortable: false, align: "center" },
        { text: this.$t("agents.to_payout"), sortable: false, align: "center" },
        { text: this.$t("custom.counts"), sortable: false, align: "center" },
        { text: this.$t("check_lists.control"), sortable: false, align: "center" },
      ],
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      headers_cashboxes: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        { text: this.$t("custom.date"), value: "created_at", align: "center" },
        { text: this.$t("custom.counts"), value: "account", align: "center" },
      ],
      headers_cashier: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("custom.group"),
          sortable: true,
          value: "group",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          sortable: false,
          value: "description",
          align: "center",
        },
        {
          text: this.$t("custom.amount"),
          sortable: false,
          value: "sum",
          align: "center",
        },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("custom.state"), value: "check", align: "center" },
      ],
      countStates: [
        { state: true, text: this.$t("counts.checked") },
        { state: false, text: this.$t("counts.not_approved") },
        { state: null, text: this.$t("counts.not_finded") },
      ],
      bookings_headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.state_short"),
          value: "in_rent",
          align: "center",
        },
        {
          text: this.$t("custom.state_short_2"),
          value: "state",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "last_name", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
      ],
      pagination: { sortBy: "created_at", descending: true },
      search: "",
      calendar_attributes: [],
      search_cashbox: "",
      search_bookings: "",
      disabledGet: false,
      disabledGive: false,
      kassa_dialog: false,
      editDialog: false,
      showNotActive: false,
      showAllUsers: false,
      showBookingsDialog: false,
      showCreateCountDialog: false,
      table_counts_loading: false,
      page: 1,
      per_page: 50,
      sort_by: "id",
      direction: "asc",
      tableOptions: {},
      total: 0,
      timeout: null,
      isLoading: true,
      isMobile: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.user_role == "superadmin" ||
        this.user_role == "admin" ||
        this.user_role == "manager" ||
        this.user_role == "guest")
    ) {
      // this.getDataFromApi();
    } else {
      this.$router.replace("/");
    }
  },
  watch: {
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "id";
      this.direction = pagination.sortDesc[0] ? "desc" : "asc";
      this.getDataFromApi();
    },
    search() {
      if (this.search == "") {
        this.search = null;
      } else {
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getDataFromApi();
          }, 300); // delay
        }
        debounce();
      }
    },
    get_cash() {
      this.user.account.cash = this.user_cash;
      this.user.account.cash -= this.get_cash;
      this.user.account.operation = false;
    },
    give_cash() {
      this.user.account.cash = this.user_cash;
      this.user.account.cash += this.give_cash;
      this.user.account.operation = true;
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    currentUserId() {
      return this.$store.getters.currentUserId;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    employees() {
      if (this.showAllUsers) {
        return this.users;
      } else {
        return this.users.filter(
          (user) => user.role != "partner" && user.role != "guest"
        );
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.axios
        .post("api/v1/agents_data",
        {
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          direction: this.direction,
          search: this.search,
        },
        {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.total = response.data.total_users;
          this.users = [];
          this.accounts = [];
          response.data.users.data.forEach((element) => {
            this.users.push(element.attributes);
          });
          this.users.forEach((user) => {
            this.accounts.push(user.account);
          });

          this.cars = [];
          response.data.cars.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
          this.counts = [];
          response.data.counts.data.forEach((count) => {
            this.counts.push(count.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
        })
        .finally(() => (this.isLoading = false));
    },
    getBookings(agent_id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.isLoading = true;
        this.axios
          .get(`/api/v1/bookings_for_agent/${agent_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.bookings = [];
            response.data.data.forEach((booking) => {
              this.bookings.push(booking.attributes);
            });
            function compare(a, b) {
              if (
                moment(a.end_date, "DD-MM-YYYY H:mm").isBefore(
                  moment(b.end_date, "DD-MM-YYYY H:mm")
                )
              ) {
                return 1;
              }
              if (
                moment(a.end_date, "DD-MM-YYYY H:mm").isSameOrAfter(
                  moment(b.end_date, "DD-MM-YYYY H:mm")
                )
              ) {
                return -1;
              }
              return 0;
            }
            this.bookings = this.bookings.sort(compare);
            function selectHighlight(booking) {
              if (booking.active) {
                return booking.in_rent ? "green" : "blue";
              } else {
                return "gray";
              }
            }

            this.calendar_attributes = [];
            this.bookings.forEach((booking) => {
              if (booking.active || booking.in_rent || booking.ride) {
                self.calendar_attributes.push({
                  highlight: {
                    start: {
                      fillMode: "outline",
                      color: selectHighlight(booking),
                    },
                    base: { fillMode: "light", color: selectHighlight(booking) },
                    end: { fillMode: "outline", color: selectHighlight(booking) },
                  },
                  content: "black",
                  dates: {
                    start: moment(booking.start_date, "DD-MM-YYYY H:mm").toDate(),
                    end: moment(booking.end_date, "DD-MM-YYYY H:mm").toDate(),
                  },
                  popover: {
                    label: `${self.$t("custom.number")}${booking.id} ${
                      booking.start_date
                    } ${booking.location_start} - ${booking.end_date} ${
                      booking.location_end
                    } | ${booking.last_name} ${booking.first_name} ${
                      booking.middle_name
                    }`,
                    visibility: "hover",
                    hideIndicator: true,
                  },
                });
              }
            });
            this.showBookingsDialog = true;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(this.isLoading = false);
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    calculateAgentCommission(agent) {
      let self = this;
      let commission = 0;
      let paid_commission = 0;
      let counts = this.counts.filter(
        (count) => agent.bookings_ids.includes(count.booking_id) || count.agent_id == agent.id
      );
      let total_counts_sum = 0;
      counts.forEach((count) => {
        if(agent.agent_setting.paid_commission_from_total) {
          if(count.group != "Залог" && count.group != "Выплаты агентам") {
            total_counts_sum += count.sum;
          }
        } else {
          if(count.group == "Оплата аренды") {
            total_counts_sum += count.sum;
          }
        }
        // минусуем уже выплаченные агенту комиссии
        if(count.group == 'Выплаты агентам') {
          paid_commission += count.sum;
        }
      });
      if(agent.agent_setting.commission_percent) {
        commission = total_counts_sum * parseFloat(agent.agent_setting.commission_percent) / 100.0;
      }
      if(agent.agent_setting.commission_fix) {
        commission += parseFloat(agent.agent_setting.commission_fix);
      }
      return commission - paid_commission;
    },
    createCount() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let count = this.count;
        let self = this;
        if (
          (count.sum > 0 || count.sum < 0) &&
          (count.cash > 0 ||
            count.cashless > 0 ||
            count.cash_card > 0 ||
            count.entity > 0 ||
            count.cash < 0 ||
            count.cashless < 0 ||
            count.cash_card < 0 ||
            count.entity < 0)
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/counts",
              {
                counts: {
                  group: "Выплаты агентам",
                  operation: false,
                  description: count.description,
                  sum: count.sum,
                  cash: count.cash,
                  cashless: count.cashless,
                  cash_card: count.cash_card,
                  entity: count.entity,
                  completed: true,
                  agent_id: this.agent_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.counts.push(response.data);
              this.showCreateCountDialog = false;
              this.count = {
                group: "Выплаты агентам",
                operation: false,
                description: null,
                sum: 0,
                cash: false,
                cashless: false,
                cash_card: false,
                entity: false,
                completed: true,
              }
              this.agent_id = null;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("counts.add_payment"),
                text: this.$t("counts.add_payment_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.count_add"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.count_add"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkLogin(id) {
      let user = this.$store.getters.getLoginUsers.find(
        (user) => user.id == id
      );
      if (user && user.login == true) {
        return true;
      } else {
        return false;
      }
    },
    getUserAccount(id) {
      return this.accounts.filter((account) => account.user_id == id)[0].cash;
    },
    setUser(id) {
      let self = this;
      this.isLoading = true;
      this.user = this.users.find((user) => user.id === id);
      this.user_cashbox = this.cashboxes.filter(
        (cashbox) => cashbox.user_id == id
      );
      this.user_cash = this.user.account.cash;
      if (this.$store.getters.currentUserId == id) {
        this.money_track = true;
      }
      this.table_counts_loading = true;
      this.axios
        .get(`/api/v1/workers_counts/${this.user.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.counts = [];
          response.data.data.forEach((count) => {
            self.counts.push(count.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cashboxes_worker_load"));
          console.log(error);
        })
        .finally(
          () => ((this.isLoading = false), (this.table_counts_loading = false))
        );
    },
    updateUserKassa() {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        let user = this.user;
        let self = this;
        if (this.get_cash == 0 && this.give_cash == 0) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.cashbox_null"),
            text: this.$t("errors.cashbox_null_text"),
          });
        } else {
          this.isLoading = true;
          // проверка если админ
          let money_track = false;
          if (this.checkAdmin()) {
            money_track = self.money_track;
          }
          this.axios
            .post(
              `/api/v1/manual_update_account`,
              {
                user_id: user.id,
                money_track: money_track,
                sum: self.get_cash + self.give_cash,
                get_cash: self.get_cash > 0 ? true : false,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              // if (!self.money_track) {
              //   if (self.get_cash > 0) {
              //     self.accounts.find(
              //       (account) =>
              //         account.user_id == self.$store.getters.currentUserId
              //     ).cash += self.get_cash;
              //   } else {
              //     self.accounts.find(
              //       (account) =>
              //         account.user_id == self.$store.getters.currentUserId
              //     ).cash -= self.give_cash;
              //   }
              // }
              self.kassa_dialog = false;
              self.user = { account: { cash: 0 } };
              self.user_cash = 0;
              self.get_cash = 0;
              self.give_cash = 0;
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: self.$t("counts.cashbox_updated"),
                text: self.$t("counts.cashbox_updated_text"),
              });
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.workers_account_load"));
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: self.$t("errors.cashbox_update"),
                text: self.error,
              });
            })
            .finally(() => (self.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editCount(count) {
      this.isLoading = true;
      this.editDialog = true;
      this.axios
        .get(`/api/v1/counts/${count.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCount = response.data;
          this.selected_count = response.data;
          this.selected_count.group = this.translateCategory(
            this.selected_count.group
          );
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_counts"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      if (count.car_id) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/cars/${count.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.selected_car = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_cars"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    saveCount() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let count = this.editedCount;
        let self = this;
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                sum: count.sum,
                operation: count.operation,
                description: count.description,
                // проблема в этом при обновлении
                group: this.selected_count.group.value,
                car_id: this.selected_car,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.counts.findIndex(
              (element) => element.id === count.id
            );
            // count.group = response.data.group;
            this.counts.splice(foundIndex, 1, response.data);
            // console.log(count)
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.update_count_title"),
              text: this.$t("counts.update_count_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.update_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateСountCheck(id, state) {
      if (this.user_role != "user" && this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let count = this.counts.find((element) => element.id === id);
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                check: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.counts.findIndex(
              (element) => element.id === id
            );
            count.check = state;
            this.counts.splice(foundIndex, 1, count);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.state_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.count_state"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.count_state"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addUserToTraccar(user_id) {
      this.isLoading = true;
      this.axios
        .post(
          `/api/v1/add_to_traccar`,
          {
            id: user_id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.users.find((user) => user.id == user_id).traccar_id = 999999;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("cars.added_to_traccar"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
        })
        .finally(() => (this.isLoading = false));
    },
    removeUserFromTraccar(user_id) {
      this.isLoading = true;
      this.axios
        .post(
          `/api/v1/remove_from_traccar`,
          {
            id: user_id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.users.find((user) => user.id == user_id).traccar_id = null;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("cars.removed_from_traccar"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
        })
        .finally(() => (this.isLoading = false));
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    formatCreatedAt(date) {
      if (date) {
        return moment.parseZone(date).format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    returnDate(item) {
      if (item) {
        return moment(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    setCar(id) {
      if (id != null) {
        return this.cars.find((car) => car.id == id).code;
      } else {
        return "-";
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    showTodosLink() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkLateDate(date) {
      if (!moment().isSameOrBefore(moment(date, "DD-MM-YYYY H:mm"), "day")) {
        return "color: tomato;";
      }
    },
    shortFormatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD-MM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    replaceToAgent(agent) {
      this.$router.push({ path: `/agent_card/${agent.id}` });
    },
    formatTimeBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("H:mm");
    },
    formatDateBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkGuest() {
      return this.$store.getters.isGuest;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    showUsersLink(user) {
      return (
        (this.$store.getters.isSuperAdmin ||
          this.$store.getters.isAdmin ||
          this.$store.getters.isGuest) &&
        this.$store.getters.currentUserId !== user.id
      );
    },
  },
};
</script>
<style>
  .is-mobile .v-data-footer__select {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .is-mobile .v-data-footer__icons-before {
    margin: 0 auto !important;
  }
  .is-mobile .v-data-footer__icons-after {
    margin: 0 auto !important;
  }
</style>
