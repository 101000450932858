<template>
  <form>
    <div class="image-container mb-3" v-if="previewPath">
      <img :src="previewPath" alt="Uploaded Image Preview" />
    </div>
    <div class="form-group">
      <div ref="dashboardContainer"></div>
    </div>
  </form>
</template>

<script>
import Uppy from "@uppy/core";

import Dashboard from "@uppy/dashboard";
import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";
// import AwsS3Multipart from "@uppy/aws-s3-multipart";
import XHRUpload from "@uppy/xhr-upload";
// import Webcam from '@uppy/webcam';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import '@uppy/webcam/dist/style.min.css';

export default {
  props: {
    maxFileSizeInBytes: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    booking_id: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      previewPath: null,
      disabled: true,
    };
  },
  mounted() {
    this.instantiateUppy();
    console.log(process.env.NODE_ENV)
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    instantiateUppy() {
      let self = this;
      this.uppy = Uppy({
        debug: true,
        locale: this.$i18n.locale === "ru" ? Russian : English,
        autoProceed: true,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 50,
          allowedFileTypes: ["image/*", ".heic", ".heif", "video/*", ".mov"],
        }
      })
        .use(Dashboard, {
          hideUploadButton: true,
          theme: this.theme,
          inline: true,
          width: this.width,
          height: this.height,
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          locale: {
            strings: {
              poweredBy2: "",
              // browseFiles: "выберите файлы"
            },
          }
        })
        // .use(Webcam, { target: Dashboard })
        .use(XHRUpload, {
          endpoint: process.env.NODE_ENV === "development" ? `http://localhost:3000/api/v1/booking_photos/${this.booking_id}/` : `https://rentprog.net/api/v1/booking_photos/${this.booking_id}/`,
          // endpoint: `http://localhost:3000/api/v1/booking_photos/${this.booking_id}/`,
          method: "post",
          formData: true,
          fieldName: "images[]",
          bundle: false,
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        });
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container>img {
  width: inherit;
  height: inherit;
}
</style>
