<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">Как найти нужную бронь?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                При необходимости, например при поступлении звонка от клиента,
                можно легко найти любую бронь по различным параметрам. В списке
                броней в строке поиска в правом верхнем углу начните вводить
                первые буквы фамилии клиента или символы кода автомобиля. По
                мере ввода символов список броней будет сокращаться до того
                момента, когда при полном соответствии введенным символам в
                списке останется только искомая бронь или брони на определенный
                автомобиль или конкретного клиента. Так же можно вывести брони
                на определенную дату, выбрав дату в левом верхнем углу, и
                произвести поиск по дате.
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">Как продлить аренду авто?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                Чтобы продлить срок аренды авто не обязательно редактировать
                бронь. Для продления аренды зайдите в карточку брони и нажмите
                на кнопку «Продление брони». Далее введите количество суток и
                суточную цену аренды. (Удобство заключается в том, что при
                редактировании брони произойдет перерасчет цены, если срок
                аренды перевалил через очередной интервал скидки, а при
                продлении вы можете сами принять решение предоставлять скидку
                или нет). После этого, если клиент уже оплатил продление,
                вводите сумму платежа и выбираете вид оплаты и нажимаете
                «Сохранить». Если оплата еще не произведена, но нужно
                зафиксировать продление, вы можете не вводить сумму платежа,
                выведется предупреждение о продлении без оплаты, нажимаете «Да»
                и бронь будет продлена без оплаты. Кнопка оплаты станет
                оранжевой, сигнализируя о недоплате. Далее, при поступлении
                денег от клиента, можно произвести оплату данной брони по кнопке
                «Оплата».
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">
                Что делать, если автоматический расчет брони неверен?
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                Если сумма автоматического расчета брони отличается по причине
                того, что с клиентом договорились о другой цене (например
                предоставили больше скидку) то расчет брони можно отрегулировать
                путем изменения цены аренды посредством изменения размера
                скидки. Для этого нажмите на ползунок «Изменить скидку» и
                введите размер скидки в соответствующее поле: «Скидка на аренду
                р/сутки».
              </v-card-text>
              <v-card-text>
                Если сумма автоматического расчета отличается по другой причине,
                например, программа считает неполные сутки аренды почасово, а вы
                хотите удержать с клиента оплату за полные сутки, вы можете
                вручную внести любые исправления в автоматический расчет. Это
                делается нажатием на ползунок «Изменить данные вручную». Там же
                можно изменить сумму залога, например увеличить залог, если у
                сотрудника не было сдачи или поставить «0» если не берете залог.
                <br />
                При редактировании данных вручную, так же необходимо отключать
                автоматический расчёт, так как при изменении каких либо данных,
                собьются данные введёные вручную. Это можно сделать путем
                переключения красной кнопки "Рассчитывать автоматически" в
                положение выключено.
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">Как оформить досрочный возврат автомобиля?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                Открываете карточку брони и нажимаете на кнопку «Принять
                автомобиль». Далее вводите дату и время фактического возврата
                машины. После того как вы посчитали суммы платежей, которые
                должны вернуть клиенту, в пункте «Доплаты» выбираете виды услуг,
                за которые необходимо вернуть деньги, например «Аренда» (можно
                выбрать несколько). После этого, если вы должны деньги клиенту –
                вводите отрицательную сумму (со знаком «-») в соответствующее
                поле. Если клиент должен вам (например за мойку) – вводите сумму
                оплаты в обычном порядке. Не забудьте проставить способ оплаты.
                При этом за все услуги не обязательно выбирать одинаковый способ
                оплаты. Например, вы можете вернуть залог наличными, остаток
                арендной платы за досрочный возврат перечислить на карту
                клиента, а за мойку удержать оплату по терминалу. Возможны любые
                сочетания способов оплаты.
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">
                Как учесть передачу денег между сотрудниками?
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                Если есть необходимость передачи денежных средств между
                сотрудниками, то один из них передает нужную сумму другому. При
                этом получатель денег должен зайти в свою кассу и создать новый
                счет. В карточке счета, рядом с названием, переключить ползунок
                «Внутренний» (Что означает перемещение денежных средств внутри
                компании), далее в графу «Сумма» внести полученную сумму и в
                выпадающем списке выбрать имя сотрудника, от которого он принял
                деньги. После нажатия на кнопку «Сохранить» касса принявшего
                сотрудника увеличится на сумму пополнения, а касса сотрудника
                выдавшего деньги автоматически уменьшится на эту же сумму.
                Сотрудник не может сам уменьшить свою кассу, только увеличить,
                приняв деньги от кого-либо.
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">Как пополнить/снять кассу сотрудника?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                При необходимости выдачи денежных средств сотруднику из кассы
                управляющего (владельца) или снятия денег из кассы сотрудника,
                пользователь с правами администратора заходит в меню
                «Сотрудники», нажатием на имя входит в кассу определенного
                сотрудника, в верхней строке открывшегося окна вводит сумму в
                поле «Забрал» или «Положил» соответственно, выбирает кассу из
                которой будет производиться выдача или прием: «Касса компании»
                или «Касса сотрудника», где пункт «Касса сотрудника»
                подразумевает личную кассу администратора, и нажимает кнопку
                «Сохранить».
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="mb-0">Как снять деньги с кассы компании?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                Операции с кассой компании производятся в кассе компании, вход в
                которую осуществляется либо по клику на аватарку в правом
                верхнем углу либо в выпадающем меню слева и выбором пункта
                «Касса компании». Далее необходимо нажать на ползунок «Операции
                с кассой» и появятся пункты доступных операций. Пункт «Внешние»
                означает вывод денежных средств из компании (получение прибыли
                владельцем), пункт «Внутренние» означает перемещение денежных
                средств внутри компании (со счета в кассу или из кассы компании
                в кассу администратора), наличные или безналичные – определяется
                выбором соответствующего пункта, далее – «Сохранить». Например,
                при выводе денег с расчетного счета в кассу компании нужно
                выбрать «Безналичные» и «Внутренние» - деньги спишутся со счета,
                касса компании пополнится.
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      faqsPanel: 0,
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
