<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1">Форма бронирования на сайт</v-card-title>
        <v-card-subtitle class="subtitle-1"
          >Получаем заявки с сайта в систему</v-card-subtitle
        >
        <v-card-text>
          Для начала использования формы бронирования на вашем сайте, необходимо получить специальный технический номер вашей компании (токен). Для этого необходимо обратиться в поддержку.
          После получения номера, необходимо вставить всего несколько строк в код вашего сайта.
        </v-card-text>
        <v-card-text>
          Данный код нужно вставить между тегами <code>&lt;head&gt;</code><br><br>
          <code>&lt;link href="https://rentprog-b5205.web.app/css/app.css" rel="stylesheet"/&gt;</code><br><br>
          Данный код нужно вставить перед закрывающим тегом <code>&lt;/body&gt;</code><br><br>
          <code>&lt;script src="https://rentprog-b5205.web.app/js/app.js"&gt;&lt;/script&gt;</code><br><br>
          Это код непосредственно заявки, его нужно вставить между тегами <code>&lt;body&gt;</code> в нужное вам место
          <br><br>
           <code>
            &lt;rentprog-widget <br>
              &nbsp;&nbsp;token="полученный токен"<br>
              locale="язык"<br>
              &nbsp;&nbsp;show_all_cars='false'<br>
              &nbsp;&nbsp;places='[{"place":"Офис","price":0},{"place":"Доставка по городу","price":300},{"place":"Аэропорт","price":800}]'<br>
              &nbsp;&nbsp;chairs='{"daily": false, "price": 300}'<br>
              &nbsp;&nbsp;boosters='{"daily": true, "price": 100}'<br>
              &nbsp;&nbsp;equipment='[{"item":"Багажник на крышу","price":500, "daily": false},{"item":"Цепи","price":200, "daily": true}]'<br>
              &nbsp;&nbsp;agreement='{"text": ", а так же принимаете ", "link_text": "условия аренды", "link": "https://rentprog.ru/public/agreement.html"}'<br>
              &nbsp;&nbsp;passport_required="false"<br>
              &nbsp;&nbsp;driver_license_required="false"<br>
              &nbsp;&nbsp;birthday_required="false"<br>
              &nbsp;&nbsp;email_required="false"<br>
              &nbsp;&nbsp;phone_required="true"&gt;<br>
              &lt;/rentprog-widget&gt;<br>
            </code>
          <br>
          <p>Вместо <b>"полученный токен"</b> нужно вставить токен выданный поддержкой.</p>
          <p>Вместо <b>"язык"</b> нужно вставить ru - для русского языка, en - для английского.</p>
          <p><b>show_all_cars</b> - показывать все автомобили, по умолчанию показываются только свободные на заданные даты с учетом промежутка между бронями заданного в настройках</p>
          <p><b>passport_required</b> - поля данных паспорта, если стоит "true", поля становятся обязательными для заполнения</p>
          <p><b>driver_license_required</b> - поля данных водительского удостоверения, если стоит "true", поля становятся обязательными для заполнения, если "false" то необязательными</p>
          <p><b>birthday_required</b> - поле даты рождения, если стоит "true", поля становятся обязательными для заполнения, если "false" то необязательными</p>
          <p><b>email_required</b> - поле Email, если стоит "true", поля становятся обязательными для заполнения, если "false" то необязательными</p>
          <p><b>phone_required</b> - поле ввода телефона, если стоит "true", поля становятся обязательными для заполнения, если "false" то необязательными</p>
          <p><b>places</b> - это места выдачи и приёма с указанием цен за доставку/приём</p>
          <p><b>chairs</b> - детские кресла, daily - оплата посуточно или на весь срок аренда, price - цена</p>
          <p><b>boosters</b> - детские кресла без спинки (сидушки), настройки те же</p>
          <p><b>agreement</b> - первая строчка она фиксированная, так как мы собираем персональные данные, если вы хотите дописать ссылку на свои условия, то нужно заполнить данный параметр, если посмотрите на пример по ссылке https://rentprog.ru/public/widget.html то станет понятно как ее настраивать</p>
          <p>Если параметры chairs, boosters, equipment, agreement не нужны, просто удалите их из кода.</p>
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
