<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1"
          >Роли пользователей и их права</v-card-title
        >
        <v-card-subtitle class="subtitle-1"
          >Что может делать пользователь в определенной роли</v-card-subtitle
        >
        <v-simple-table fixed-header height="600px" class="text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Раздел
                </th>
                <th class="text-center">
                  Подраздел
                </th>
                <th class="text-center">
                  Функция
                </th>
                <th class="text-center">
                  Сотрудник
                </th>
                <th class="text-center">
                  Менеджер
                </th>
                <th class="text-center">
                  Управляющий
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">Главная</td>
                <td class="font-italic">Главная</td>
                <td>Состояние авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение мойки</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение заправки</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение местонахождения</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке техсостояния</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Брони</td>
                <td class="font-italic">Активные</td>
                <td>Создание брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение состояния брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Назначение ответственного</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отправка письма клиенту</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продление брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Печать документов</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Прием автомобиля</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение расчета вручную</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение данных о пробеге и т.п.</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Неактивные</td>
                <td>Активация</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата брони</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Архивация</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Клиенты</td>
                <td class="font-italic">Клиенты</td>
                <td>Создание клиента</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование клиента</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Загрузка документов</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение категории</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Долги</td>
                <td>Создание долга</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование долга</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата долга</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление долга</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Штрафы</td>
                <td>Создание штрафа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование штрафа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата штрафа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Поступление денег в оплату от клиента</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление штрафа</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Счета</td>
                <td>Создание счета</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование счета</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Печать счета</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Оплата счета</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление счета</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Календарь</td>
                <td class="font-italic">Календарь</td>
                <td>Режим просмотра</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td>План на день</td>
                <td>Изменение ответственного</td>
                <td>+/-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавление задачи</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование задачи</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление задачи</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Касса</td>
                <td class="font-italic">Касса</td>
                <td>Создание платежа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование платежа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Автомобили</td>
                <td class="font-italic">Автомобили</td>
                <td>Добавить авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактировать авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Активировать авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Деактивировать авто</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продать авто</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменить состояние авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавить ремонт</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Просмотр аналитики по авто</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Создать счет из авто</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Поменять резину</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Обслуживание</td>
                <td class="font-italic">Техобслуживание</td>
                <td>Отметка о проведении</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Документы</td>
                <td>Продление страховки</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Продление диагностической карты</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Список задач</td>
                <td class="font-italic">Все</td>
                <td>Просмотр</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Просмотр личного чек-листа</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавить задачу</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удалить задачу</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td class="font-italic">Личные</td>
                <td>Добавить задачу</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о выполнении</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удалить задачу</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Аналитика</td>
                <td class="font-italic">Аналитика</td>
                <td>Просмотр</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Сотрудники</td>
                <td class="font-italic">Сотрудники</td>
                <td>Просмотр кассы сотрудника</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование операции</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение кассы</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Зарплата</td>
                <td class="font-italic">Зарплата</td>
                <td>Просмотр всех выплат</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Создание выплаты</td>
                <td>+/-</td>
                <td>+/-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о проверке</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Чек-лист</td>
                <td class="font-italic">Чек-лист</td>
                <td>Просмотр</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Добавление задачи</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование задачи</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Удаление задачи</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Отметка о выполнении</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение приоритета</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Профиль</td>
                <td class="font-italic">Личный профиль</td>
                <td>Редактирование</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Расписание</td>
                <td class="font-italic">Расписание</td>
                <td>Просмотр</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Изменение занятости</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование занятости задним числом</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Касса компании</td>
                <td class="font-italic">Касса компании</td>
                <td>Просмотр</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Операции с кассой</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Операции с кассой внешние</td>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Маяки</td>
                <td class="font-italic">Карта</td>
                <td>Просмотр</td>
                <td>+</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td  colspan="6"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Настройки компании</td>
                <td class="font-italic">Профиль компании</td>
                <td>Просмотр</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Редактирование</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Загрузка шаблонов</td>
                <td>-</td>
                <td>+</td>
                <td>+</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
