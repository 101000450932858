<template>
  <!-- <v-card v-if="url" flat tile max-width="600px" > -->
    <v-img
      v-if="url"
      :src="url"
      aspect-ratio="1"
      class="grey lighten-2"
      width="600px"
      height="600px"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  <!-- </v-card> -->
</template>
<script>
export default {
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      url: null,
      dialog: false,
    };
  },
  created() {
    if(this.latitude && this.longitude) {
      this.url = this.getMapUrl(this.latitude, this.longitude)
    }
  },
  computed: {

    },
  methods: {
    getMapUrl(latitude, longitude) {
      // const token = "pk.68acf538c9c973e5fa35020e319e1c3f"
      const token = "pk.10fabbc2878b90c96677e5d4f83a32e6"
      return `https://maps.locationiq.com/v3/staticmap?key=${token}&lat=${latitude}&lon=${longitude}&zoom=17&size=600x600&markers=icon:tiny-blue-cutout%7C${latitude},${longitude}`
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
