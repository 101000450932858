RedactorX.lang['ru'] = {
    "accessibility": {
        "help-label": "Редактор"
    },
    "placeholders": {
        "figcaption": "Type caption (optional)"
    },
    "popup": {
        "back": "Назад",
        "link": "Ссылка",
        "add": "Добавить",
        "image": "Картинка",
        "add-image": "Добавить картинку"
    },
    "shortcuts": {
        "meta-a": "Выделить все",
        "meta-z": "Отменить",
        "meta-shift-z": "Повторить",
        "meta-shift-m": "Удалить строчный формат",
        "meta-b": "Жирный",
        "meta-i": "Курсив",
        "meta-u": "Подчеркнутый",
        "meta-h": "Superscript",
        "meta-l": "Subscript",
        "meta-k": "Ссылка",
        "meta-alt-0": "Обычный текст",
        "meta-alt-1": "Заголовок 1",
        "meta-alt-2": "Заголовок 2",
        "meta-alt-3": "Заголовок 3",
        "meta-alt-4": "Заголовок 4",
        "meta-alt-5": "Заголовок 5",
        "meta-alt-6": "Заголовок 6",
        "meta-shift-7": "Нумерованный список",
        "meta-shift-8": "Маркированный список",
        "meta-indent": "Отступ",
        "meta-outdent": "Выступ",
        "meta-shift-backspace": "Удалить",
        "meta-shift-o": "Добавить",
        "meta-shift-d": "Скопировать",
        "meta-shift-up": "Вверх",
        "meta-shift-down": "Вниз"
    },
    "buttons": {
        "add": "Добавить",
        "html": "HTML",
        "format": "Формат",
        "bold": "Жирный",
        "italic": "Курсив",
        "deleted": "Зачеркнутый",
        "link": "Ссылка",
        "list": "Список",
        "image": "Картинка",
        "indent": "Отступ",
        "outdent": "Выступ",
        "embed": "Embed",
        "table": "Таблица",
        "insert": "Вставить",
        "save": "Сохранить",
        "cancel": "Отмена",
        "delete": "Удалить",
        "duplicate": "Копировать",
        "shortcut": "Клавиши",
        "underline": "Подчеркнутый",
        "undo": "Отменить",
        "redo": "Повторить",
        "code": "Код",
        "mark": "Маркировать",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "kbd": "Shortcut",
        "image-settings": "Настройки картинки",
        "column-settings": "Настройки колонки",
    },
    "blocks": {
        "text": "Текст",
        "paragraph": "Параграф",
        "image": "Картинка",
        "embed": "Embed",
        "line": "Линия",
        "table": "Таблица",
        "quote": "Цитата",
        "pre": "Код",
        "address": "Адрес",
    },
    "format": {
        "p": "Обычный текст",
        "h1": "Заголовок 1",
        "h2": "Заголовок 2",
        "h3": "Заголовок 3",
        "h4": "Заголовок 4",
        "h5": "Заголовок 5",
        "h6": "Заголовок 6",
        "address": "Адрес",
        "ul": "Маркированный список",
        "ol": "Нумерованный список",
        "dl": "Definition List"
    },
    "embed": {
        "embed": "Embed",
        "caption": "Подпись",
        "description": "Paste any embed/html code or enter the url (vimeo or youtube video only)",
        "responsive-video": "Responsive video"
    },
    "image": {
        "or": "или",
        "alt-text": "Alt Text",
        "link": "Ссылка",
        "width": "Ширина",
        "caption": "Подпись",
        "link-in-new-tab": "Открыть ссылку в новой вкладке",
        "url-placeholder": "Вставьте URL изображения или в base64 формате...",
        "upload-new-placeholder": "Перетащите, чтобы загрузить новое изображение<br>или нажмите, чтобы выбрать"
    },
    "link": {
        "link": "Ссылка",
        "edit-link": "Редактировать ссылку",
        "unlink": "Удалить ссылку",
        "link-in-new-tab": "Открыть ссылку в новой вкладке",
        "text": "Текст",
        "url": "URL"
    },
    "table": {
        "width": "Ширина",
        "nowrap": "Непрерывное перенос",
        "column": "Колонка",
        "add-head": "Добавить заголовок",
        "remove-head": "Удалить заголовок",
        "add-row-below": "Добавить строку ниже",
        "add-row-above": "Добавить строку выше",
        "remove-row": "Удалить строку",
        "add-column-after": "Добавить колонку справа",
        "add-column-before": "Добавить колонку слева",
        "remove-column": "Удалить колонку",
        "delete-table": "Удалить таблицу"
    }
};