<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar
        v-if="!isMobile"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
      >
        <h2 class="mx-2">{{ $t("salary.salary") }}</h2>
        <v-switch
          v-if="checkAdmin()"
          v-model="show_all_salaries"
          :label="$t('salary.all_salary')"
          class="align-center"
          outlined
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select
          v-model="selected_workers"
          clearable
          :items="workers"
          item-text="name"
          item-value="id"
          multiple
          :label="$t('salary.recipients')"
          class="mt-5"
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn
          v-if="checkAdminManager()"
          small
          color="success"
          @click="showNewSalary = true"
          >{{ $t("salary.add") }}
          <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        v-else
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        height="180px"
        class="mb-2"
      >
        <v-flex md12>
          <v-flex md12>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-select
              v-model="selected_workers"
              clearable
              :items="workers"
              item-text="name"
              item-value="id"
              multiple
              :label="$t('salary.recipients')"
              class="mt-5"
            ></v-select>
          </v-flex>
          <v-flex md12 class="d-flex justify-space-between">
            <v-btn
              v-if="checkAdminManager()"
              small
              color="success"
              @click="showNewSalary = true"
              >{{ $t("salary.add") }}
              <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
            </v-btn>
            <v-switch
              v-if="checkAdmin()"
              v-model="show_all_salaries"
              :label="$t('salary.all_salary')"
              class="align-center"
              outlined
              hide-details
            ></v-switch>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="filtered_salaries"
        :search="search"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :items-per-page="20"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        :dense="isMobile"
        mobile-breakpoint="100"
        :class="!isMobile ? '' : 'is-mobile'"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-center">{{ props.item.id }}</td>
            <td class="text-center">{{ props.item.count_id }}</td>
            <td class="text-center" nowrap>
              {{ formatCreatedAt(props.item.created_at) }}
            </td>
            <td class="text-center">
              {{ translateCategory(props.item.category) }}
            </td>
            <td class="text-center description-trunc">
              <read-more
                v-if="
                  props.item.description && props.item.description.length > 0
                "
                :more-str="$t('custom.read')"
                :text="props.item.description"
                link="#"
                :less-str="$t('custom.less')"
                :max-chars="20"
              ></read-more>
            </td>
            <td class="text-center" nowrap>{{ props.item.period }}</td>
            <td class="text-center">
              {{ setUser(props.item.worker_id) }}
            </td>
            <td class="text-center">
              {{ setUser(props.item.user_id) }}
            </td>
            <td class="text-center">{{ props.item.work_days }}</td>
            <td class="text-center">{{ props.item.sum }}{{ currency }}</td>
            <td class="text-center">
              <v-menu offset-y v-if="props.item.check == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    v-tooltip="{
                      content: $t('tooltips.some_invoice'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                  >
                    mdi-map-marker-question-outline
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in salaryStates"
                    :key="index"
                    @click="updateSalaryCheck(props.item.id, state.state)"
                  >
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.check == true">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    v-tooltip="{
                      content: $t('tooltips.trust_invoice'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >
                    mdi-checkbox-outline
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in salaryStates"
                    :key="index"
                    @click="updateSalaryCheck(props.item.id, state.state)"
                  >
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.check == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    v-tooltip="{
                      content: $t('tooltips.bad_invoice'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                  >
                    mdi-bookmark-remove-outline
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in salaryStates"
                    :key="index"
                    @click="updateSalaryCheck(props.item.id, state.state)"
                  >
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
      <v-flex class="mx-2 mb-2" md12>
        {{ $t("salary.for_month") }}: {{ workerSalary() }}{{ currency }}.
        {{ $t("salary.for_past") }}: {{ workerSalaryPast() }}{{ currency }}.
      </v-flex>
    </v-card>
    <v-dialog
      v-model="showNewSalary"
      :retain-focus="false"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("salary.payment") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm1 md1>
                    <v-text-field
                      v-model.number="salary.sum"
                      :label="$t('custom.amount')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-autocomplete
                      v-model="salary.category"
                      item-text="text"
                      item-value="value"
                      :items="salary_categories"
                      :label="$t('salary.payment_category')"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex v-if="checkAdmin()" xs12 md3>
                    <v-autocomplete
                      v-model="salary.worker_id"
                      item-text="name"
                      item-value="id"
                      :items="workers"
                      :label="$t('salary.select_worker')"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex v-else xs12 md3>
                    <v-autocomplete
                      disabled
                      :value="
                        $store.getters.getCurrentUser.name
                          ? $store.getters.getCurrentUser.name
                          : $store.getters.getCurrentUser.email
                      "
                      :label="$t('salary.select_worker')"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="period"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="salary.period"
                          :label="$t('salary.payment_period')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="period"
                        :locale="$i18n.locale"
                        range
                        no-title
                        scrollable
                        first-day-of-week="1"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          {{ $t("custom.decline") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(period)"
                        >
                          {{ $t("custom.ok") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="salary.work_days"
                      :label="$t('salary.work_days')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    sm12
                    md12
                    :class="isMobile ? '' : 'd-inline-flex'"
                  >
                    <v-switch
                      v-model="salary.cash"
                      :label="$t('money.cash')"
                      :disabled="
                        salary.cashless || salary.cash_card || salary.entity
                      "
                      class="mr-2"
                    ></v-switch>
                    <v-switch
                      v-model="salary.cashless"
                      :label="$t('money.terminal_short')"
                      :disabled="
                        salary.cash || salary.cash_card || salary.entity
                      "
                      class="mr-2"
                    ></v-switch>
                    <v-switch
                      v-model="salary.cash_card"
                      :label="$t('money.card_to_card')"
                      :disabled="
                        salary.cash || salary.cashless || salary.entity
                      "
                      class="mr-2"
                    ></v-switch>
                    <v-switch
                      v-model="salary.entity"
                      :label="$t('money.to_entity_short')"
                      :disabled="
                        salary.cashless || salary.cash_card || salary.cash
                      "
                    ></v-switch>
                  </v-flex>
                  <v-flex md12>
                    <v-text-field
                      v-model.number="salary.description"
                      :label="$t('custom.description')"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (showNewSalary = false),
                (salary = {
                  sum: 0,
                  worker_id: null,
                  user_id: null,
                  period: null,
                  work_days: 0,
                  category: 'Ставка',
                  description: '',
                })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveSalary()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      salaries: [],
      filtered_salaries: [],
      salary: {
        sum: 0,
        worker_id: null,
        user_id: null,
        period: null,
        work_days: 0,
        category: { text: this.$t("salary_categories.rate"), value: "Ставка" },
        description: "",
        cash: false,
        cash_card: false,
        cashless: false,
        entity: false,
      },
      workers: [],
      selected_workers: [],
      period: null,
      salary_categories: [
        { text: this.$t("salary_categories.rate"), value: "Ставка" },
        { text: this.$t("salary_categories.hourly"), value: "Почасовая" },
        { text: this.$t("salary_categories.extra"), value: "Доплата" },
        { text: this.$t("salary_categories.reward"), value: "Премия" },
        { text: this.$t("salary_categories.holiday"), value: "Отпускные" },
      ],
      show_all_salaries: false,
      search: "",
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("salary.pay"),
          sortable: false,
          value: "count_id",
          align: "center",
        },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("salary.category"),
          value: "category",
          align: "center",
        },
        {
          text: this.$t("salary.descriprion"),
          value: "descriprion",
          align: "center",
        },
        { text: this.$t("salary.period"), sortable: false, align: "center" },
        {
          text: this.$t("salary.recipient"),
          value: "worker_id",
          align: "center",
        },
        { text: this.$t("salary.payer"), value: "user_id", align: "center" },
        { text: this.$t("salary.work_days"), sortable: false, align: "center" },
        {
          text: this.$t("custom.amount"),
          sortable: false,
          value: "sum",
          align: "center",
        },
        { text: this.$t("salary.state"), value: "check", align: "center" },
      ],
      salaryStates: [
        { state: true, text: this.$t("counts.checked") },
        { state: false, text: this.$t("counts.not_approved") },
        { state: null, text: this.$t("counts.not_finded") },
      ],
      pagination: { sortBy: "created_at", descending: true },
      showNewSalary: false,
      menu: false,
      isLoading: true,
      isMobile: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/salaries.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.salaries = response.data;
          this.filtered_salaries = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.salaries_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/get_workers_names_with_ids`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.employers_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    period() {
      if (this.period && this.period.length > 1) {
        this.salary.period =
          moment(this.period[0], "YYYY-MM-DD").format("DD-MM-YYYY") +
          " - " +
          moment(this.period[1], "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    },
    show_all_salaries() {
      if (this.show_all_salaries) {
        this.getAllSalaries();
      } else {
        this.isLoading = true;
        this.axios
          .get("/api/v1/salaries", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.salaries = response.data;
            this.filtered_salaries = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.salaries_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    selected_workers() {
      if (this.selected_workers && this.selected_workers.length > 0) {
        this.filtered_salaries = this.salaries.filter((salary) => {
          return this.selected_workers.includes(salary.worker_id);
        });
      } else {
        this.filtered_salaries = this.salaries;
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    saveSalary() {
       if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.salary.sum > 0 &&
          (this.salary.cash ||
            this.salary.cash_card ||
            this.salary.cashless ||
            this.salary.entity)
        ) {
          this.isLoading = true;
          let self = this;
          this.axios
            .post(
              `/api/v1/salaries`,
              {
                salaries: {
                  sum: self.salary.sum,
                  worker_id:
                    this.checkAdmin() && this.salary.worker_id
                      ? this.salary.worker_id
                      : this.$store.getters.currentUserId,
                  period: self.salary.period,
                  work_days: self.salary.work_days,
                  category: self.salary.category,
                  description: self.salary.description,
                  cash: self.salary.cash,
                  cashless: self.salary.cashless,
                  cash_card: self.salary.cash_card,
                  entity: self.salary.entity,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.salaries.unshift(response.data);
              this.showNewSalary = false;
              this.salary = {
                sum: 0,
                worker_id: null,
                user_id: null,
                period: null,
                work_days: 0,
                category: "Ставка",
                description: "",
                cash: false,
                cash_card: false,
                cashless: false,
                entity: false,
              };
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("salary.created"),
                text: this.$t("salary.created_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.salaries_create"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.salaries_create"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.required_fields"),
            text: this.$t("salary.created_warn"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getAllSalaries() {
      let self = this;
      if (this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin) {
        this.isLoading = true;
        this.axios
          .get("/api/v1/all_salaries", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.salaries = response.data;
            this.filtered_salaries = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.salaries_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
          text: this.$t("errors.admins_only_3"),
        });
      }
    },
    updateSalaryCheck(id, state) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.isLoading = true;
        let self = this;
        let salary = this.salaries.find((element) => element.id === id);
        this.axios
          .patch(
            `/api/v1/salaries/${salary.id}`,
            {
              salaries: {
                check: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.salaries.findIndex(
              (element) => element.id === id
            );
            salary.check = state;
            this.salaries.splice(foundIndex, 1, salary);

            let foundIndex2 = this.filtered_salaries.findIndex(
              (element) => element.id === id
            );
            this.filtered_salaries.splice(foundIndex2, 1, salary);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.state_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.count_state_update"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.count_state_update"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setUser(id) {
      if (id != null) {
        if (this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    workerSalary() {
      let salary_rub = 0;
      let start = moment().startOf("month");

      this.filtered_salaries.forEach((salary) => {
        if (
          salary.worker_id == this.$store.getters.getCurrentUser.id &&
          start.isSameOrBefore(moment(salary.created_at))
        ) {
          salary_rub += salary.sum;
        }
      });
      return salary_rub;
    },
    workerSalaryPast() {
      let salary_rub = 0;
      let start = moment().startOf("month");
      let end = moment().startOf("month").subtract(1, "months");

      this.filtered_salaries.forEach((salary) => {
        if (
          salary.worker_id == this.$store.getters.getCurrentUser.id &&
          moment(salary.created_at).isBetween(end, start)
        ) {
          salary_rub += salary.sum;
        }
      });
      return salary_rub;
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Ставка":
          group_text = this.$t("salary_categories.rate");
          break;
        case "Почасовая":
          group_text = this.$t("salary_categories.hourly");
          break;
        case "Доплата":
          group_text = this.$t("salary_categories.extra");
          break;
        case "Премия":
          group_text = this.$t("salary_categories.reward");
          break;
        case "Отпускные":
          group_text = this.$t("salary_categories.holiday");
          break;
      }
      return group_text;
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
