import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify, {
  iconfont: 'md'
})

export default new Vuetify({
  theme: {
    dark: false
  },
  lang: {
    locales: { ru, en },
    current: 'ru'
  }
})
