<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="heading-2"
          >Добавил автомобиль, а он не появился в списке свободных и доступных
          для бронирования
        </v-card-title>
        <v-card-text>
          Для того, чтобы добавленный в базу автомобиль стал доступным для
          бронирования его необходимо активировать. Для активации автомобиля
          нужно заполнить цены в карточке автомобиля, вкладка «Цены» и нажать на
          ползунок «Активная» в правом верхнем углу карточки автомобиля.
        </v-card-text>
        <v-card-title class="heading-2"
          >Разбор ситуации возврата машины при неполной оплате брони
        </v-card-title>
        <v-card-text>
          Бывают ситуации, когда клиент хочет произвести оплату некоторых услуг
          (например платы за возврат авто в аэропорту или за дополнительное
          время, сверх полных суток аренды) в конце аренды, при сдаче
          автомобиля. При этом, если о необходимости этих услуг стало известно
          до начала аренды, они будут учтены при расчете брони. Соответственно,
          если клиент не оплатит соответствующие суммы при выдаче авто,
          возникнет недоплата по брони, и кнопка оплаты будет гореть оранжевым
          цветом, сигнализируя недоплату по брони. Сумму недоплаты можно
          удержать из залога, при возврате. Но, если вы учтете поступление
          данных сумм как доплаты непосредственно в форме возврата автомобиля,
          произойдет их повторное начисление, как доплаты при возврате, и кнопка
          оплаты останется оранжевой, хотя клиент полностью рассчитается и не
          останется должником. Чтобы этого не произошло, перед приемом
          автомобиля необходимо вначале произвести оплату недоплаченных при
          выдаче сумм, а потом уже оформлять прием автомобиля.
        </v-card-text>
        <v-card-text>
          Разберем на примере. Клиент оформил бронь на 2 суток с доставкой и
          возвратом авто в аэропорту. Программой произведен расчет брони 2 суток
          х 1000р. + 500р. доставка + 500р. возврат в аэропорту. Итого 3000р.
          аренда + 3000р. залог, всего 6000р. При выдаче машины клиент попросил
          произвести доплату за возврат непосредственно при возврате, поэтому
          сотрудник взял с клиента и провел платеж на 5500р. Возникла недоплата
          по брони на сумму платы за возврат в аэропорту – 500р. Поэтому кнопка
          оплаты брони стала оранжевой, сигнализируя недоплату. Благодаря этому
          сотрудник, который будет принимать машину, увидит, что у клиента есть
          задолженность по оплате брони. Накануне, клиент оповестил, что сдаст
          машину на 2 часа позже установленного времени. Сотрудник, который
          принимает машину, удерживает с клиента 500р. долг за возврат в
          аэропорту + 200р. за дополнительное время, всего 700р., и возвращает
          ему 2300р. – остаток залога. Все эти суммы сотрудник может провести
          непосредственно в форме приема машины, но тогда, как было сказано
          выше, кнопка останется оранжевой, т.к произойдет повторное
          доначисление платы за возврат. Поэтому, вначале нужно провести в
          программе оплату долга в сумме 500р., а потом оформить прием машины, с
          доплатой 200р. за дополнительное время и возвратом залога.
        </v-card-text>
        <v-card-title class="heading-2"
          >Сотрудник ошибся при вводе суммы платежа в расходном счете или при
          оплате авто
        </v-card-title>
        <v-card-text>
          Любое поступление или расход денег из кассы сотрудника (кассы
          компании), проведенное соответствующим платежом в программе, сразу
          приводит к соответствующему изменению кассы сотрудника (компании).
          Если при оплате аренды или при создании расходного счета в кассе, была
          неверно введена сумма платежа, исправить сумму в ошибочной операции
          невозможно. Сделано это для избежания манипуляций с кассой и возможных
          злоупотреблений. Для исправления ошибочной операции необходимо
          провести повторную операцию по тому же самому виду оплаты, но с
          противоположным математическим знаком.
        </v-card-text>
        <v-card-subtitle class="subtitle-1">
          Примеры:
        </v-card-subtitle>
        <v-card-text>
          <ul>
            <li>
              Сотрудник при оплате брони ошибочно удержал арендную плату с
              клиента 6000р. вместо 5000р. Для исправления он должен провести
              повторную операцию по тому же виду платежа «Арендная плата», с тем
              же способом оплаты (например «Наличные») на сумму «-1000р.»
            </li>
            <li>
              Сотрудник при оплате брони удержал арендную плату с клиента 5000р.
              и ошибочно провел ее способом оплаты «Наличными» вместо способа
              оплаты «По терминалу». Для исправления он должен провести еще одну
              операцию по способу оплаты «Наличными» на сумму «-5000р.», (таким
              образом оплата аннулируется) далее провести верную операцию с
              выбором способа оплаты «По терминалу».
            </li>
            <li>
              Сотрудник провел оплату ремонта и ошибочно ввел сумму 6000р.
              вместо 5000р. – он должен провести повторную операцию с видом
              расхода «Ремонт» на сумму «-1000р.» с выбором того же способа
              оплаты.
            </li>
          </ul>
        </v-card-text>
        <v-card-title class="heading-2"
          >Сотрудник ошибся при выборе вида расходов (автомобиля) в счете
        </v-card-title>
        <v-card-text>
          Если сотрудник в расходном счете ошибочно выбрал не тот вид расхода
          или не тот автомобиль, он может обратиться к администратору и тот,
          нажав на кнопку редактирования, может внести исправления в
          соответствующие поля счета.
        </v-card-text>
        <v-card-title class="heading-2"
          >Сотрудник ошибся при вводе пробега (бензина, мойки)
        </v-card-title>
        <v-card-text>
          Если сотрудник, при выдаче автомобиля, неверно указал пробег
          (количество бензина в баке или мытый или немытый автомобиль) он может
          обратиться к администратору для исправления. Администратор должен
          зайти в соответствующую бронь, нажать на кнопку редактирования брони,
          внизу окна редактирования нажать на ползунок «Изменить данные вручную»
          и внести изменения в соответствующие поля. Если неверный ввод пробега
          выяснился после приема автомобиля, необходимо таким же образом
          исправить введенный пробег, а так же вручную пересчитать и исправить
          поле «Пробег за бронь».
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
