<template>
  <v-container v-resize="checkMobile">
    <v-overlay v-if="!isLoading && userOnFreeTariff">
      <v-alert
        color="info"
        border="top"
        class="mx-2"
      >
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1" :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card :class="$vuetify.theme.dark ? 'dark' : ''">
      <v-toolbar v-if="!isMobile" text :color="$vuetify.theme.dark ? '' : 'white'" class="">
        <v-btn small text class="ml-2 mt-2" @click="goBack">{{
                  $t("custom.goback")
                  }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="newTemplate()" v-if="!showNewtemplate">+ {{ $t("templates.new") }}</v-btn>
        <v-btn small color="primary" @click="showNewtemplate = false" v-else>- {{ $t("templates.select") }}</v-btn>
        <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
                  }/${$root.$i18n.locale}/categories/3/guides/12`" target="_blank" class="ml-2">
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap class="mt-1">
            <v-flex md12 class="mb-2" v-if="showNewtemplate">
              <v-select v-model="template_type" item-text="name" item-value="value" :items="template_types"
                @change="redactorx_key += 1" hide-details :label="$t('templates.select_type')"></v-select>
              <v-text-field v-model="name" type="text" :label="$t('templates.name')"></v-text-field>
              <v-autocomplete v-model="car_id" item-text="code" item-value="id" :loading="isLoadingCars"
                :search-input.sync="searchCar" auto-select-first text hide-no-data hide-details
                :label="$t('templates.to_car')" :items="cars">
              </v-autocomplete>
            </v-flex>
            <v-flex md12 v-if="showNewtemplate" class="text-center mb-2">
              <v-btn small class="mr-1 mt-2" color="success" @click="saveContent()">{{ $t("custom.save") }}</v-btn>
            </v-flex>
            <v-flex md12 class="mb-2" v-if="!showNewtemplate">
              <v-autocomplete v-model="template" item-text="name" item-value="id" :items="templates"
                @change="redactorx_key += 1" hide-details :label="$t('templates.select_2')"></v-autocomplete>
              <v-text-field v-model="name" type="text" :label="$t('templates.name')"></v-text-field>
              <v-autocomplete v-model="car_id" item-text="code" item-value="id" :loading="isLoadingCars"
                :search-input.sync="searchCar" auto-select-first text hide-no-data hide-details
                :label="$t('templates.to_car')" :items="cars">
              </v-autocomplete>
              <v-flex md12 class="text-center">
                <v-btn small v-if="template != null && content" class="mr-1 mt-2" color="success"
                  @click="updateContent()">{{ $t("custom.save") }}</v-btn>
                <v-btn small v-if="template" color="error" class="mt-2" @click="deleteTemplate()">{{ $t("custom.delete")
                                  }}</v-btn>
              </v-flex>
            </v-flex>
            <v-flex :key="redactorx_key" md12 v-if="!showCustomHtml" style="width: 210mm; margin: 0 auto">
              <p class="text-center" v-html="$t('templates.word_warn_html')"></p>
              <Redactorx v-if="content" v-model="content" :config="configRedactorXOptions"></Redactorx>
              <!-- <wysiwyg style="width: 210mm; margin: 0 auto;" v-model="content" /> -->
            </v-flex>
            <v-flex md12 class="mt-2 text-center" v-if="template == null && content && !showCustomHtml">
              <v-btn color="success" @click="saveContent()">{{
                              $t("custom.save")
                              }}</v-btn>
            </v-flex>
            <v-flex md12 class="mt-2 text-center" v-if="template != null && content && !showCustomHtml">
              <v-btn color="success" @click="updateContent()">{{
                              $t("custom.save")
                              }}</v-btn>
            </v-flex>
            <!-- <v-flex md12>
              <v-switch
                v-model="showCustomHtml"
                class="mt-0 mr-1 mr-1"
                label="HTML"
                dense
                hide-details
              ></v-switch>
              <v-textarea
                v-if="showCustomHtml"
                v-model="content"
                auto-grow
                filled
                label="HTML"
              ></v-textarea>
            </v-flex> -->
            <v-flex md12 class="mt-2 text-center" v-if="template == null && content && showCustomHtml">
              <v-btn color="success" @click="saveContent()">{{
                              $t("custom.save")
                              }}</v-btn>
            </v-flex>
            <v-flex md12 class="mt-2 text-center" v-if="template != null && content && showCustomHtml">
              <v-btn color="success" @click="updateContent()">{{
                              $t("custom.save")
                              }}</v-btn>
            </v-flex>
            <!-- <v-flex md12>
              <v-btn @click="generateReport()">Генерировать</v-btn>
            </v-flex> -->
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
// import html2pdf from 'html2pdf.js'
export default {
  data() {
    return {
      content: null,
      template_type: "booking_start",
      template_types: [
        { name: this.$t("sign.start_booking"), value: "booking_start" },
        { name: this.$t("sign.end_booking"), value: "booking_end" },
        { name: this.$t("sign.prolong"), value: "prolong" },
        { name: this.$t("templates.invoices"), value: "invoice" },
        { name: this.$t("templates.act"), value: "act" },
      ],
      templates: [],
      template: null,
      name: null,
      redactorx_key: 1,
      configRedactorXOptions: {
        embed: false,
        control: true,
        context: true,
        toolbar: {
          stickyTopOffset: 65,
        },
        editor: {
          lang: this.$i18n.locale == "ru" ? "ru" : "en",
        },
        plugins: ["alignment", "imageposition", "imageresize", "removeformat"],
        image: {
          upload: process.env.NODE_ENV === "development" ? "http://localhost:3000/email_templates_image_upload" : "https://rentprog.net/email_templates_image_upload",
          multiple: false,
          data: {
            user_id: this.$store.getters.getCurrentUser.id,
          },
        },
      },
      cars: [{ code: this.$t("templates.no_car"), id: null }],
      car_id: null,
      showNewtemplate: false,
      showCustomHtml: false,
      isLoadingCars: false,
      searchCar: null,
      isMobile: false,
      isLoading: false,
      isLoadingSubscribeToPaidTariff: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/templates`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.templates = response.data;
          if (this.templates.length > 0) {
            this.content = this.templates[0].content;
            // reload redactorx

            this.name = this.templates[0].name;
            this.car_id = this.templates[0].car_id;
            this.template = this.templates[0].id;
          }
          this.axios
            .get("/api/v1/cars", {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              response.data.data.forEach((element) => {
                this.cars.push(element.attributes);
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.cars_load"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.templates"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.templates"),
            text: this.error,
          });
        })
        .finally((this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => { });
    }
  },
  watch: {
    template() {
      if (this.template) {
        let finded = this.templates.find((tmpl) => {
          return tmpl.id == this.template;
        });
        if (finded) {
          this.content = finded.content;
          this.$forceUpdate();
          this.name = finded.name;
          this.car_id = finded.car_id;
        }
      }
    },
    searchCar(val) {
      // Items have already been loaded
      if (this.cars.length > 1) return;

      // Items have already been requested
      if (this.isLoadingCars) return;

      this.isLoadingCars = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingCars = false));
    },
  },
  computed: {
    userOnFreeTariff() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany.tariff == "Старт" && moment(this.$store.getters.getMainCompany.created_at).isBefore(moment().subtract(7, "day")) ? true : false;
      } else {
        return false;
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    saveContent() {
      if (
        this.name &&
        this.name.length > 3 &&
        this.user_role != "guest" &&
        this.user_role != "partner"
      ) {
        let self = this;
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/templates",
            {
              templates: {
                name: this.name,
                content: this.content,
                template_type: this.template_type,
                car_id: this.car_id && this.car_id != 0 ? this.car_id : null,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.templates.push(response.data);
            this.showNewtemplate = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("templates.created"),
            });
          })
          .catch((error) => {
            self.setError(error, self.$t("templates.template_save"));
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: self.$t("templates.template_save"),
              text: self.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          timer: 3000,
          icon: "error",
          title: this.$t("templates.template_save"),
          text: this.$t("templates.name_to_short"),
        });
      }
    },
    updateContent() {
      if (
        this.name &&
        this.name.length > 3 &&
        this.user_role != "guest" &&
        this.user_role != "partner"
      ) {
        let self = this;
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/templates/${this.template}`,
            {
              templates: {
                name: this.name,
                content: this.content,
                template_type: this.template_type,
                car_id: this.car_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.templates.findIndex(
              (element) => element.id === response.data.id
            );
            this.templates.splice(foundIndex, 1, response.data);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("templates.updated"),
            });
          })
          .catch((error) => {
            self.setError(error, self.$t("errors.template_update"));
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: self.$t("errors.template_update"),
              text: self.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          timer: 3000,
          icon: "error",
          title: this.$t("errors.template_save"),
          text: this.$t("templates.name_to_short"),
        });
      }
    },
    newTemplate() {
      this.template = null;
      this.showNewtemplate = true;
      this.content = this.$t("templates.initial");
    },
    deleteTemplate() {
      let id = this.template;
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/templates/${id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.templates.splice(
                  this.templates.findIndex((template) => {
                    return template.id == id;
                  }),
                  1
                );
                if (this.templates.length > 0) {
                  this.content = this.templates[0].content;
                  this.template = this.templates[0].id;
                }
              })
              .catch((error) =>
                this.setError(error, this.$t("templates.template_delete"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("templates.deleted"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    generateReport() {
      let opt = {
        margin: 1,
        filename: "document.pdf",
        image: { type: "png", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          userPermissions: ["print", "copy"],
        },
      };
      html2pdf()
        .set(opt)
        .from(this.content)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.isMobile = false;
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    subscribeToPaidTariff() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("custom.to_subscribe_to_paid_tariff_warning"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value && this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.id) {
          this.isLoadingSubscribeToPaidTariff = true;
          this.axios
            .post(`/api/v1/subscribe_to_paid_v2`,
              {
                main_company_id: self.$store.getters.getMainCompany.id,
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.success"),
              });
              let main_company = self.$store.getters.getMainCompany;
              main_company.tariff = "Стандарт";
              self.$store.commit("setMainCompany", main_company);
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.error"))
            )
            .finally(() => (this.isLoadingSubscribeToPaidTariff = false));
        }
      });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.isLoading = false;
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    // TiptapVuetify
  },
};
</script>
<style>
.dark .rx-content {
  color: #000;
}
</style>
