<template>
  <v-container v-resize="checkMobile" grid-list-md text-xs-center>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-card>
      <v-card-title>
        <h2 class="title">{{ $t('partners.partners') }}</h2>
        <v-spacer></v-spacer>
        <v-btn small color="success" :to="{ name: 'NewInvestor' }"
          >{{ $t('partners.add') }}</v-btn
        >
        <v-btn small class="mx-2" :to="{ name: 'InvestorsArchive' }"
          >{{ $t('custom.archive') }}</v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-data-table
            :headers="headers"
            :items="investors"
            :search="search"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="20"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1]
            }"
          >
            <template slot="header" slot-scope="props">
              <tr id="registration-step-64">
                <th v-for="header in props.headers" :key="header.text" nowrap>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template slot="item" slot-scope="props">
              <tr
                @dblclick="replaceToInvestor(props.item)"
                style="cursor: pointer;"
              >
                <td class="text-center" :key="props.item.id">
                  <router-link :to="{ path: `/investors/${props.item.id}` }">{{
                    props.item.id
                  }}</router-link>
                </td>
                <td class="text-center" nowrap>
                  <router-link :to="{ path: `/investors/${props.item.id}` }">{{
                    props.item.name
                  }}</router-link>
                </td>
                <td class="text-center">
                  {{props.item.percent}}
                </td>
                <td class="text-center">
                  {{translateMode(props.item.mode)}}
                </td>
                <td class="text-center">
                  {{props.item.description}}
                </td>
                <td class="text-center">
                  <v-btn
                    icon
                    color="error"
                    @click="archivateInvestor(props.item)"
                    :title="$t('custom.archive')"
                    :loading="isLoadingArchivateInvestor"
                  >
                    <v-icon>mdi-archive-arrow-down</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      investors: [],
      headers: [
        { text: this.$t('custom.id'), align: "center", value: "id" },
        { text: this.$t('bookings.name'), align: "center", value: "name" },
        { text: "%", align: "center", value: "percent" },
        { text: this.$t('partners.mode'), align: "center", value: "mode" },
        { text: this.$t('custom.description_2'), align: "center", value: "description" },
        { text: "", align: "center", sortable: false }
      ],
      investor: {
        name: null,
        percent: 10,
        mode: "Доходы",
        description: null
      },
      search: null,
      isMobile: false,
      isLoading: false,
      isLoadingArchivateInvestor: false,
      error: ""
    }
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin || this.$store.getters.isManager) {
        this.isLoading = true;
        this.axios
          .get("/api/v1/investors", {
            headers: {
              Authorization: this.$store.getters.getAuthToken
            }
          })
          .then(response => {
            this.investors = response.data;
          })
          .catch(error => {
            this.setError(error, this.$t('errors.partners_load'));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t('errors.not_permitted_title'),
          text: this.$t('errors.only_admins_2')
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role
      } else {
        return ""
      }
    },
  },
  methods: {
    archivateInvestor(investor) {
      this.isLoadingArchivateInvestor = true;
      this.axios
        .patch(`/api/v1/investors/${investor.id}`,
          {
            investors: {
              archived: true,
            },
            headers: {
              Authorization: this.$store.getters.getAuthToken
            }
          },
        )
        .then(response => {
          this.investors = this.investors.filter(
            item => item.id !== investor.id
          );
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t('custom.success'),
            text: this.$t('partners.archived')
          });
        })
        .catch(error => {
          this.setError(error, this.$t('errors.partners_archive'));
          console.log(error);
        })
        .finally(() => (this.isLoadingArchivateInvestor = false));
    },
    translateMode(mode) {
      let group_text = "";
      switch (mode) {
        case "Доходы":
          group_text = this.$t('investor_types.income');
          break;
        case "Доходы - Расходы":
          group_text = this.$t('investor_types.income_expences');
          break;
      }
      return group_text;
    },
    replaceToInvestor(investor) {
      this.$router.push({ path: `/investors/${investor.id}` });
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {}
};
</script>
