<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-text>
        <g-gantt-chart
          :chart-start="myChartStart"
          :chart-end="myChartEnd"
          precision="hour"
          color-scheme="material-blue"
        >
          <g-gantt-row
            v-for="row in rows"
            :key="row.label"
            :label="row.label"
            :bars="row.bars"
            :highlight-on-hover="true"
            bar-start="myStart"
            bar-end="myEnd"
          />
        </g-gantt-chart>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import {GGanttChart, GGanttRow} from 'vue-ganttastic'
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
require('dayjs/locale/ru')
import PullToRefresh from "pulltorefreshjs";
// moment.locale("ru");
export default {
  data() {
    return {
      bookings: [],
      rows: [
      ],
      myChartStart: "2023-03-26 00:00",
      myChartEnd: "2023-04-01 00:00",
      editDialog: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/index_for_calendar", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          self.rows = [];
          let data = [];
          console.log(response.data)
          response.data.cars.data.forEach((car) => {
            data.push({
              id: car.id,
              label: car.attributes.code,
              bars: [],
            });
          });
          response.data.bookings.data.forEach((booking) => {
            let row = data.find((row) => row.id == booking.attributes.car_id)
            if (row) {
              row.bars.push({
                id: booking.id,
                label: `${booking.attributes.last_name} ${booking.attributes.name}`,
                myStart: moment(booking.attributes.start_date, "DD-MM-YYYY H:mm").format("YYYY-MM-DD HH:mm"),
                myEnd: moment(booking.attributes.end_date, "DD-MM-YYYY H:mm").format("YYYY-MM-DD HH:mm"),
                color: booking.attributes.active ? "green" : "red",
              });
            }

          });
          self.rows = data;
          console.log(data)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    company() {
      return this.$store.getters.getCompany.company;
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components:{
    GGanttChart,
    GGanttRow
  },
};
</script>
