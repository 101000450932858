<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-container fluid fill-height>
      <!-- <v-flex md12>
        <h2>{{ $t("custom.analitics") }}</h2>
      </v-flex> -->
      <v-toolbar
        v-if="!isMobile"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center start-tour-analitic-1"
      >
        <v-toolbar-title
          >{{ $t("analitics.common_company") }} {{ $t("custom.number")
          }}{{ report.id }} {{ start_date_formatted }} -
          {{ end_date_formatted }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="report.with_property"
          :label="$t('analitics.with_property_short')"
          @click="with_property_warn()"
          readonly
          class="mt-5 mr-2"
        ></v-checkbox>
        <v-btn icon @click="generateReport()">
          <v-icon color="success">mdi-download-circle-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/8/guides/25`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        v-else
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center start-tour-analitic-1 mb-2"
        height="130px"
      >
        <v-flex md12>
          <v-flex md12>
            <v-toolbar-title class="subtitle-1 text-center"
              >{{ $t("analitics.common_company") }} {{ $t("custom.number")
              }}{{ report.id }}<br />{{ start_date_formatted }} -
              {{ end_date_formatted }}</v-toolbar-title
            >
          </v-flex>
          <v-flex md12 class="d-flex align-center justify-center">
            <v-checkbox
              v-model="report.with_property"
              :label="$t('analitics.with_property_short')"
              @click="with_property_warn()"
              readonly
              hide-details
              class="text-center"
            ></v-checkbox>
          </v-flex>
          <v-flex md12 class="text-center">
            <v-btn icon @click="generateReport()">
              <v-icon color="success">mdi-download-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="!isIos"
              icon
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/categories/8/guides/25`"
              target="_blank"
            >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <!-- По компании -->
      <v-flex
        v-if="report.state == 'created'"
        md12
        :class="isMobile ? '' : 'd-inline-flex'"
      >
        <v-flex md6 sm12>
          <v-simple-table :dense="isMobile ? true : false">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    {{ $t("analitics.income") }}
                  </th>
                  <th class="text-center">
                    {{ $t("custom.sum") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.part_income") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.expence") }}
                  </th>
                  <th class="text-center">
                    {{ $t("custom.sum") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.part_expences") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.rental_cost") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.rent_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.rent_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.salary") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.salary_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.salary_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.delivery") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.delivery_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.delivery_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.transport_expences") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.transport_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.transport_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.checkout") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.checkout_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.checkout_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.common_expences") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.common_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.common_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.gas_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.fuel_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.fuel_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.gas_fill") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.fuel_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.fuel_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.clean_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.clean_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.clean_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("custom.clean") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.clean_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.clean_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.add_hours_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.add_time_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.add_time_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.repair") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.repair_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.repair_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.repair_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.damages_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.damages_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("custom.service") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.service_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.service_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.fines_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.fines_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.fines_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("custom.fines") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.fines_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.fines_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.other_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.other_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.other_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.taxes") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.taxes_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.taxes_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("groups_counts.mileage") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.mileage_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.mileage_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.ads") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.ads_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.ads_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr v-if="report.with_property">
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.sell_property") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.sale_property_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.sale_property_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.buy_property") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.buy_property_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.buy_property_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.insurance_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.insurance_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.insurance_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("custom.insurance") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.insurance_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.insurance_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("counts.drivers_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.drivers_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.drivers_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.rent_office") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.rent_property_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.rent_property_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("groups_counts.equipment") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.equipment_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.equipment_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.partners_payments") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.partners_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.partners_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  >
                    {{ $t("groups_counts.different_income") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.different_income).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.different_income /
                          (report.income == 0 ? 1 : report.income)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("groups_counts.other_expence") }}
                  </th>
                  <td style="text-align: center">
                    {{ parseFloat(report.other_expence).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td style="text-align: center">
                    {{
                      parseFloat(
                        (report.other_expence /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                    "
                  ></th>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <th
                    :class="
                      $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                    "
                  >
                    {{ $t("counts.parts") }}
                  </th>
                  <td class="text-center">
                    {{ parseFloat(report.parts_expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{
                      parseFloat(
                        (report.parts_expense /
                          (report.expense == 0 ? 1 : report.expense)) *
                          100
                      ).toFixed(1)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark
                        ? 'green darken-3 title'
                        : 'green lighten-3 title'
                    "
                  >
                    {{ $t("analitics.incomes") }}
                  </th>
                  <td class="text-center subtitle-1">
                    {{ parseFloat(report.income).toFixed(1) }}{{ currency }}
                  </td>
                  <td class="text-center"></td>
                  <th
                    :class="
                      $vuetify.theme.dark
                        ? 'red darken-4 title'
                        : 'red lighten-3 title'
                    "
                  >
                    {{ $t("analitics.expences") }}
                  </th>
                  <td class="text-center subtitle-1">
                    {{ parseFloat(report.expense).toFixed(1) }}{{ currency }}
                  </td>
                  <td class="text-center"></td>
                </tr>
                <tr>
                  <th
                    :class="
                      $vuetify.theme.dark
                        ? 'green darken-2 title'
                        : 'green lighten-3 title'
                    "
                    colspan="4"
                  >
                    {{ $t("analitics.profit") }}
                  </th>
                  <td class="text-center title">
                    {{ parseFloat(report.income - report.expense).toFixed(1)
                    }}{{ currency }}
                  </td>
                  <td class="text-center"></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
        <v-flex md6 sm12 class="mt-2">
          <!-- Общий за период -->
          <v-flex md12 sm12 v-if="showRevenueGraf">
            <GChart
              style="width: 100%; min-height: 300px"
              type="BarChart"
              :options="{ title: $t('analitics.common_profit') }"
              :settings="{
                packages: ['corechart'],
                language: this.$i18n.locale,
              }"
              :data="revenue_for_period_data"
            />
          </v-flex>
          <v-flex md12 sm12 v-if="showRevenueGraf">
            <GChart
              style="width: 100%; min-height: 300px"
              type="PieChart"
              :options="pieIncomeOptions"
              :settings="{
                packages: ['corechart'],
                language: this.$i18n.locale,
              }"
              :data="pie_chart_income_data"
            />
          </v-flex>
          <v-flex md12 sm12 v-if="showRevenueGraf">
            <GChart
              style="width: 100%; min-height: 300px"
              type="PieChart"
              :options="pieExpencesOptions"
              :settings="{
                packages: ['corechart'],
                language: this.$i18n.locale,
              }"
              :data="pie_chart_expences_data"
            />
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex v-else md12 class="text-center mt-10">
        {{ $t("analitics.report_in_progress") }}
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <br />
        <v-btn @click="$router.go()">{{ $t("custom.update") }}</v-btn>
      </v-flex>
    </v-container>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      filename="report.pdf"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="landscape"
      :margin="[20, 40]"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      @hasStartedGeneration="isLoading = true"
      @hasGenerated="isLoading = false"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <h2 style="text-align: center; margin-top: 20px">
          {{ $t("analitics.common_company") }} {{ $t("custom.number")
          }}{{ report.id }} {{ start_date_formatted }} -
          {{ end_date_formatted }}
        </h2>

        <v-simple-table dense class="mx-auto ml-3">
          <template v-slot:default>
            <thead>
              <tr style="text-align: left">
                <th style="text-align: center">
                  {{ $t("analitics.income") }}
                </th>
                <th style="text-align: center">
                  {{ $t("custom.sum") }}
                </th>
                <th style="text-align: center">
                  {{ $t("analitics.part_income") }}
                </th>
                <th style="text-align: center">
                  {{ $t("analitics.expence") }}
                </th>
                <th style="text-align: center">
                  {{ $t("custom.sum") }}
                </th>
                <th style="text-align: center">
                  {{ $t("analitics.part_expences") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.rental_cost") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.rent_income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.rent_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.salary") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.salary_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.salary_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.delivery") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.delivery_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.delivery_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.transport_expences") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.transport_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.transport_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.checkout") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.checkout_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.checkout_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.common_expences") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.common_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.common_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.gas_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.fuel_income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.fuel_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.gas_fill") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.fuel_expense).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.fuel_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.clean_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.clean_income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.clean_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("custom.clean") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.clean_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.clean_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.add_hours_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.add_time_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.add_time_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.repair") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.repair_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.repair_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.repair_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.damages_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.damages_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("custom.service") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.service_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.service_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.fines_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.fines_income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.fines_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("custom.fines") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.fines_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.fines_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.other_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.other_income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.other_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.taxes") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.taxes_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.taxes_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("groups_counts.mileage") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.mileage_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.mileage_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.ads") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.ads_expense).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.ads_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr v-if="report.with_property">
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.sell_property") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.sale_property_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.sale_property_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.buy_property") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.buy_property_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.buy_property_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.insurance_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.insurance_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.insurance_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("custom.insurance") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.insurance_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.insurance_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("counts.drivers_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.drivers_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.drivers_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.rent_office") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.rent_property_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.rent_property_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("groups_counts.equipment") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.equipment_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.equipment_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.partners_payments") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.partners_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.partners_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                >
                  {{ $t("groups_counts.different_income") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.different_income).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.different_income /
                        (report.income == 0 ? 1 : report.income)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("groups_counts.other_expence") }}
                </th>
                <td style="text-align: center">
                  {{ parseFloat(report.other_expence).toFixed(1)
                  }}{{ currency }}
                </td>
                <td style="text-align: center">
                  {{
                    parseFloat(
                      (report.other_expence /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th
                  :class="
                    $vuetify.theme.dark ? 'green darken-1' : 'green lighten-4'
                  "
                ></th>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <th
                  :class="
                    $vuetify.theme.dark ? 'red darken-1' : 'red lighten-4'
                  "
                >
                  {{ $t("counts.parts") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.parts_expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center">
                  {{
                    parseFloat(
                      (report.parts_expense /
                        (report.expense == 0 ? 1 : report.expense)) *
                        100
                    ).toFixed(1)
                  }}%
                </td>
              </tr>
              <tr>
                <th class="green lighten-3">
                  {{ $t("analitics.incomes") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.income).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center"></td>
                <th class="red lighten-3">
                  {{ $t("analitics.expences") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.expense).toFixed(1) }}{{ currency }}
                </td>
                <td class="text-center"></td>
              </tr>
              <tr>
                <th class="green lighten-1" colspan="4">
                  {{ $t("analitics.profit") }}
                </th>
                <td class="text-center">
                  {{ parseFloat(report.income - report.expense).toFixed(1)
                  }}{{ currency }}
                </td>
                <td class="text-center"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="html2pdf__page-break" />
        <v-flex md12 sm12 v-if="showRevenueGraf">
          <GChart
            style="width: 1200px"
            type="BarChart"
            :options="{ title: $t('analitics.common_profit'), height: 600 }"
            :settings="{ packages: ['corechart'], language: this.$i18n.locale }"
            :data="revenue_for_period_data"
          />
        </v-flex>
        <div class="html2pdf__page-break" />
        <v-flex md12 sm12 v-if="showRevenueGraf" class="justify-start">
          <GChart
            style="width: 900px"
            type="PieChart"
            :options="pieIncomeOptionsPdf"
            :settings="{ packages: ['corechart'], language: this.$i18n.locale }"
            :data="pie_chart_income_data"
          />
        </v-flex>
        <div class="html2pdf__page-break" />
        <v-flex md12 sm12 v-if="showRevenueGraf" class="justify-start">
          <GChart
            style="width: 900px"
            type="PieChart"
            :options="pieExpencesOptionsPdf"
            :settings="{ packages: ['corechart'], language: this.$i18n.locale }"
            :data="pie_chart_expences_data"
          />
        </v-flex>
      </section>
    </vue-html2pdf>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { GChart } from "vue-google-charts";
import { isMobile } from "mobile-device-detect";
import VueHtml2pdf from "vue-html2pdf";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      report: {},
      property: false,
      pie_chart_data: [],
      revenue_for_period_data: [],
      pie_chart_income_data: [],
      pie_chart_expences_data: [],
      pieIncomeOptions: {
        title: this.$t("custom.income"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pieExpencesOptions: {
        title: this.$t("custom.expences"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pieIncomeOptionsPdf: {
        title: this.$t("custom.income"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
        // width: 800,
        height: 480,
      },
      pieExpencesOptionsPdf: {
        title: this.$t("custom.expences"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
        width: 800,
        height: 480,
      },
      showExcel: false,
      showRevenueGraf: false,
      showRevenueColumn: false,
      showRevenueTable: false,
      showClientsData: false,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/company_reports/${this.$route.params.query}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.report = response.data;
          this.revenue_for_period_data = [
            [
              this.$t("custom.income"),
              this.$t("custom.income"),
              this.$t("custom.expences"),
              this.$t("analitics.profit"),
            ],
            [
              "",
              this.report.income,
              this.report.expense,
              this.report.income - this.report.expense,
            ],
          ];
          this.pie_chart_income_data = [
            [this.$t("custom.income"), this.$t("custom.sum")],
            [
              this.$t("counts_groups.rental_cost"),
              parseFloat(this.report.rent_income),
            ],
            [
              this.$t("counts_groups.delivery_payments"),
              parseFloat(this.report.delivery_income),
            ],
            [
              this.$t("counts_groups.checkout_payments"),
              parseFloat(this.report.checkout_income),
            ],
            [
              this.$t("counts_groups.gas_payments"),
              parseFloat(this.report.fuel_income),
            ],
            [
              this.$t("counts_groups.insurance_payments"),
              parseFloat(this.report.insurance_income),
            ],
            [
              this.$t("counts_groups.drivers_payments"),
              parseFloat(this.report.drivers_income),
            ],
            [
              this.$t("groups_counts.equipment"),
              parseFloat(this.report.equipment_income),
            ],
            [
              this.$t("counts_groups.clean_payments"),
              parseFloat(this.report.clean_income),
            ],
            [
              this.$t("counts_groups.add_hours_payments"),
              parseFloat(this.report.add_time_income),
            ],
            [
              this.$t("counts_groups.repair_payments"),
              parseFloat(this.report.damages_income),
            ],
            [
              this.$t("counts_groups.fines_payments"),
              parseFloat(this.report.fines_income),
            ],
            [
              this.$t("counts_groups.other_payments"),
              parseFloat(this.report.other_income),
            ],
            [
              this.$t("counts_groups.different_income"),
              parseFloat(this.report.different_income),
            ],
            [
              this.$t("counts_groups.mileage_payments"),
              parseFloat(this.report.mileage_income),
            ],
            [
              this.$t("counts_groups.sell_property"),
              parseFloat(this.report.sale_property_income),
            ],
          ];
          if (!this.report.with_property) {
            this.pie_chart_income_data.pop();
          }
          this.pie_chart_expences_data = [
            [this.$t("custom.expence"), this.$t("custom.sum")],
            [
              this.$t("counts_groups.salary"),
              parseFloat(this.report.salary_expense),
            ],
            [
              this.$t("counts_groups.transport_expences"),
              parseFloat(this.report.transport_expense),
            ],
            [
              this.$t("counts_groups.gas"),
              parseFloat(this.report.fuel_expense),
            ],
            [
              this.$t("counts_groups.clean_expences"),
              parseFloat(this.report.clean_expense),
            ],
            [
              this.$t("counts_groups.parts_expences"),
              parseFloat(this.report.parts_expense),
            ],
            [
              this.$t("counts_groups.insurance_expences"),
              parseFloat(this.report.insurance_expense),
            ],
            [
              this.$t("counts_groups.fines_expences"),
              parseFloat(this.report.fines_expense),
            ],
            [
              this.$t("counts_groups.repair_expences"),
              parseFloat(this.report.repair_expense),
            ],
            [
              this.$t("counts_groups.service_expences"),
              parseFloat(this.report.service_expense),
            ],
            [
              this.$t("counts_groups.common_expences"),
              parseFloat(this.report.common_expense),
            ],
            [
              this.$t("counts_groups.tax_expences"),
              parseFloat(this.report.taxes_expense),
            ],
            [
              this.$t("counts_groups.partners_expences"),
              parseFloat(this.report.partners_expense),
            ],
            [
              this.$t("counts_groups.ads_expences"),
              parseFloat(this.report.ads_expense),
            ],
            [
              this.$t("counts_groups.office_expences"),
              parseFloat(this.report.rent_property_expense),
            ],
            [
              this.$t("counts_groups.buy_property"),
              parseFloat(this.report.buy_property_expense),
            ],
            [
              this.$t("counts_groups.other_expense"),
              parseFloat(this.report.other_expense),
            ],
          ];
          if (!this.report.with_property) {
            this.pie_chart_expences_data.pop();
          }
          this.showRevenueGraf = true;
        })
        .catch((error) => {
          // Поменять перевод!
          this.setError(error, this.$t("errors.company_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => {});
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  computed: {
    start_date_formatted() {
      return moment(this.report.start_date).format("DD.MM.YYYY");
    },
    end_date_formatted() {
      return moment(this.report.end_date).format("DD.MM.YYYY");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    with_property_warn() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "warning",
        title: this.$t("errors.with_property_warn"),
      });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    VueHtml2pdf,
    GChart,
  },
};
</script>
