<template>
  <v-container v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <!-- <v-card-title>
        <h2>{{ $t("custom.profile") }} {{ user.name }}</h2>
        <v-icon class="ml-2 green--text" @click="saveUser()">
          mdi-content-save-all-outline
        </v-icon>
      </v-card-title> -->

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 class="text-center">
              <v-flex xs12 sm12 md12>
                <v-avatar size="150px" color="blue" v-if="user" class="UppyModalOpenerBtn" style="cursor: pointer;">
                  <v-img
                    v-if="!isNative && user && user.avatar_url"
                    :src="user.avatar_url"
                    :alt="user.name"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-gravatar
                    v-else-if="!isNative"
                    crossorigin="Anonymous"
                    :email="user.email"
                    :size="150"
                  />
                  <span v-else class="white--text text-h5">{{
                    user && user.name && user.name[0]
                      ? user.name[0]
                      : user.email[0]
                  }}</span>
                </v-avatar>
              </v-flex>
              <v-flex v-if="user.name" xs12 sm12 md12>
                <h2>{{ user.name }}</h2>
              </v-flex>
            </v-flex>
            <v-flex xs12 sm12 md12 class="my-2 d-flex justify-center">
              <uppy-user-avatar
                :max-file-size-in-bytes="10000000"
                :width="isMobile ? '300' : '750'"
                :height="isMobile ? 250 : 250"
                :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                :user_id="user.id"
              ></uppy-user-avatar>
            </v-flex>
          </v-layout>
          <v-layout row wrap id="registration-step-6">
            <v-flex xs12 sm12 md4 id="registration-step-2">
              <v-text-field
                v-model.trim="user.email"
                :label="$t('custom.email')"
                :disabled="true"
                type="text"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                :label="$t('users.phone')"
                v-model.trim="user.phone"
                :placeholder="$t('users.phone_placeholder')"
                type="text"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                :label="$t('users.tg_id')"
                v-model.trim="user.telegram_id"
                type="text"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                :label="$t('employers.nickname')"
                type="text"
                v-model.trim="user.name"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                :label="$t('employers.name')"
                type="text"
                v-model.trim="user.first_name"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                :label="$t('employers.middle_name')"
                type="text"
                v-model.trim="user.middle_name"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                :label="$t('employers.last_name')"
                type="text"
                v-model.trim="user.last_name"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex md12 sm12>
              <v-select
                v-model="select_locale"
                class="mb-2"
                item-text="text"
                item-value="value"
                :items="locales"
                hide-details
                :label="$t('companies.locale_front')"
                outlined
              ></v-select>
            </v-flex>
            <v-flex v-if="main_company.beacons_on && user_role != 'agent'" md12 sm12>
              <h3 class="mb-3">{{ $t("cars.traccar_service") }}</h3>
              <v-text-field
                v-model="user.traccar_password"
                :label="$t('cars.traccar_password')"
                type="password"
                autocomplete="off"
                outlined
                hide-details
              >
              </v-text-field>
            </v-flex>
            <v-flex md12 sm12>
              <h3>{{ $t("employers.partner_program") }}</h3>
              <p class="caption mb-3">{{ $t("employers.partner_program_description") }}</p>
              <v-text-field
                v-model="referral_link"
                :label="$t('employers.referral_link')"
                type="text"
                readonly
                outlined
                hide-details
              >
              <v-icon
                slot="append"
                :color="referral_link ? 'green' : 'grey'"
                @click="copyReferralLink()"
                cursor="pointer"
              >
                mdi-content-copy
              </v-icon>
              </v-text-field>
            </v-flex>
            <v-flex md12 sm12>
              <v-text-field
                v-model="referral_link_landing"
                :label="$t('employers.referral_link_landing')"
                type="text"
                readonly
                outlined
                hide-details
              >
              <v-icon
                slot="append"
                :color="referral_link_landing ? 'green' : 'grey'"
                @click="copyReferralLinkLanding()"
                cursor="pointer"
              >
                mdi-content-copy
              </v-icon>
              </v-text-field>
            </v-flex>
            <v-flex v-if="user.partner_program_policy_accepted" md12 sm12 class="d-flex align-center">
              <v-text-field
                v-if="user.partner_balance_hold <= 0"
                v-model="user.partner_balance"
                :label="$t('employers.partner_balance')"
                readonly
                outlined
                hide-details
              >
              </v-text-field>
              <v-text-field
                v-else
                v-model="user.partner_balance_hold"
                :label="$t('employers.partner_balance_to_payout')"
                disabled
                readonly
                outlined
                hide-details
              >
              </v-text-field>
              <v-btn
                color="info"
                @click="payoutPartnerBalance()"
                :loading="isLoadingPayoutPartnerBalance"
                :disabled="user.partner_balance <= 0"
                class="ml-2"
                >{{ $t("employers.payout_partner_balance")}}
              </v-btn>
            </v-flex>
            <v-flex v-if="!user.partner_program_policy_accept" md12 sm12 class="d-flex align-center justify-center">
              <v-btn
                color="info"
                @click="partner_program_policy_accept_dialog = true"
                class="ml-2"
                >{{ $t("employers.partner_program_policy_accept")}}
              </v-btn>
            </v-flex>
            <v-flex v-if="user.partner_program_policy_accept && !user.partner_program_policy_accepted" md12 sm12 class="d-flex align-center justify-center">
              <v-alert
                type="warning"
                :value="true"
                dismissible
                border="left"
                elevation="2"
                icon="mdi-alert"
                class="mt-3"
              >
                {{ $t("employers.partner_program_policy_accepted_not_approved") }}
              </v-alert>
            </v-flex>
            <v-flex md12>
              <h3>{{ $t("user_settings.dark_theme") }}</h3>
              <v-switch
                v-model="user_setting.dark_theme"
                :label="$t('user_settings.dark_theme')"
                @change="setTheme()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex md12>
              <h3>{{ $t("user_settings.calendar_settings") }}</h3>
              <v-switch
                v-model="user_setting.show_icons_on_calendar"
                :label="$t('user_settings.show_icons_on_calendar')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <h3>{{ $t("user_settings.push_app") }}</h3>
              <v-switch
                v-model="user_setting.new_booking_push"
                :label="$t('user_settings.new_booking_push')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <v-switch
                v-model="user_setting.new_booking_sound"
                :label="$t('user_settings.new_booking_sound')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <v-switch
                v-model="user_setting.new_message_push"
                :label="$t('user_settings.new_message_push')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <v-switch
                v-model="user_setting.new_message_sound"
                :label="$t('user_settings.new_message_sound')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <h3>{{ $t("users.connect_telegram") }}</h3>
              <v-btn class="my-3" @click="toTelegram(user.email)"
                >{{ $t("users.connect")
                }}<svg
                  class="mx-1"
                  width="24px"
                  height="24px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 1.41421;
                  "
                >
                  <path
                    id="telegram-1"
                    d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                  /></svg
              ></v-btn>
            </v-flex>
            <v-flex v-if="user_role != 'agent'" md12>
              <h3>{{ $t("user_settings.interface_settings") }}</h3>
              <v-switch
                v-model="user_setting.show_repair_on_main"
                :label="$t('user_settings.show_repairs_on_main')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
              <v-switch
                v-model="user_setting.show_store_place_on_main"
                :label="$t('user_settings.show_store_places_on_main')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
              <v-switch
                v-model="user_setting.show_to_on_main"
                :label="$t('user_settings.show_to_on_main')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
              <v-switch
                v-model="user_setting.show_tire_on_main"
                :label="$t('user_settings.show_tire_on_main')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
              <v-switch
                v-model="user_setting.short_client_view"
                :label="$t('user_settings.short_client_view')"
                @change="updateUserSetting()"
                class="my-2"
                outlined
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex md12>
              <p>
                {{ $t("users.avatar_change") }}
                <a v-if="$root.$i18n.locale == 'ru'" href="https://ru.gravatar.com/" target="_blank">{{ $t("custom.there") }}</a>
                <a v-else href="https://gravatar.com/" target="_blank">{{ $t("custom.there") }}</a>
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="registration-step-5"
          class="mb-3"
          color="success"
          @click="saveUser()"
          >{{ $t("custom.save")
          }}<v-icon class="ml-1">
            mdi-content-save-check-outline
          </v-icon></v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showConfirmSignin"
      :retain-focus="false"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("custom.approve") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-card-text>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                :hint="$t('users.password_length')"
                counter
                @click:append="show1 = !show1"
                data-cy="password"
                :label="$t('users.password')"
                required
              ></v-text-field>
            </v-card-text>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            @click="showConfirmSignin = false"
            data-cy="submit"
            block
            color="primary"
            >{{ $t("custom.approve_2") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="partner_program_policy_accept_dialog"
      :retain-focus="false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("employers.partner_program_policy_accept_title") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <p>{{ $t("employers.partner_program_policy_accept_text") }}</p>
          <p class="text-center mt-2"><a :href="$t('employers.partner_program_policy_link')" target="_blank">{{ $t("employers.partner_program_policy_link_text") }}</a></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="partnerProgramPolicyAccept()"
            color="primary"
            class="mr-2"
            :loading="isLoadingPartnerProgramPolicyAccept"
            >{{ $t("custom.approve_2") }}</v-btn
          >
          <v-btn
            @click="partner_program_policy_accept_dialog = false"
            color="secondary"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import PullToRefresh from "pulltorefreshjs";
import Gravatar from "vue-gravatar";
import { Capacitor } from "@capacitor/core";
import UppyUserAvatar from "../../../plugins/uppy_user_avatar";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      user: [],
      email: null,
      password: null,
      user_setting: {},
      showConfirmSignin: false,
      show1: false,
      account: {
        cash: 0,
        cashless: 0,
      },
      locales: [
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      select_locale: this.$i18n.locale,
      referral_link: null,
      referral_link_landing: null,
      goDark: false,
      showEdit: false,
      partner_program_policy_accept_dialog: false,
      isLoadingPayoutPartnerBalance: false,
      isLoadingPartnerProgramPolicyAccept: false,
      isLoading: false,
      isMobile: false,
      error: "",
    };
  },
  created() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/user_info`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.user = response.data;
          this.$store.commit("setCurrentUser", response.data);
          this.referral_link = `https://web.rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/signup?ref=${this.user.referral_code}`;
          this.referral_link_landing = `https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/?ref=${this.user.referral_code}`;

          this.account = response.data.account;
          this.user_setting = response.data.user_setting;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_user"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (this.$tours["registrationTour"]) {
      this.$tours["registrationTour"].nextStep();
    }
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  computed: {
    main_company() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
  },
  watch: {
    select_locale() {
      this.$i18n.locale = this.select_locale;
      this.$root.$i18n.locale = this.select_locale;
      localStorage.setItem("lang", this.select_locale);
      this.user.locale = this.select_locale;
      this.saveLocale();
    },
  },
  methods: {
    saveUser() {
      this.isLoading = true;
      let self = this;
      if (
        this.user.email &&
        this.$store.getters.getCurrentUser.email &&
        this.user.email != this.$store.getters.getCurrentUser.email
      ) {
        this.axios
          .patch(
            `/api/v1/users/${this.user.id}`,
            {
              users: {
                name: this.user.name,
                last_name: this.user.last_name,
                middle_name: this.user.middle_name,
                first_name: this.user.first_name,
                phone: this.user.phone,
                telegram_id: this.user.telegram_id,
                locale: this.user.locale,
                traccar_password: this.user.traccar_password,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.showEdit = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.profile_updated"),
              text: this.$t("users.profile_updated_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.profile_update"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.profile_update"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else if (
        this.user.email &&
        this.$store.getters.getCurrentUser.email &&
        this.user.email == this.$store.getters.getCurrentUser.email
      ) {
        this.axios
          .patch(
            `/api/v1/users/${this.user.id}`,
            {
              users: {
                name: this.user.name,
                last_name: this.user.last_name,
                middle_name: this.user.middle_name,
                first_name: this.user.first_name,
                telegram_id: this.user.telegram_id,
                phone: this.user.phone,
                locale: this.user.locale,
                traccar_password: this.user.traccar_password,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.showEdit = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.profile_updated"),
              text: this.$t("users.profile_updated_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.profile_update"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.profile_update"),
              text: this.error,
            });
          })
          .finally(() => (self.isLoading = false));
      }
    },
    payoutPartnerBalance() {
      if (this.user.currency_partner_program == "RUB" && this.user.partner_balance < 5000) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("employers.payout_partner_balance_not_enough"),
        });
        return;
      }
      if (this.user.currency_partner_program == "EUR" && this.user.partner_balance < 50) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("employers.payout_partner_balance_not_enough"),
        });
        return;
      }

      this.isLoadingPayoutPartnerBalance = true;
      let self = this;
      this.axios
        .post(
          `/api/v1/payout_partner_balance`,
          {
            user_id: this.user.id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.user.partner_balance_hold = this.user.partner_balance;
          this.user.partner_balance = 0;

          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("other.success"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.profile_update"));
        })
        .finally(() => (self.isLoadingPayoutPartnerBalance = false));
    },
    partnerProgramPolicyAccept() {
      this.isLoadingPartnerProgramPolicyAccept = true;
      let self = this;
      this.axios
        .post(
          `/api/v1/partner_program_policy_accept`,
          {
            user_id: this.user.id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.user.partner_program_policy_accept = true;
          this.partner_program_policy_accept_dialog = false;
          this.$store.commit("setCurrentUser", response.data);
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("other.success"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.profile_update"));
        })
        .finally(() => (self.isLoadingPartnerProgramPolicyAccept = false));
    },
    updateUserSetting() {
      this.axios
        .patch(
          `/api/v1/update_user_setting`,
          {
            user_setting: {
              new_booking_push: this.user_setting.new_booking_push,
              new_booking_sound: this.user_setting.new_booking_sound,
              new_message_push: this.user_setting.new_message_push,
              new_message_sound: this.user_setting.new_message_sound,
              tech_push: this.user_setting.tech_push,
              insurance_push: this.user_setting.insurance_push,
              show_repair_on_main: this.user_setting.show_repair_on_main,
              show_store_place_on_main:
                this.user_setting.show_store_place_on_main,
              show_to_on_main: this.user_setting.show_to_on_main,
              show_tire_on_main: this.user_setting.show_tire_on_main,
              short_client_view: this.user_setting.short_client_view,
              dark_theme: this.user_setting.dark_theme,
              show_icons_on_calendar: this.user_setting.show_icons_on_calendar,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setUserSettings", response.data);
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("users.profile_updated"),
            text: this.$t("users.profile_updated_text"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.profile_update"));
        })
        .finally(() => (this.isLoading = false));
    },
    saveLocale() {
      this.isLoading = true;
      let self = this;
      if (this.user.locale) {
        this.axios
          .patch(
            `/api/v1/users/${this.user.id}`,
            {
              users: {
                locale: this.user.locale,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: self.$t("users.profile_updated"),
              text: self.$t("users.profile_updated_text"),
            });
          })
          .catch((error) => {
            self.setError(error, self.$t("errors.profile_update"));
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: self.$t("errors.profile_update"),
              text: self.error,
            });
          })
          .finally(() => (self.isLoading = false));
      }
    },
    setTheme() {
      this.updateUserSetting();
      if (this.user_setting.dark_theme == true) {
        return (this.$vuetify.theme.dark = true);
      } else {
        return (this.$vuetify.theme.dark = false);
      }
    },
    toTelegram(email) {
      let base_64_email = btoa(email);
      window.open(`https://t.me/rentalmanbot?start=${base_64_email}`, "_blank");
    },
    copyReferralLink() {
      navigator.clipboard.writeText(this.referral_link);

      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: this.$t("custom.copy_success"),
      });
    },
    copyReferralLinkLanding() {
      navigator.clipboard.writeText(this.referral_link_landing);

      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: this.$t("custom.copy_success"),
      });
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    "v-gravatar": Gravatar,
    UppyUserAvatar,
  },
};
</script>
