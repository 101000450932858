<template>
  <v-container>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-flex v-if="documents.length > 0">
      <v-list
        subheader
        two-line
      >
        <v-subheader inset>
          {{ $t('custom.files') }}
        </v-subheader>

        <v-list-item
          v-for="document in documents"
          :key="document.id"
        >
          <v-list-item-avatar>
            <v-icon
              class="grey lighten-1"
              dark
            >
              mdi-folder
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <!-- <v-list-item-title v-text="document.name"></v-list-item-title> -->

            <v-list-item-subtitle>
              {{ fileName(document.name) }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-flex>
              <v-icon class="mx-1" color="info" @click="downloadItem(document)">
                mdi-content-save-outline
              </v-icon>
              <v-icon
                class="mx-1"
                color="warning"
                @click="deleteItem(document)"
              >
                mdi-delete-forever
              </v-icon>
            </v-flex>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-flex>
    <v-flex v-else class="d-flex text-center justify-center">
      <v-card flat tile class="d-flex text-center justify-center">
        {{ $t('other.no_docs') }}
      </v-card>
    </v-flex>
  </v-container>
</template>
<script>
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      documents: [],
      gallery_items: [],
      link_full_image: "",
      dialog: false,
      isLoading: false
    };
  },
  beforeCreate() {
    this.isLoading = true;
    this.axios
      .get(`/api/v1/contractor_files/${this.$route.params.id}`, {
        headers: {
          Authorization: this.$store.getters.getAuthToken
        }
      })
      .then(response => {
        this.documents = response.data;
      })
      .catch(error => {
        this.setError(error, this.$t('errors.file_links_load'));
        console.log(error);
      })
      .finally(() => (this.isLoading = false));
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role
      } else {
        return ""
      }
    },
  },
  methods: {
    fileName(name) {
      return name.split("/").pop();
    },
    screenFullImage(item) {
      this.link_full_image = item.url;
      this.dialog = true;
    },
    downloadItem(item) {
      saveAs(`${item.url}`);
    },
    async deleteItem(item) {
       if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this
        this.$swal({
          title: this.$t('custom.you_sure_title'),
          text: this.$t('custom.you_sure_text'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t('custom.you_sure_yes'),
          cancelButtonText: this.$t('custom.you_sure_no'),
          showCloseButton: true
        }).then(result => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/contractor_files/${item.id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken
                }
              })
              .then(() => {
                this.documents.splice(this.documents.indexOf(item), 1);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t('custom.deleted'),
                  text: this.$t('other.delete_file')
                });
              })
              .catch(error => {
                this.setError(error, this.$t('errors.file_client_load'));
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
