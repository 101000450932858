var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.checkMobile),expression:"checkMobile"}]},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('h2',[_vm._v(_vm._s(_vm.$t("custom.cars_archive")))]),_c('v-card',[_c('v-toolbar',{attrs:{"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.econom')},model:{value:(_vm.showEconom),callback:function ($$v) {_vm.showEconom=$$v},expression:"showEconom"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.middle')},model:{value:(_vm.showMiddle),callback:function ($$v) {_vm.showMiddle=$$v},expression:"showMiddle"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.business')},model:{value:(_vm.showBusiness),callback:function ($$v) {_vm.showBusiness=$$v},expression:"showBusiness"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.commercial')},model:{value:(_vm.showCommercial),callback:function ($$v) {_vm.showCommercial=$$v},expression:"showCommercial"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{class:!_vm.isMobile ? '' : 'is-mobile',attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"loading-text":_vm.$t('custom.loading_table'),"items":_vm.cars,"search":_vm.search,"sort-by":"sort","sort-desc":true,"item-key":"id","items-per-page":50,"footer-props":{
        pageText: `{0} ${_vm.$t('custom.of')} {1}`,
        itemsPerPageText: _vm.$t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100, -1],
      }},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
              'column sortable',
              _vm.pagination.descending ? 'desc' : 'asc',
              header.value === _vm.pagination.sortBy ? 'active' : '',
            ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"item",fn:function(props){return [_c('tr',{on:{"dblclick":function($event){return _vm.replaceToCar(props.item)}}},[_c('td',{key:props.item.id,staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ path: `/cars/${props.item.id}` }}},[_vm._v(_vm._s(props.item.car_name))])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.code))]),_c('td',{staticClass:"justify-center layout px-0"},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(props.item)}}},[_vm._v(" mdi-delete-forever ")])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.number))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.color))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.year))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.price_hour))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.deposit))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("custom.no_data_in_table"))+" ")]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("tables.no_search_result", { msg: _vm.search }))+" ")])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }