<template>
  <form>
    <div class="image-container mb-3" v-if="previewPath">
      <img :src="previewPath" alt="Uploaded Image Preview" />
    </div>
    <div class="form-group">
      <div ref="dashboardContainer"></div>
    </div>
    <!-- <v-btn :disabled="disabled" @click.prevent="confirmUpload" class="btn btn-primary btn-block mb-2">Confirm upload</v-btn> -->
  </form>
</template>

<script>
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
// import AwsS3Multipart from "@uppy/aws-s3-multipart";

import Dashboard from "@uppy/dashboard";
// import Form from "@uppy/form";
import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    maxFileSizeInBytes: {
      type: Number,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      previewPath: null,
      disabled: true,
      isLoading: false,
    };
  },
  mounted() {
    this.instantiateUppy();
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    instantiateUppy() {
      let self = this;
      this.uppy = Uppy({
        debug: true,
        locale: this.$i18n.locale === "ru" ? Russian : English,
        autoProceed: true,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          allowedFileTypes: [
            "image/*", ".heic", ".heif",
          ],
          // allowedFileTypes: ['image/*', 'application/doc', 'application/docx', 'application/pdf']
        },
      })
        .use(Dashboard, {
          hideUploadButton: true,
          theme: this.theme,
          inline: false,
          trigger: ".UppyModalOpenerBtn",
          width: this.width,
          height: this.height,
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          closeAfterFinish: true,
          locale: {
            strings: {
              poweredBy2: "",
              // browseFiles: "выберите"
            },
          },
        })
        .use(XHRUpload, {
          endpoint: process.env.NODE_ENV === "development" ? `http://localhost:3000/api/v1/user_avatars` : `https://rentprog.net/api/v1/user_avatars`,
          method: "post",
          formData: true,
          fieldName: "image",
          bundle: false,
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        });

      this.uppy.on("complete", (event) => {
        if (event.successful[0] !== undefined) {
          // this.payload = event.successful[0].response.body.path;
          // const imageName = uuid.v1();
          // let fileExtention = file.name.split(".")[1];
          // console.log(event.successful[0].s3Multipart.key);
          this.disabled = false;
          let user = this.$store.getters.getCurrentUser;
          user.avatar_url = event.successful[0].response.body;
          this.$store.commit(
            "setCurrentUser",
            user
          );
        }
      });

      const uploadedFileData = (file, response) => {
        return JSON.stringify(response.body);
      };
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;

      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
    confirmUpload() {
      if (this.payload) {
        this.disabled = true;
        axios
          .post("/store", { file: this.payload })
          .then(({ data }) => {
            this.updatePreviewPath(data).resetUploader();
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Файлы загружены!",
              text: "Вы успешно загрузили файлы!",
            });
          })
          .catch((err) => {
            console.error(err);

            this.resetUploader();
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Ошибка при загрузке!",
          text: this.error,
        });
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container>img {
  width: inherit;
  height: inherit;
}
</style>
