<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <!-- <h2 v-if="!isMobile">{{ $t("custom.archive") }}</h2>
    <h3 class="text-center" v-else>{{ $t("custom.archive") }}</h3> -->
    <v-toolbar :color="$vuetify.theme.dark ? '' : 'white'" v-if="!isMobile" class="align-middle">
      <v-flex class="d-flex justify-space-between align-center">
        <v-flex class="d-flex mr-3">
          <v-btn small color="secondary" :to="{ name: 'Bookings' }">{{
            $t("custom.back_to_booking")
            }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="!show_ride_booking">{{ $t("archive.ride_bookings") }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="show_ride_booking">{{ $t("archive.all_bookings") }}</v-btn>
          <v-spacer></v-spacer>
        </v-flex>
        <v-flex md12 class="d-flex mr-3">
          <v-flex class="d-flex mr-3">
            <v-menu v-model="sort_date_menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="sort_date_formatted" :label="$t('archive.start_end')" readonly v-bind="attrs"
                  v-on="on" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="sort_date" :locale="$i18n.locale" @input="sort_date_menu = false"
                first-day-of-week="1"></v-date-picker>
            </v-menu>
            <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="sort_date = null">
              mdi-close
            </v-icon>
          </v-flex>
          <v-flex class="d-flex mr-3">
            <v-menu v-model="sort_date_between_menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="sort_date_between_formatted" :label="$t('archive.date_in')" readonly
                  v-bind="attrs" v-on="on" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="sort_date_between" :locale="$i18n.locale" @input="sort_date_between_menu = false"
                first-day-of-week="1" clearable></v-date-picker>
            </v-menu>
            <v-icon class="mr-2 mt-2 text-left" v-if="sort_date_between" @click="sort_date_between = null">
              mdi-close
            </v-icon>
          </v-flex>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line clearable
            hide-details></v-text-field>
          <v-icon
            v-if="(user_role == 'superadmin' || user_role == 'admin') && showBooking() && showBooking().length > 0"
            @click="toXlsx" class="mx-2" style="cursor: pointer" color="success">
            mdi-file-excel-outline
          </v-icon>
        </v-flex>
      </v-flex>
    </v-toolbar>
    <v-toolbar class="mb-2" height="200px" :color="$vuetify.theme.dark ? '' : 'white'" v-else>
      <v-flex md12 class="text-center" justify-center>
        <v-flex class="d-flex mt-2 mb-4">
          <v-spacer></v-spacer>
          <v-btn small color="secondary" :to="{ name: 'Bookings' }">{{
            $t("custom.back_to_booking")
            }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="!show_ride_booking">{{ $t("archive.ride_bookings") }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="show_ride_booking">{{ $t("archive.all_bookings") }}</v-btn>
          <download-excel :data="showBooking()" v-if="showBooking() && showBooking().length > 0">
            <v-icon class="ml-2" style="cursor: pointer" color="success">
              mdi-file-excel-outline
            </v-icon>
          </download-excel>
          <v-spacer></v-spacer>
        </v-flex>
        <v-flex class="d-flex mb-4">
          <v-menu v-model="sort_date_menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="sort_date_formatted" :label="$t('archive.start_end')" readonly v-bind="attrs"
                v-on="on" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="sort_date" :locale="$i18n.locale" @input="sort_date_menu = false"
              first-day-of-week="1"></v-date-picker>
          </v-menu>
          <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="sort_date = null">
            mdi-close
          </v-icon>
        </v-flex>
        <v-flex class="d-flex mb-4">
          <v-menu v-model="sort_date_between_menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="sort_date_between_formatted" :label="$t('archive.date_in')" readonly v-bind="attrs"
                v-on="on" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="sort_date_between" :locale="$i18n.locale" @input="sort_date_between_menu = false"
              first-day-of-week="1" clearable></v-date-picker>
          </v-menu>
          <v-icon class="mr-2 mt-2 text-left" v-if="sort_date_between" @click="sort_date_between = null">
            mdi-close
          </v-icon>
        </v-flex>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line clearable
          hide-details></v-text-field>
      </v-flex>
    </v-toolbar>
    <v-data-table v-model="selected" :headers="headers" :items="showBooking()" :search="search" :loading="isLoading"
      :loading-text="$t('custom.loading_table')" item-key="id"
      :class="!isMobile ? 'elevation-1 mt-2' : 'elevation-1 mt-2 is-mobile'" :items-per-page="50"
      mobile-breakpoint="100" dense :footer-props="{
        pageText: `{0} ${$t('custom.of')} {1}`,
        itemsPerPageText: $t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100, -1],
      }">
      <template slot="header" slot-scope="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text" nowrap>
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="item" slot-scope="props">
        <tr @dblclick="replaceToBooking(props.item)" style="cursor: pointer"
          :class="!props.item.technical ? '' : 'blue-grey lighten-4'">
          <td class="text-center" :key="props.item.id">{{ props.item.id }}</td>
          <td class="text-center" nowrap>
            {{ formatCreatedAt(props.item.created_at) }}
          </td>
          <td class="text-center" nowrap>
            {{ formatCreatedAt(props.item.archived_date) }}
          </td>
          <td class="text-center" nowrap>
            <v-icon v-if="props.item.ride" color="success" v-tooltip="{
              content: $t('bookings.ride_true'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              mdi-garage-variant
            </v-icon>
            <v-icon v-else color="secondery"> mdi-minus </v-icon>
          </td>
          <td class="text-center" nowrap>
            <v-icon v-if="props.item.auto_archived" color="error">
              mdi-robot-outline
            </v-icon>
            <v-icon v-else color="success"> mdi-face-man-outline </v-icon>
          </td>
          <td class="text-center">
            <v-icon small class="mr-2" @click="replaceToBooking(props.item)" :title="$t('archive.booking_info')">
              mdi-eye-outline
            </v-icon>
            <v-icon v-if="checkAdminManager()" small @click="deleteItem(props.item)">
              mdi-delete-forever
            </v-icon>
          </td>
          <td class="text-center">
            <v-icon class="mr-2" color="red" v-if="!props.item.active && !props.item.archive"
              @click="archiveBooking(props.item)">
              mdi-archive-arrow-down
            </v-icon>
            <v-icon class="mr-2" color="green" v-if="!props.item.active && props.item.archive"
              @click="unarchiveBooking(props.item)">
              mdi-archive-arrow-up
            </v-icon>
          </td>
          <td class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{ props.item.car_name }}({{ props.item.car_code
              }})</router-link>
          </td>
          <td class="text-center" nowrap>
            <router-link :class="checkClientCategory(props.item.client_id)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }">{{ props.item.first_name ?
                props.item.first_name[0] : "" }}.
              {{ props.item.middle_name ? props.item.middle_name[0] : "" }}.
              {{
                props.item.last_name ? props.item.last_name : ""
              }}</router-link>
          </td>
          <td class="text-center" nowrap>
            {{ formatBookingDates(props.item.start_date) }}
          </td>
          <td class="text-center" nowrap>
            {{ formatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center">{{ props.item.days }}</td>
          <td class="text-center">{{ props.item.location_start }}</td>
          <td class="text-center">{{ props.item.location_end }}</td>
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
        {{ $t("tables.no_search_result", { msg: search }) }}
      </v-alert>
    </v-data-table>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
import xlsx from "json-as-xlsx";
export default {
  data() {
    return {
      bookings: [],
      all_bookings: [],
      ride_bookings: [],
      workers_names: [],
      payment: {
        cash: null,
        booking_id: null,
      },
      showArrivalPayments: false,
      show_ride_booking: false,
      arrival_payments: {
        gas: 0,
        damages: 0,
        clean: 0,
        delivery: 0,
        additional_hours: 0,
        other: 0,
      },
      prolongData: {
        days: null,
        price: null,
        additional_hours: null,
        paid: null,
      },
      selected: [],
      search: "",
      rowsPerPageItems: [10, 25, 50, 100],
      headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.archived_date"),
          value: "archived_date",
          align: "center",
        },
        {
          text: this.$t("bookings.ride_true"),
          value: "ride",
          align: "center",
        },
        {
          text: this.$t("custom.who_archived"),
          value: "auto_archived",
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        { text: this.$t("custom.actions"), sortable: false, align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        { text: this.$t("custom.client"), value: "last_name", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date_js",
          align: "center",
        },
        { text: this.$t("custom.end"), value: "end_date_js", align: "center" },
        { text: this.$t("custom.days"), value: "days", align: "center" },
        {
          text: this.$t("custom.checkin"),
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          value: "location_end",
          align: "center",
        },
      ],
      sortingBookings: { sortBy: "created_at", descending: true },
      options: {
        sortBy: "car_code",
      },
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        // timeAdjust: "12:00:00",
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "red",
            fillMode: "outline",
          },
          dates: new Date(),
        },
      ],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      sort_date_menu: false,
      start_date_format: null,
      sort_date_between_menu: false,
      sort_date: null,
      sort_date_between: null,
      configSort: {
        altFormat: "d-m-Y",
        altInput: true,
        dateFormat: "d-m-Y",
      },
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.show_ride_booking = this.$store.getters.getShowRideBookings;

      this.axios
        .get("/api/v1/archive_bookings", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.all_bookings = [];
          this.bookings = [];
          response.data.data.forEach((booking) => {
            this.all_bookings.push(booking.attributes);
          });
          response.data.data.forEach((booking) => {
            this.bookings.push(booking.attributes);
          });
          response.data.data.forEach((booking) => {
            if (booking.attributes.ride == true) {
              this.ride_bookings.push(booking.attributes);
            }
          });
          // Делаю формат даты для сортировки
          this.bookings.forEach((booking) => {
            booking.start_date_js = moment(
              booking.start_date,
              "DD-MM-YYYY H:mm"
            ).toDate();
            booking.end_date_js = moment(
              booking.end_date,
              "DD-MM-YYYY H:mm"
            ).toDate();
          });
          this.all_bookings.forEach((booking) => {
            booking.start_date_js = moment(
              booking.start_date,
              "DD-MM-YYYY H:mm"
            ).toDate();
            booking.end_date_js = moment(
              booking.end_date,
              "DD-MM-YYYY H:mm"
            ).toDate();
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.archive_bookings"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => { });
    }
  },
  watch: {
    sort_date() {
      let self = this;
      if (this.sort_date) {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });

        this.bookings = this.bookings.filter(
          (x) =>
            moment(self.sort_date, "YYYY-MM-DD").isSame(
              moment(x.start_date, "DD-MM-YYYY"),
              "day"
            ) ||
            moment(self.sort_date, "DD-MM-YYYY").isSame(
              moment(x.end_date, "DD-MM-YYYY"),
              "day"
            )
        );
        this.ride_bookings = this.ride_bookings.filter(
          (x) =>
            moment(self.sort_date, "YYYY-MM-DD").isSame(
              moment(x.start_date, "DD-MM-YYYY"),
              "day"
            ) ||
            moment(self.sort_date, "YYYY-MM-DD").isSame(
              moment(x.end_date, "DD-MM-YYYY"),
              "day"
            )
        );
      } else {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });
      }
    },
    sort_date_between() {
      let self = this;
      if (this.sort_date_between) {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });

        this.bookings = this.bookings.filter((x) =>
          moment(self.sort_date_between, "YYYY-MM-DD").isBetween(
            moment(x.start_date, "DD-MM-YYYY"),
            moment(x.end_date, "DD-MM-YYYY"),
            "day"
          )
        );
        this.ride_bookings = this.ride_bookings.filter((x) =>
          moment(self.sort_date_between, "YYYY-MM-DD").isBetween(
            moment(x.start_date, "DD-MM-YYYY"),
            moment(x.end_date, "DD-MM-YYYY"),
            "day"
          )
        );
      } else {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    sort_date_formatted() {
      if (this.sort_date) {
        return moment(this.sort_date, "YYYY-MM-DD").format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    sort_date_between_formatted() {
      if (this.sort_date_between) {
        return moment(this.sort_date_between, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    deleteItem(booking) {
      if (booking.vseprokaty_id) {
        this.$swal({
          position: "center",
          showConfirmButton: true,
          confirmButtonText: this.$t("custom.ok"),
          icon: "warning",
          title: this.$t("agregator.no_delete_aggregator"),
          text: this.$t("agregator.no_delete_aggregator_text"),
        });
        return;
      }
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("custom.you_sure_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/bookings/${booking.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.bookings.splice(this.bookings.indexOf(booking), 1);
              this.ride_bookings.splice(this.ride_bookings.indexOf(booking), 1);
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.archive_bookings_delete"))
            );
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: this.$t("custom.deleted"),
            text: this.$t("custom.deleted_ok"),
          });
        }
      });
    },
    findWorker(id) {
      let name;
      if (this.workers_names.find((worker) => worker.id == id)) {
        name = this.workers_names.find((worker) => worker.id == id).name
          ? this.workers_names.find((worker) => worker.id == id).name
          : this.workers_names.find((worker) => worker.id == id).email;
      }
      if (name) {
        return name;
      } else {
        return id;
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let bookings = this.all_bookings;
        this.axios
          .get("/api/v1/get_workers_names_with_ids", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.workers_names = response.data;
            this.axios
              .get(`/api/v1/bookings_export_excel`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let data = [
                  {
                    sheets: "BookingArchive",
                    columns: [
                      { label: "ID", value: "id" },
                      { label: this.$t("clients.created_at"), value: "created_at" },
                      { label: this.$t("bookings.name"), value: "first_name" },
                      {
                        label: this.$t("bookings.middlename"),
                        value: "middle_name",
                      },
                      { label: this.$t("bookings.lastname"), value: "last_name" },
                      { label: this.$t("bookings.car"), value: "car_name" },
                      { label: this.$t("doc_variables.car_code"), value: "car_code" },

                      { label: this.$t("bookings.start_date"), value: "start_date" },
                      { label: this.$t("bookings.end_date"), value: "end_date" },
                      { label: this.$t("bookings.days"), value: "days" },
                      { label: this.$t("bookings.add_hours_short"), value: "additional_hours" },
                      { label: this.$t("bookings.price"), value: "price" },
                      {
                        label: this.$t("bookings.rental_cost"),
                        value: "rental_cost",
                      },
                      { label: this.$t("bookings.delivery"), value: "delivery" },
                      {
                        label: this.$t("bookings.checkout"),
                        value: "delivery_end",
                      },
                      { label: this.$t("bookings.equipment"), value: "equipment" },
                      { label: this.$t("bookings.insurance"), value: "insurance" },
                      {
                        label: this.$t("bookings.clean"),
                        value: "clean_payment",
                      },
                      {
                        label: this.$t("bookings.add_drivers_2"),
                        value: "add_drivers_cost",
                      },
                      { label: this.$t("bookings.hours_cost"), value: "hours_cost" },
                      {
                        label: this.$t("bookings.mileage_cost"),
                        value: "mileage_cost",
                      },
                      { label: this.$t("bookings.damages"), value: "damage" },
                      { label: this.$t("bookings.gas"), value: "gas" },
                      { label: this.$t("bookings.for_fines"), value: "fine" },
                      { label: this.$t("bookings.other"), value: "other" },
                      {
                        label: this.$t("bookings.for_other_end"),
                        value: "other_end",
                      },
                      {
                        label: this.$t("bookings.hours_cost_end"),
                        value: "hours_cost_end",
                      },
                      {
                        label: this.$t("clients.client_source"),
                        value: "client_source",
                      },
                      {
                        label: this.$t("clients.source"),
                        value: "source",
                      },
                      {
                        label: this.$t("bookings.checkiner"),
                        value: (row) => {
                          return this.findWorker(row.start_worker_id);
                        },
                      },
                      {
                        label: this.$t("bookings.checkouter"),
                        value: (row) => {
                          return this.findWorker(row.end_worker_id);
                        },
                      },
                    ],
                    content: bookings,
                  },
                ];
                let settings = {
                  fileName: "booking_archive", // Name of the resulting spreadsheet
                  extraLength: 3, // A bigger number means that columns will be wider
                  writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                  writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
                  RTL: false, // Display the columns from right-to-left (the default value is false)
                };
                xlsx(data, settings);
              })
              .catch((error) => {
                this.setError(error, "Something went wrong in get_workers_names");
                console.log(error);
              });
          })

      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    showBooking() {
      if (!this.$store.getters.getShowRideBookings) {
        return this.bookings;
      }
      if (this.$store.getters.getShowRideBookings) {
        return this.ride_bookings;
      }
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    toggleShowBookings() {
      return this.$store.commit("show_ride_bookings");
    },
    formatCreatedAt(date) {
      if (moment(date).isValid()) {
        return moment.parseZone(date).format("DD MMM H:mm");
      } else {
        return date;
      }
    },
    checkClientCategory(client_id) {
      let client = this.set_client;
      if (client && client.category == this.$t("clients_groups.new")) {
        return "" + client.debt > 0 ? "orange darken-4" : "";
      } else if (
        client &&
        client.category == this.$t("clients_groups.permanent")
      ) {
        return `green--text ${client.debt > 0 ? " orange darken-4" : ""}`;
      } else if (client && client.category == this.$t("clients_groups.gray")) {
        return `grey--text text-decoration-line-through ${client.debt > 0 ? " orange darken-4" : ""
          }`;
      } else if (client && client.category == this.$t("clients_groups.black")) {
        return `black--text text-decoration-line-through ${client.debt > 0 ? " orange darken-4" : ""
          }`;
      } else if (client && client.category == this.$t("clients_groups.loyal")) {
        return `deep-purple--text ${client.debt > 0 ? " orange darken-4" : ""}`;
      }
    },
    formatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` }).catch((err) => { });
    },
    archiveBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: true,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === booking.id
              );
              this.bookings[foundIndex].archive = true;
            })
            .catch((error) => {
              this.setError(
                error,
                this.$t("errors.archive_bookings_archiving")
              );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.archive_bookings_archiving"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    unarchiveBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: false,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === booking.id
              );
              this.bookings[foundIndex].archive = false;
            })
            .catch((error) => {
              this.setError(
                error,
                this.$t("errors.archive_bookings_unarchiving")
              );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.archive_bookings_unarchiving"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    // flatPickr
  },
};
</script>
<style>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 10px;
}

/* .vc-popover-content-wrapper {
  z-index: 99999999 !important;
}
.vc-container {
  z-index: 99999999 !important;
}
.vc-popover-content {
  z-index: 99999999 !important;
}
.vc-popover-content {
  z-index: 99999999 !important;
}
.vc-pane-container {
  z-index: 99999999 !important;
}
.vc-pane-layout {
  z-index: 99999999 !important;
} */
</style>
