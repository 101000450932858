<template>
  <v-navigation-drawer permanent :mini-variant="showMini">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-icon v-if="showMini" @click="showMini = !showMini"
            >mdi-arrow-right-drop-circle-outline</v-icon
          >
          <span v-if="!showMini">Справка</span>
          <v-icon v-if="!showMini" @click="showMini = !showMini"
            >mdi-arrow-left-drop-circle-outline</v-icon
          >
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            <router-link :to="item.to">
              {{ item.title }}
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Быстрый старт",
          icon: "mdi-flash-circle",
          to: "/help/quick_start"
        },
        {
          title: "Возможные проблемы",
          icon: "mdi-alert",
          to: "/help/problems"
        },
        {
          title: "Роли пользователей",
          icon: "mdi-account-tie",
          to: "/help/roles"
        },
        {
          title: "Добавление автомобиля",
          icon: "mdi-car-arrow-right",
          to: "/help/add_car"
        },
        {
          title: "Формирование документов",
          icon: "mdi-alpha-d-circle",
          to: "/help/doc_creation"
        },
        {
          title: "Переменные в шаблонах",
          icon: "mdi-alpha-v-circle",
          to: "/help/doc_variables"
        },
        {
          title: "Форма на сайт",
          icon: "mdi-widgets-outline",
          to: "/help/widget"
        },
        {
          title: "Краткое описание функций",
          icon: "mdi-function",
          to: "/help/functions_desc"
        },
        {
          title: "Частые вопросы",
          icon: "mdi-frequently-asked-questions",
          to: "/help/faqs"
        }
      ],
      right: false,
      drawer: true,
      clipped: false,
      showMini: false,
      isMobile: false
    };
  }
};
</script>

<style></style>
