<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-card class="text-center">
      <v-card-title class="text-center">
        <v-spacer></v-spacer>
        <h2 class="text-center">{{ $t('errors.payment_bad') }}</h2>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-icon color="error" x-large>
          mdi-alert-circle-outline
        </v-icon>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :to="{ path: `/dashboard` }"
          >{{ $t('other.to_main') }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
export default {
  data: () => ({
    isLoading: false,
    error: ""
  }),
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken
          }
        })
        .then(response => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get(`api/v1/main_companies/${response.data.main_company_id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken
              }
            })
            .then(response_main_company => {
              this.$store.commit("setMainCompany", response_main_company.data );
            })
            .catch(error => {
              self.isLoading = false;
              self.setError(error, self.$t('errors.main_company_data'));
              console.log(error);
            });
        })
        .catch(error => {
          this.isLoading = false;
          this.setError(error, this.$t('errors.company_data'));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    }
  },
  methods: {
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
