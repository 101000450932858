<template>
  <v-flex class="d-flex">
    <v-card>
      <help-drawer></help-drawer>
    </v-card>
    <v-container fluid>
      <v-card>
        <v-card-title class="display-1"
          >Как сформировать документы</v-card-title
        >
        <v-card-subtitle class="subtitle-1"
          >Загрузка шаблонов для генерации документов на аренду</v-card-subtitle
        >
        <v-card-text>
          Для автоматического формирования всех документов на аренду в один
          клик, в первую очередь, необходимо подготовить шаблоны договора и акта
          приема-передачи и загрузить их в профиль компании. Для этого зайдите в
          меню программы по клику на имя пользователя, в правом верхнем углу,
          выберите пункт «Настройки компании» и перейдите во вкладку «Шаблоны».
          Вы можете взять бланки документов вашей организации и вставить в них
          переменные для данных, которые программа будет заполнять
          автоматически, или скачать наш шаблон договора, доступный в том же
          разделе настроек, и внести в него изменения под вашу организацию. Этот
          же договор служит образцом для расстановки переменных в вашей форме
          договора. Список переменных доступен
          <router-link to="/help/doc_variables">данной странице</router-link>.
          Формирование договора или внесение изменений в существующий шаблон
          необходимо производить в программе Microsoft Word в формате docx. Для
          примера вы можете
          <a href="/doc_example.docx" target="_blank">скачать данный шаблон</a>.
          Все переменные доступны для форматирования текста. Т.е. если вы
          написали какую то переменную и выделили ее жирным и увеличили размер
          шрифта, то при формировании документов подставленные данные будут
          иметь такое же форматирование.
        </v-card-text>
        <v-card-text
          >После подготовки бланков документов с переменными, их необходимо
          загрузить с помощью диалогового окна выбора документов, в том же
          разделе настроек компании, либо простым перетаскиванием файла в
          указанное окно.</v-card-text
        >
        <v-card-text
          >Те же операции необходимо повторить для формирования договора и
          прочих документов для юридических лиц (при
          необходимости).</v-card-text
        >
        <v-card-text
          >При необходимости внесения правок в уже сформированные шаблоны, вам
          нет необходимости повторять всю процедуру полностью. Достаточно в
          диалоговом окне подгрузки документов нажать кнопку «Скачать текущий».
          Скачается ваш, сформированный на предыдущем этапе, бланк. После
          внесения необходимых изменений повторите загрузку исправленного файла
          вышеуказанным способом.</v-card-text
        >
        <v-card-text
          >После того как загрузка бланков завершена можно заходить в Меню –
          Брони – Активные, создать новую бронь или открыть существующую и
          нажать кнопку
          <v-icon
            color="green"
            v-tooltip="{
              content: $t('bookings.generate_docs'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500
              }
            }"
          >
            mdi-file-document-outline </v-icon
          >. Произойдет формирование и загрузка файла с документами, в которые
          внесены данные клиента и остальные данные, согласно брони. Далее файл
          можно вывести на печать.</v-card-text
        >
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import HelpDrawer from "./HelpDrawer";
export default {
  data() {
    return {
      isMobile: false
    };
  },
  components: {
    HelpDrawer
  }
};
</script>

<style></style>
