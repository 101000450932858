<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-flex xs12 sm6 class="mx-auto">
      <v-card>
        <v-card-text>
          <v-text-field
            :label="$t('custom.email')"
            v-model.trim="user.email"
            :disabled="true"
          ></v-text-field>
          <v-text-field
            v-model.trim="user.name"
            :placeholder="$t('employers.nickname')"
            :label="$t('employers.nickname')"
          ></v-text-field>
          <v-text-field
            v-model.trim="user.first_name"
            :placeholder="$t('employers.first_name')"
            :label="$t('employers.name')"
          ></v-text-field>
          <v-text-field
            v-model.trim="user.middle_name"
            :placeholder="$t('employers.middle_name')"
            :label="$t('employers.middle_name')"
          ></v-text-field>
          <v-text-field
            v-model.trim="user.last_name"
            :placeholder="$t('employers.last_name')"
            :label="$t('employers.last_name')"
          ></v-text-field>
          <v-select
            v-model="agent_setting.connect_type"
            clearable
            multiple
            :items="connect_types"
            :label="$t('agents.connect_type')"
            class="mt-5"

          ></v-select>
          <v-text-field
            v-model="agent_setting.commission_percent"
            :label="$t('agents.commission_percent')"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="agent_setting.commission_fix"
            :label="$t('agents.commission_fix') + ', ' + currency"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="agent_setting.prepayment_percent"
            :label="$t('agents.prepayment_percent')"
            type="number"
          ></v-text-field>
          <v-textarea
            v-model.trim="agent_setting.payment_details"
            :label="$t('agents.payment_details')"
            rows="1"
            auto-grow
          ></v-textarea>
          <v-btn class="mb-3" color="success" small @click="addWebsite()">
            {{ $t("agents.add_site") }}
            <v-icon class="ml-1"> mdi-plus </v-icon>
          </v-btn>
          <v-flex
            :class="isMobile ? 'text-center' : 'd-flex mx-auto'"
            md12
            v-for="(website, index) in websites"
            :key="index"
          >
            <v-text-field
              class="mr-2 my-2"
              v-model="website.value"
              :label="$t('agents.website')"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-btn
              v-if="websites && websites.length > 1"
              :class="isMobile ? 'm-2' : 'ml-2 my-2'"
              color="error"
              @click="removeWebsite(index)"
            >
              -
            </v-btn>
          </v-flex>
          <v-flex>
            <v-radio-group
              @change="update_agent_settings"
              v-model="agent_setting.paid_commission_from_total"
            >
              <v-radio
                :label="$t('agents.paid_commission_from_total')"
                :value="true"
              ></v-radio>
              <v-radio
                :label="$t('agents.paid_commission_from_not_total')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update_agent_settings"
            v-model="agent_setting.can_edit_booking"
            :label="$t('agents.can_edit_booking')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update"
            v-model="user.can_change_branch"
            :label="$t('users.can_change_branch')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update_agent_settings"
            v-model="agent_setting.access_to_account"
            :label="$t('agents.access_to_account')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update_agent_settings"
            v-model="agent_setting.can_take_money"
            :label="$t('agents.can_take_money')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update_agent_settings"
            v-model="agent_setting.can_take_prepayment"
            :label="$t('agents.can_take_prepayment')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-flex md12 :class="isMobile ? '' : 'd-flex justify-center text-center'">
            <v-flex>
              <v-btn
                v-if="checkAdminManager()"
                text
                @click="update"
                color="success"
                >{{ $t("custom.save") }}
              </v-btn>
            </v-flex>
            <v-flex v-if="(checkAdmin() || user_role == 'guest') && user.active">
              <v-btn
                text
                color="warning"
                @click="saveUserActive(false)"
                >{{ $t("agents.turn_off_agent") }}
              </v-btn>
            </v-flex>
            <v-flex v-if="(checkAdmin() || user_role == 'guest') && !user.active">
              <v-btn
                text
                color="success"
                @click="saveUserActive(true)"
              >
                {{ $t("custom.activate") }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn class="mx-auto" text to="/agents">{{
                $t("agents.all_agents")
              }}</v-btn>
            </v-flex>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-flex>
  </span>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  name: "UserEdit",
  data() {
    return {
      error: "",
      notice: "",
      user: {
        role: null,
        email: null,
        name: null,
        active: true,
        can_change_branch: false,
      },
      agent_setting: {
        access_to_account: true,
        can_edit_booking: true,
        can_take_money: true,
        can_take_prepayment: true,
        commission_fix: 0,
        commission_percent: 0,
        connect_type: ["manual"],
        payment_details: "",
        prepayment_percent: 0,
        website: [],
        paid_commission_from_total: true,
      },
      websites: [],
      connect_types: [
        { text: this.$t("agents.connect_type_1"), value: "manual" },
      ],
      roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      super_roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.superadmin"), value: "superadmin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      isLoading: false,
      isMobile: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/admin/users/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.user = response.data;
          this.agent_setting = response.data.agent_setting;
          this.websites = response.data.agent_setting.website.map((website) => {
            return { value: website };
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.load_user"),
          });
          this.setError(error, this.$t("errors.load_user"));
          console.log(error);
        })
        .finally(this.isLoading = false);
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    current_user_id() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.id;
      } else {
        return "";
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
  },
  methods: {
    update() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                role: this.user.role,
                name: this.user.name,
                last_name: this.user.last_name,
                middle_name: this.user.middle_name,
                first_name: this.user.first_name,
                partner_id: this.user.partner_id,
                can_change_branch: this.user.can_change_branch,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.update_agent_settings();
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.setError(error, this.$t("errors.must_be_one_superadmin"));
            } else {
              this.setError(error, this.$t("errors.error"));
            }
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    update_agent_settings() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.agent_setting.website = this.websites.map((website) => {
          return website.value;
        });
        this.axios
          .patch(
            `api/v1/agent_settings/${this.agent_setting.id}`,
            {
              agent_settings: this.agent_setting,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("agents.settings_saved"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.setError(error, this.$t("errors.must_be_one_superadmin"));
            } else {
              this.setError(error, this.$t("errors.error"));
            }
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    removeWebsite(index) {
      this.websites.splice(index, 1);
    },
    addWebsite() {
      this.websites.push({ value: "" });
    },
    saveUserActive(state) {
      if (this.checkAdmin()) {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                active: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.user.active = state;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
      this.notice = "";
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
};
</script>
